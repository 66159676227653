import { GenericObject } from '../interfaces';

interface CreateFormDataProps {
  params: GenericObject;
}

export const createFormData = ({ params }: CreateFormDataProps) => {
  const formData = new FormData();

  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};
