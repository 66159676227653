import { Heading as HexaHeading, Paragraph } from '@hexa-ui/components';
import styled from 'styled-components';

export const PocDetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;

  width: 100%;
  padding-bottom: 16px;
  column-gap: 16px;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 65%;
  row-gap: 16px;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 35%;
  row-gap: 16px;
`;

export const Title = styled(HexaHeading)`
  font-family: 'Work Sans', var(--fonts-heading);
  font-size: 1rem;
`;

export const Subheader = styled(HexaHeading).attrs({ size: 'H5' })<{ type?: 'small' | 'large' }>`
  font-family: 'Work Sans', var(--fonts-heading);
  font-size: ${({ type = 'small' }) => (type === 'small' ? '0.875rem' : '1rem')};
`;

export const Content = styled(Paragraph)`
  font-size: 0.875rem;
`;
