import { IconButton as HexaIconButton } from '@hexa-ui/components';
import styled, { css } from 'styled-components';
import { Direction } from './Slider.types';

export const SliderContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  overflow: hidden;

  width: 100%;
`;

export const SliderContent = styled.div`
  display: flex;

  overflow: hidden;

  width: 100%;
  column-gap: 8px;
`;

export const IconButton = styled(HexaIconButton)<{ $direction: Direction }>`
  position: absolute;
  z-index: 1;

  border-radius: 0;

  ${({ $direction }) => {
    if ($direction === 'prev') {
      return css`
        left: 0;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 0) 100%
        );

        &:hover {
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      `;
    }

    return css`
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      );

      &:hover {
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    `;
  }};
`;
