import { PayloadAction } from '@reduxjs/toolkit';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { serializer } from '../../../../../utils/filter/serialize';
import {
  ApplyFn,
  ClearFnType,
  FilterChips,
  FilterContextProps,
  FilterCounter,
  FilterFnType,
  FilterPayload,
  FilterState,
} from './FilterContext.types';

const FilterContext = createContext<FilterContextProps | null>(null);

export const FilterProvider: React.FC = ({ children }) => {
  const reducer = useCallback(
    (state: FilterState, { type, payload }: PayloadAction<FilterPayload | null>) => {
      if (type === 'SETTER' && payload.state.value && payload.state.value !== '00') {
        return { ...state, [payload.id]: payload.state };
      }

      if (type === 'SETTER' && (!payload.state.value || payload.state.value === '00')) {
        const { [payload.id]: _, ...newState } = state;

        return newState;
      }

      if (type === 'DELETE') {
        const deletedState = Object.keys(state)
          .filter((identifier) => !identifier.startsWith(payload.id))
          .reduce((obj, key) => {
            obj[key] = state[key];

            return obj;
          }, {});

        payload.callback?.(payload.id, deletedState);
        return deletedState;
      }

      return {};
    },
    []
  );

  const [state, dispatch] = useReducer(reducer, {});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [chips, setChips] = useState<FilterChips>({});

  const onFilterRef = useRef<FilterFnType | null>(null);
  const onClearRef = useRef<ClearFnType | null>(null);

  const counter: FilterCounter = useMemo(() => {
    return Object.keys(state).reduce(
      (acc, key) => {
        const [name, _, type] = key.split(':');

        if (['checkbox'].includes(type) && acc.individual[name]) {
          acc.individual[name] += 1;
          acc.total += 1;

          return acc;
        }

        acc.individual[name] = 1;
        acc.total += 1;

        return acc;
      },
      { individual: {}, total: 0 }
    );
  }, [state]);

  const onFiltering = useCallback((id, state) => {
    dispatch({ type: 'SETTER', payload: { id, state } });
  }, []);

  const onClear = useCallback(() => {
    if (onFilterRef.current && Object.keys(chips).length > 0) {
      onFilterRef.current('');
    }

    dispatch({ type: 'CLEAR', payload: null });

    setChips({});
    if (onClearRef.current) onClearRef.current(1);
  }, [chips]);

  const onClearDate = useCallback((id) => {
    dispatch({ type: 'DELETE', payload: { id, state: null } });
  }, []);

  const onDeleteCallback = useCallback((id: string, state: FilterState) => {
    setChips((prev) => {
      const { [id]: _, ...newChips } = prev;

      return newChips;
    });

    if (onFilterRef.current && onClearRef.current) {
      onApply({ state, onFilterFn: onFilterRef.current, onClearFn: onClearRef.current });
    }
  }, []);

  const onDelete = useCallback((id) => {
    dispatch({ type: 'DELETE', payload: { id, state: null, callback: onDeleteCallback } });
  }, []);

  const onCancel = useCallback(() => {
    onClear();

    setIsOpen(false);
  }, []);

  const onApply = useCallback(({ state, onFilterFn, onClearFn }: ApplyFn) => {
    onFilterRef.current = onFilterFn;
    onClearRef.current = onClearFn;

    const { chips, filters } = serializer(state);

    setChips(chips);
    onFilterFn(filters);

    setIsOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      open: { value: isOpen, setter: setIsOpen },
      chips,
      counter: Object.keys(chips).length ? counter.total : 0,
      filtered: { counter: counter.individual, value: state, setter: onFiltering },
      onClear,
      onClearDate,
      onDelete,
      onCancel,
      onApply,
    }),
    [isOpen, chips, counter, state]
  );

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useFilter = () => useContext(FilterContext);
