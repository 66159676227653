import { Card as HexaCard } from '@hexa-ui/components';
import styled from 'styled-components';

export const Card = styled(HexaCard)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  padding: 24px;
`;
