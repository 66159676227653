import { Tables } from '../../interfaces';
import * as completed from './completed';
import * as files from './files';
import * as inprogress from './inprogress';
import * as pending from './pending';

export { completed, files, inprogress, pending };

export const excludeAssessments: Tables[] = [Tables.FILES];

export const status = {
  pending: {
    status: {
      '#0F7FFA:formatting.recommendation.NEW_CREDIT_OFFER': ['NEW_CREDIT_OFFER'],
      '#D9912B:formatting.recommendation.DECREASE': ['DECREASE'],
      '#2AA147:formatting.recommendation.INCREASE': ['INCREASE'],
    },
  },
  inprogress: {
    status: {
      '#0F7FFA:tables.inprogress.status.processing': ['WAITING_FOR_ACTIVATION', 'APPROVED_BY_BU'],
      '#D9B62B:tables.inprogress.status.integration': ['WAITING_ONBOARDING'],
    },
  },
  completed: {
    status: {
      '#107418:tables.completed.status.active': ['ACTIVATED'],
      '#14141451:tables.completed.status.denied': ['DENIED_BY_BU', 'DENIED_BY_ONBOARDING'],
    },
  },
  files: {
    status: {
      '#0000FF:tables.files.status.processing': ['ANALYZING', 'PENDING', 'PROCESSING'],
      '#008000:tables.files.status.success': ['ANALYZED', 'UPLOADED', 'DONE'],
      '#FFA500:tables.files.status.errors': ['FAILED'],
    },
  },
};

export const fixed: { [key in Tables]: string[] } = {
  [Tables.PENDING]: ['pocName', 'actions'],
  [Tables.INPROGRESS]: [],
  [Tables.COMPLETED]: [],
  [Tables.FILES]: [],
};
