import { Dialog } from '@hexa-ui/components';
import styled from 'styled-components';

export const Container = styled.div``;

export const Root = styled(Dialog.Root)`
  padding: 16px;
`;

export const Close = styled(Dialog.Close)``;

export const ModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  row-gap: 18px;
  max-width: 650px;
  padding: 4px;

  overflow: hidden !important;
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 18px;

  width: 100%;

  > * {
    align-self: end;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  width: 100%;
  column-gap: 12px;
`;
