import { useDispatch, useSelector } from 'react-redux';
import { AppState, Selected } from '../../interfaces';
import {
  setLastSelected as setLastSelectedAction,
  setSelected as setterSelected,
  setUserName as setterUserName,
} from '../ducks/selectedDuck';

export const useSelected = () => {
  const dispatch = useDispatch();

  const selected = useSelector((state: AppState) => state.selected);
  const setSelected = (selected: Selected) => dispatch(setterSelected(selected));
  const setUserName = (userName: string) => dispatch(setterUserName(userName));
  const setLastSelected = () => dispatch(setLastSelectedAction());

  return { selected, setSelected, setUserName, setLastSelected };
};
