import React from 'react';

export const NoFilter = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      width="126"
      height="136"
      viewBox="0 0 126 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="no-filter-icon"
    >
      <path
        d="M53.6911 6.53316L14.0339 27.908C8.20716 31.0486 4.57422 37.1338 4.57422 43.753L4.57422 90.7608C4.57422 97.2282 8.04383 103.198 13.663 106.4L53.0921 128.867C58.6689 132.044 65.5157 132.012 71.0621 128.782L112.485 104.657C118.022 101.433 121.427 95.5091 121.427 89.1026L121.427 43.9092C121.427 37.2076 117.704 31.0608 111.764 27.9566L70.5691 6.42562C65.274 3.65806 58.9506 3.69835 53.6911 6.53316Z"
        fill="white"
        stroke="#F0ECFC"
        strokeWidth="8"
        strokeLinejoin="round"
      />
      <path
        d="M54.5544 90.8294V75.8983C54.5544 75.4655 54.4462 75.1409 54.1216 74.8163L34.1052 52.3114C33.3478 51.4458 32.915 50.3638 32.915 49.1737V44.6294C32.915 42.8983 34.3216 41.4917 36.0527 41.4917H85.1741C86.9052 41.4917 88.3118 42.8983 88.3118 44.6294V49.1737C88.3118 50.3638 87.879 51.4458 87.1216 52.3114L67.1052 74.8163C66.7806 75.1409 66.6724 75.4655 66.6724 75.8983V97.213C66.6724 98.6196 65.1577 99.3769 63.9675 98.5114L55.2036 92.0196C54.879 91.8032 54.5544 91.2622 54.5544 90.8294Z"
        fill="#7C74B1"
      />
      <path
        d="M60.6133 41.4917H85.1739C86.9051 41.4917 88.3116 42.8983 88.3116 44.6294V49.1737C88.3116 50.3638 87.8789 51.4458 87.1215 52.3114L67.1051 74.8163C66.7805 75.1409 66.6723 75.4655 66.6723 75.8983V97.213C66.6723 98.6196 65.1575 99.3769 63.9674 98.5114L60.6133 96.131V41.4917Z"
        fill="#5F538F"
      />
      <path
        d="M96.3087 49.7635C97.2526 43.8024 93.1855 38.2048 87.2244 37.2609C81.2633 36.317 75.6657 40.3842 74.7218 46.3452C73.7779 52.3063 77.8451 57.9039 83.8062 58.8478C89.7672 59.7917 95.3648 55.7245 96.3087 49.7635Z"
        fill="#E2D246"
      />
      <path
        d="M94.5732 49.5559C95.9601 43.6824 92.323 37.7966 86.4494 36.4097C80.5759 35.0228 74.6901 38.6599 73.3032 44.5335C71.9163 50.407 75.5534 56.2928 81.427 57.6797C87.3005 59.0666 93.1863 55.4295 94.5732 49.5559Z"
        fill="#F0E44C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.4752 50.9047L86.5801 47.0096L90.4752 43.1145L87.9867 40.626L84.0916 44.5211L80.1965 40.626L77.708 43.1145L81.6031 47.0096L77.708 50.9047L80.1965 53.3932L84.0916 49.4981L87.9867 53.3932L90.4752 50.9047Z"
        fill="white"
      />
      <path
        d="M88.0137 40.5531L84.418 44.1489L84.9535 44.6845L88.5493 41.0887L88.0137 40.5531Z"
        fill="#DDDCDC"
      />
      <path
        d="M84.9564 49.7296L84.6504 50.0356L88.0167 53.4019L88.3227 53.0959L84.9564 49.7296Z"
        fill="#DDDCDC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.5736 51.1211L82.4687 47.226L78.5736 43.3309L80.7375 41.167L80.1965 40.626L77.708 43.1145L81.6031 47.0096L77.708 50.9047L80.1965 53.3932L80.5211 53.0686L78.5736 51.1211Z"
        fill="#DDDCDC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.0918 44.5214L84.9574 44.7378L84.4164 44.1968L84.0918 44.5214Z"
        fill="#DDDCDC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.0918 49.4985L84.9574 49.7149L84.6328 50.0395L84.0918 49.4985Z"
        fill="#DDDCDC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.708 50.9048L78.5736 51.1212L80.5211 53.0687L80.1965 53.3933L77.708 50.9048Z"
        fill="#424242"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.708 43.1147L78.5736 43.3311L82.4687 47.2262L81.6031 47.0098L77.708 43.1147Z"
        fill="#424242"
      />
    </svg>
  )
);
