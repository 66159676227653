import { CreditRecommendation } from '../interfaces';

export const getRecommendation = (recommendations: Array<any>): CreditRecommendation => {
  if (!recommendations?.length) {
    return CreditRecommendation.NEW_CREDIT_OFFER;
  }

  const isDecrease =
    recommendations.find((item) => item?.name?.toUpperCase() === 'VARIATION')?.value < 0;

  return isDecrease ? CreditRecommendation.DECREASE : CreditRecommendation.INCREASE;
};
