import { Dialog } from '@hexa-ui/components';
import styled from 'styled-components';

export const Container = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  column-gap: 16px;
`;

export const Root = styled(Dialog.Root)``;

export const Close = styled(Dialog.Close)``;
