import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Selected, SelectedStore } from '../../interfaces';

const initialState: SelectedStore = {
  country: '',
  vendorId: '',
  userName: '',
  lastSelected: {
    country: '',
    vendor: '',
  },
};

const selectedSlice = createSlice({
  name: 'selected',
  initialState,
  reducers: {
    setLastSelected: (state: SelectedStore) => {
      state.country = state.lastSelected.country;
      state.vendorId = state.lastSelected.vendor;
    },
    setSelected: (state: SelectedStore, action: PayloadAction<Selected>) => {
      if (action.payload.country !== state.country || action.payload.vendorId !== state.vendorId) {
        state.lastSelected = {
          country: state.country,
          vendor: state.vendorId,
        };
      }
      state.country = action.payload.country;
      state.vendorId = action.payload.vendorId;
    },
    setUserName: (state: SelectedStore, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
  },
});

export const { setSelected, setUserName, setLastSelected } = selectedSlice.actions;

export default selectedSlice.reducer;
