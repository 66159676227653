import { Button } from '@hexa-ui/components';
import { Download, Upload } from '@hexa-ui/icons';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Modal } from './Modal/Modal';
import { FileButtonsContainer } from './styles';
import SegmentService from '../../../services/segment';

export const FileButtons: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { permissions, selected } = useGetUserPreferences();

  const [open, setOpen] = useState<boolean>(false);

  const buttonClicked = (buttonLabel: string, buttonName: string, event: string) => {
    SegmentService.paymentsButtonClicked(
      buttonLabel,
      buttonName,
      selected?.vendorId,
      event,
      'CREDIT_MANAGEMENT_FILE_PAGE'
    );
  };

  const onOpen = () => {
    setOpen(true);
    buttonClicked(
      'Download File',
      'Download File Button',
      'Credit Management Files Download Beggining'
    );
  };

  if (!permissions.has('actions.upload') && !permissions.has('actions.download.assessments')) {
    return null;
  }

  return (
    <FileButtonsContainer data-testid="file-buttons-container">
      {permissions.has('actions.upload') && (
        <Button
          id="upload-button"
          icon={Upload}
          size="large"
          leading
          variant="secondary"
          onClick={() => {
            navigate('/bees-credit-management/upload');
            buttonClicked(
              'Upload File',
              'Upload File Button',
              'Credit Management Files Upload Beggining'
            );
          }}
          css={{ minWidth: 'auto', whiteSpace: 'nowrap' }}
        >
          {formatMessage({ id: 'files.buttons.upload' })}
        </Button>
      )}

      <Modal
        open={{ value: open, setter: setOpen }}
        trigger={
          <Button
            id="download-button"
            icon={Download}
            size="large"
            leading
            variant="primary"
            onClick={onOpen}
            css={{ minWidth: 'auto', whiteSpace: 'nowrap' }}
          >
            {formatMessage({ id: 'files.buttons.download' })}
          </Button>
        }
      />
    </FileButtonsContainer>
  );
};
