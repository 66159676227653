import React from 'react';

export const ErrorLoading = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      width="125"
      height="136"
      viewBox="0 0 125 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="error-loading-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.2981 86.162C31.9327 86.162 30.8219 87.2297 30.8219 88.5421C30.8219 89.8545 31.9327 90.9222 33.2981 90.9222H89.6659C91.0312 90.9222 92.142 89.8545 92.142 88.5421C92.142 87.2297 91.0312 86.162 89.6659 86.162H33.2981ZM89.6659 91.7019H33.2981C31.4857 91.7019 30.0107 90.2848 30.0107 88.5421C30.0107 86.8001 31.4857 85.3823 33.2981 85.3823H89.6659C91.4782 85.3823 92.9532 86.8001 92.9532 88.5421C92.9532 90.2848 91.4782 91.7019 89.6659 91.7019Z"
        fill="#DDDCDC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.7332 89.9272H33.5493C32.7533 89.9272 32.1084 89.3073 32.1084 88.5422C32.1084 87.7771 32.7533 87.1572 33.5493 87.1572H61.7332C62.5291 87.1572 63.1741 87.7771 63.1741 88.5422C63.1741 89.3073 62.5291 89.9272 61.7332 89.9272Z"
        fill="#F0E44C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.322 78.7289H50.4108C48.7324 78.7289 47.6838 76.9827 48.5229 75.5861L54.4782 65.6705L60.4341 55.755C61.2733 54.3583 63.3707 54.3583 64.2098 55.755L70.1657 65.6705L76.121 75.5861C76.9602 76.9827 75.9115 78.7289 74.2331 78.7289H62.322Z"
        fill="#5F538F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.1719 77.2118L49.3125 78.4464L50.5706 77.0836L49.6961 75.9773L47.1719 77.2118Z"
        fill="#5F538F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.4626 53.9702L63.7249 55.1948L62.3273 57.3478L60.9297 56.4627L61.4626 53.9702Z"
        fill="#5F538F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.6403 77.8397H48.7292C47.0508 77.8397 46.0021 76.0936 46.8413 74.6969L52.7966 64.7814L58.7525 54.8659C59.5917 53.4692 61.689 53.4692 62.5282 54.8659L68.4841 64.7814L74.4394 74.6969C75.2786 76.0936 74.2299 77.8397 72.5515 77.8397H60.6403Z"
        fill="#7C74B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1775 69.965C59.1281 69.9176 59.1038 69.8608 59.1038 69.7929L58.7457 61.0405C58.7457 60.879 58.8297 60.7976 58.9984 60.7976H61.991C62.1597 60.7976 62.2443 60.879 62.2443 61.0405L61.8856 69.7929C61.8856 69.8608 61.8613 69.9176 61.8119 69.965C61.7626 70.0124 61.7029 70.0359 61.6329 70.0359H59.3565C59.2865 70.0359 59.2268 70.0124 59.1775 69.965ZM59.0301 74.4624C58.6854 74.1317 58.5137 73.7102 58.5137 73.1962C58.5137 72.6835 58.6854 72.2643 59.0301 71.9406C59.3742 71.6163 59.8133 71.4542 60.3473 71.4542C60.8808 71.4542 61.3199 71.6163 61.6646 71.9406C62.0087 72.2643 62.181 72.6835 62.181 73.1962C62.181 73.6961 62.0087 74.1153 61.6646 74.4524C61.3199 74.7902 60.8808 74.9588 60.3473 74.9588C59.8133 74.9588 59.3742 74.7937 59.0301 74.4624Z"
        fill="#F0E44C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.8395 73.5049C58.8395 72.9921 59.0113 72.573 59.356 72.2487C59.7 71.9244 60.1391 71.7628 60.6732 71.7628C61.0879 71.7628 61.4448 71.8618 61.745 72.0573C61.7097 72.0175 61.6725 71.9783 61.633 71.9402C61.2883 71.6159 60.8492 71.4543 60.3157 71.4543C59.7816 71.4543 59.3426 71.6159 58.9985 71.9402C58.6538 72.2645 58.4814 72.683 58.4814 73.1964C58.4814 73.7098 58.6538 74.1318 58.9985 74.4625C59.0807 74.5416 59.1684 74.6106 59.2616 74.6709C58.9808 74.3566 58.8395 73.9685 58.8395 73.5049Z"
        fill="#E2D246"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1456 69.965C59.195 70.0124 59.2546 70.0358 59.3247 70.0358H59.427L59.0713 61.349C59.0713 61.1875 59.1554 61.1061 59.3247 61.1061H62.2095L62.2125 61.0405C62.2125 60.879 62.1279 60.7976 61.9592 60.7976H58.9666C58.7979 60.7976 58.7139 60.879 58.7139 61.0405L59.072 69.7929C59.072 69.8608 59.0963 69.9176 59.1456 69.965Z"
        fill="#E2D246"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4762 37.9966C25.7991 37.9966 24.4337 39.3084 24.4337 40.9211V93.83C24.4337 95.4427 25.7991 96.7545 27.4762 96.7545H95.4867C97.1644 96.7545 98.5292 95.4427 98.5292 93.83V40.9211C98.5292 39.3084 97.1644 37.9966 95.4867 37.9966H27.4762ZM95.4867 97.6454H27.4762C25.2875 97.6454 23.5068 95.9338 23.5068 93.83V40.9211C23.5068 38.8173 25.2875 37.1057 27.4762 37.1057H95.4867C97.6754 37.1057 99.4561 38.8173 99.4561 40.9211V93.83C99.4561 95.9338 97.6754 97.6454 95.4867 97.6454Z"
        fill="#DDDCDC"
      />
      <mask id="mask0_2701_10907" maskUnits="userSpaceOnUse" x="21" y="34" width="82" height="67">
        <path d="M21.3115 100.459H102.459V34.459H21.3115V100.459Z" fill="#DEDEDE" />
      </mask>
      <g mask="url(#mask0_2701_10907)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9707 47.6138H98.993V46.7229H23.9707V47.6138Z"
          fill="#DDDCDC"
        />
      </g>
      <mask id="mask1_2701_10907" maskUnits="userSpaceOnUse" x="21" y="34" width="82" height="67">
        <path d="M21.3115 100.459H102.459V34.459H21.3115V100.459Z" fill="#DEDEDE" />
      </mask>
      <g mask="url(#mask1_2701_10907)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7007 42.5043C31.7007 43.4397 30.9121 44.1977 29.9389 44.1977C28.9664 44.1977 28.1777 43.4397 28.1777 42.5043C28.1777 41.5688 28.9664 40.8108 29.9389 40.8108C30.9121 40.8108 31.7007 41.5688 31.7007 42.5043Z"
          fill="#DDDCDC"
        />
      </g>
      <mask id="mask2_2701_10907" maskUnits="userSpaceOnUse" x="21" y="34" width="82" height="67">
        <path d="M21.3115 100.459H102.459V34.459H21.3115V100.459Z" fill="#DEDEDE" />
      </mask>
      <g mask="url(#mask2_2701_10907)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.479 42.5043C37.479 43.4397 36.6904 44.1977 35.7172 44.1977C34.7447 44.1977 33.9561 43.4397 33.9561 42.5043C33.9561 41.5688 34.7447 40.8108 35.7172 40.8108C36.6904 40.8108 37.479 41.5688 37.479 42.5043Z"
          fill="#DDDCDC"
        />
      </g>
      <mask id="mask3_2701_10907" maskUnits="userSpaceOnUse" x="21" y="34" width="82" height="67">
        <path d="M21.3115 100.459H102.459V34.459H21.3115V100.459Z" fill="#DEDEDE" />
      </mask>
      <g mask="url(#mask3_2701_10907)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.2544 42.5043C43.2544 43.4397 42.4658 44.1977 41.4926 44.1977C40.5201 44.1977 39.7314 43.4397 39.7314 42.5043C39.7314 41.5688 40.5201 40.8108 41.4926 40.8108C42.4658 40.8108 43.2544 41.5688 43.2544 42.5043Z"
          fill="#DDDCDC"
        />
      </g>
      <path
        d="M53.1169 6.53316L13.4597 27.908C7.63295 31.0486 4 37.1338 4 43.753L4 90.7608C4 97.2282 7.46961 103.198 13.0888 106.4L52.5179 128.867C58.0946 132.044 64.9414 132.012 70.4878 128.782L111.911 104.657C117.447 101.433 120.852 95.5091 120.852 89.1026L120.852 43.9092C120.852 37.2076 117.129 31.0608 111.19 27.9566L69.9949 6.42562C64.6997 3.65806 58.3764 3.69835 53.1169 6.53316Z"
        stroke="#F0ECFC"
        strokeWidth="8"
        strokeLinejoin="round"
      />
    </svg>
  )
);
