import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Snackbar, UiStore } from '../../interfaces';

const initialState: UiStore = {
  snackbarMessage: '',
  snackbarType: 'info',
  uploadFile: false,
  updateSummary: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSnackbarMessage: (state: UiStore, action: PayloadAction<string>) => {
      state.snackbarMessage = action.payload;
    },
    setSnackbarType: (state: UiStore, action: PayloadAction<Snackbar>) => {
      state.snackbarType = action.payload;
    },
    setUploadAction: (state: UiStore, action: PayloadAction<boolean>) => {
      state.uploadFile = action.payload;
    },
    setUpdateSummary: (state: UiStore, action: PayloadAction<boolean>) => {
      state.updateSummary = action.payload;
    },
  },
});

export const { setSnackbarMessage, setSnackbarType, setUploadAction, setUpdateSummary } =
  uiSlice.actions;

export default uiSlice.reducer;
