import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useHeader } from '../../../hooks/useHeader';
import { useQuery } from '../../../hooks/useQuery';
import { useRequester } from '../../../hooks/useRequester';
import { useToast } from '../../../hooks/useToast';
import { AssessmentById } from '../../../interfaces';
import { getAssessmentById, getPocDetails } from '../../../services';
import SegmentService from '../../../services/segment';
import { Page } from '../Page/Page';
import { Conditions } from './Conditions/Conditions';
import { History } from './History/History';
import { Information } from './Information/Information';
import { PocDetailsProps } from './PocDetailsPage.types';
import { Recommendation } from './Recommendation/Recommendation';
import { LeftContainer, PocDetailsContainer, RightContainer } from './styles';

export const PocDetailsPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setPageHeader } = useHeader();

  const { get } = useRequester();
  const { get: query } = useQuery();

  const { toast } = useToast();
  const { permissions, selected } = useGetUserPreferences();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assessment, setAssessment] = useState<AssessmentById | null>(null);
  const [pocDetails, setPocDetails] = useState<PocDetailsProps | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasEdit, setHasEdit] = useState<boolean>(false);

  const isFirstRender = useRef(true);

  const getAssessment = async () => {
    setIsLoading(true);
    const response = await getAssessmentById({ api: { get }, params: { id } });

    if (!response?.success) {
      setHasError(true);
      setIsLoading(false);
      return;
    }

    setAssessment(response.data);
  };

  const getData = async () => {
    setIsLoading(true);
    const response = await getPocDetails({
      api: { get },
      params: { assessmentId: id, beesAccountId: assessment.beesAccountId },
    });

    if (!response?.success) {
      setHasError(true);
      setIsLoading(false);
      return;
    }

    setPocDetails(response.data);
    setIsLoading(false);
  };

  const onCallback = async () => setHasEdit(!hasEdit);

  const onOpenEditRecommendationModal = (isOpen: boolean) => {
    if (isOpen) {
      SegmentService.paymentsButtonClicked(
        'Edit Recommendation',
        'Edit Recommendation Button',
        selected.vendorId,
        'Credit Management Edit Line Active',
        'POC_DETAILS_PAGE',
        null
      );
    }
  };

  useEffect(() => {
    getAssessment();
  }, []);

  useEffect(() => {
    if (assessment) {
      setPageHeader({ page: 'pocDetails', attributes: { pageTitle: assessment.pocName } });

      getData();
    }
  }, [assessment]);

  useEffect(() => {
    if ((!query('country') || !query('vendorId')) && !isLoading && (hasError || !pocDetails)) {
      navigate('/bees-credit-management/credit');

      toast.error({ message: 'errors.default' });
    }
  }, [isLoading, hasError, pocDetails]);

  useEffect(() => {
    if (!isFirstRender.current) {
      getAssessment();

      getData();
    }

    isFirstRender.current = false;
  }, [hasEdit]);

  if (!permissions.has('pages.pocDetails')) {
    return <Page.Error type="403" />;
  }

  if (isLoading) {
    return <Page.Loading />;
  }

  if (!isLoading && hasError) {
    return <Page.Error type="404" />;
  }

  return (
    <PocDetailsContainer data-testid="poc-details-container">
      <LeftContainer>
        <Recommendation
          data={assessment}
          recommendation={pocDetails.creditLineRecommendation}
          timeline={pocDetails.timeline}
          onCallback={onCallback}
        />

        <Conditions
          data={assessment}
          conditions={pocDetails.currentCreditLineConditions}
          onCallback={onCallback}
          onOpenEditRecommendationModal={onOpenEditRecommendationModal}
        />

        <Information information={pocDetails.pocInformation} />
      </LeftContainer>

      <RightContainer>
        <History.Credit
          beesAccountId={assessment.beesAccountId}
          creditHistory={pocDetails.creditHistory}
        />
      </RightContainer>
    </PocDetailsContainer>
  );
};
