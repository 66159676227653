import { Currency } from '@hexa-ui/icons';
import { Files } from '../../components/icons/Files/Files.svg';
import { SideBarItems } from '../../interfaces';

export const sidebar: SideBarItems = {
  credit: {
    icon: Currency,
    title: 'routes.credit',
    path: '/bees-credit-management/credit',
    permission: 'pages.credit',
  },
  files: {
    icon: Files,
    title: 'routes.files',
    path: '/bees-credit-management/files',
    permission: 'tables.files',
  },
};
