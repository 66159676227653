import styled from 'styled-components';

export const FilterOptionsItem = styled.div<{ $align: 'center' | 'flex-start' | 'flex-end' }>`
  display: flex;
  align-items: ${({ $align }) => $align};
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
`;
