import { useLocation } from 'react-router-dom';

interface UseQuery {
  get: (key: string) => string | null;
}

export const useQuery = (): UseQuery => {
  const { search } = useLocation();

  if (!search) return { get: () => null };

  const query = new URLSearchParams(search);

  return { get: query.get.bind(query) };
};
