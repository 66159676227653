import React, { useEffect } from 'react';
import { CreditPage as Page } from '../../components/pages';
import { useHeader } from '../../hooks/useHeader';

export const CreditPage: React.FC = () => {
  const { setPageHeader } = useHeader();

  useEffect(() => {
    setPageHeader({ page: 'credit' });
  }, []);

  return <Page />;
};
