import React from 'react';
import { useIntl } from 'react-intl';
import { status as StatusMapping } from '../../../../constants/tables';
import { useFormatting } from '../../../../hooks/useFormatting';
import { Tables, TextDateParams, TextProps, TextType } from '../../../../interfaces';
import { StatusColumn } from '../../../atoms/StatusColumn';
import { CustomPargraph } from './styles';

export const Text: React.FC<TextProps<TextType>> = React.memo(({ type, params, hasEllipsis }) => {
  const { formatting } = useFormatting();
  const { value, width } = params;

  const { formatted } = formatting({
    type,
    value: value || '-',
    config: { datetimeType: (params as TextDateParams).type },
  });

  return (
    <CustomPargraph title={formatted.completed} $width={width} $hasellipsis={hasEllipsis}>
      {formatted.completed}
    </CustomPargraph>
  );
});

interface StatusProps {
  table: Tables;
  status: string;
}

export const Status: React.FC<StatusProps> = React.memo(({ table, status }) => {
  const { formatMessage } = useIntl();

  const { color, text } = Object.entries(StatusMapping[table].status).reduce(
    (acc, [key, value]) => {
      if (value.includes(status)) {
        const [color, text] = key.split(':');

        acc = { color, text };
      }

      return acc;
    },
    { color: '', text: '' }
  );

  return <StatusColumn color={color} text={formatMessage({ id: text })} />;
});
