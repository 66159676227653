import { Check, DollarSign, Edit2, Lock, Unlock, X, Zap } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { CreditHistoryStatus } from '../interfaces';

export const useCreditHistoryTitle = (status: string) => {
  const { formatMessage } = useIntl();

  const statusMapping: { [key: string]: CreditHistoryStatus } = {
    APPROVED: CreditHistoryStatus.CREDIT_RECOMMENDATION_APPROVED,
    APPROVED_BY_BU: CreditHistoryStatus.CREDIT_RECOMMENDATION_APPROVED,
    DENIED: CreditHistoryStatus.CREDIT_RECOMMENDATION_DENIED,
    DENIED_BY_BU: CreditHistoryStatus.CREDIT_RECOMMENDATION_DENIED,
    POC_IN_OVERDUE: CreditHistoryStatus.CREDIT_LINE_BLOCKED,
    POC_OUT_OF_OVERDUE: CreditHistoryStatus.CREDIT_LINE_UNBLOCKED,
    ACTIVATED: CreditHistoryStatus.CREDIT_LINE_ACTIVATED,
    WAITING_FOR_BU: CreditHistoryStatus.NEW_CREDIT_RECOMMENDATION,
    WAITING_FOR_APPROVAL: CreditHistoryStatus.NEW_CREDIT_RECOMMENDATION,
  };

  const enumStatus = statusMapping[status];

  const creditHistoryTitle = {
    [CreditHistoryStatus.NEW_CREDIT_RECOMMENDATION]: {
      icon: <DollarSign size="medium" style={{ color: '#0363C4' }} />,
      text: formatMessage({
        id: 'pages.poc-details.history.credit.status.newCreditRecommendation',
      }),
      backgroundColor: '#E9F1FF',
    },
    [CreditHistoryStatus.CREDIT_RECOMMENDATION_APPROVED]: {
      icon: <Check size="medium" style={{ color: '#176D38' }} />,
      text: formatMessage({ id: 'pages.poc-details.history.credit.status.approvedRecommendation' }),
      method: formatMessage({ id: 'pages.poc-details.history.credit.labels.method.approval' }),
      backgroundColor: '#E6F2EB',
    },
    [CreditHistoryStatus.CREDIT_SUGGESTION_EDITED]: {
      icon: <Edit2 size="medium" style={{ color: '#0363C4' }} />,
      text: formatMessage({ id: 'pages.poc-details.history.credit.status.editedSuggestion' }),
      method: formatMessage({ id: 'pages.poc-details.history.credit.labels.method.edit' }),
      backgroundColor: '#E9F1FF',
    },
    [CreditHistoryStatus.CREDIT_CONDITIONS_EDITED]: {
      icon: <Edit2 size="medium" style={{ color: '#0363C4' }} />,
      text: formatMessage({ id: 'pages.poc-details.history.credit.status.creditConditionsEdited' }),
      method: formatMessage({ id: 'pages.poc-details.history.credit.labels.method.edit' }),
      backgroundColor: '#E9F1FF',
    },
    [CreditHistoryStatus.CREDIT_RECOMMENDATION_DENIED]: {
      icon: <X size="medium" style={{ color: '#E53C32' }} />,
      text: formatMessage({ id: 'pages.poc-details.history.credit.status.recommendationDenied' }),
      method: formatMessage({ id: 'pages.poc-details.history.credit.labels.method.denial' }),
      backgroundColor: '#FCECEB',
    },
    [CreditHistoryStatus.CREDIT_LINE_BLOCKED]: {
      icon: <Lock size="medium" style={{ color: 'E53C32' }} />,
      text: formatMessage({ id: 'pages.poc-details.history.credit.status.creditLineBlocked' }),
      backgroundColor: '#FCECEB',
    },
    [CreditHistoryStatus.CREDIT_LINE_UNBLOCKED]: {
      icon: <Unlock size="medium" style={{ color: '#FFAA33' }} />,
      text: formatMessage({ id: 'pages.poc-details.history.credit.status.creditLineUnblocked' }),
      backgroundColor: '#FFF7EB',
    },
    [CreditHistoryStatus.CREDIT_LINE_ACTIVATED]: {
      icon: <Zap size="medium" style={{ color: '#A080FF' }} />,
      text: formatMessage({ id: 'pages.poc-details.history.credit.status.creditLineActivated' }),
      backgroundColor: '#F6F2FF',
    },
  };

  return creditHistoryTitle[enumStatus];
};
