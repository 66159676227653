import { useEffect } from 'react';
import { FilesPage as Page } from '../../components/pages';
import { useHeader } from '../../hooks/useHeader';

export const FilesPage: React.FC = () => {
  const { setPageHeader } = useHeader();

  useEffect(() => {
    setPageHeader({ page: 'files' });
  }, []);

  return <Page />;
};
