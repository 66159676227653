import { Grid } from '@hexa-ui/components';
import styled from 'styled-components';

export const Container = styled(Grid.Container)`
  padding-top: 24px;
  padding-bottom: 24px;

  box-sizing: border-box;
`;

export const HeaderContent = styled.div<{ $hasbreadcrumbs: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: ${({ $hasbreadcrumbs }) => ($hasbreadcrumbs ? 'space-between' : 'flex-end')};
  flex-direction: row;
  min-height: 38px;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  column-gap: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  column-gap: 16px;
`;

export const Link = styled.div`
  cursor: pointer;
`;
