import { Table as HexaTable } from '@hexa-ui/components';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { GenericObject } from '../../../interfaces';
import { EmptyMessage } from './Empty/EmptyMessage';
import { TableExtra } from './Extra/TableExtra';
import { TableProps } from './Table.types';
import { TableContainer } from './styles';

export const Table: React.FC<TableProps> = ({
  data,
  columns,
  loading,
  messages,
  tableHeight = 'min(100vh - 350px)',
  search = { has: false },
  views = { has: false },
  filters = { has: false, chips: false },
  selectable = { has: false, hasValue: false },
  pagination = { page: 1, size: 100 },
  toolbarExtra,
  onRow,
}) => {
  const { formatMessage } = useIntl();

  const { configs } = useGetUserPreferences();
  const { pagination: paginationConfig } = configs;

  const onRowClick = useCallback(
    (rows: GenericObject) => ({ onClick: () => onRow(rows) }),
    [onRow]
  );

  const tablePagination = useMemo(() => {
    return {
      quantityIndicatorIntl: formatMessage({ id: 'tables.pagination.quantity' }),
      pageSizeOptionsIntl: (option: number) =>
        formatMessage({ id: 'tables.pagination.itensPerPage' }, { numberPage: option }),
      current: pagination.page,
      pageSize: pagination.size,
      pageSizeOptions: paginationConfig.sizeOptions,
      showPageSizeSelector: true,
      ...(pagination.total && { total: pagination.total }),
      ...(pagination.onChange && { onChange: pagination.onChange }),
    };
  }, [pagination]);

  const tableSelectable = useMemo(() => {
    if (!selectable.has) return null;

    return {
      headerSelectionType: 0,
      displayTextDeselectAll: formatMessage({ id: 'tables.selectable.deselect' }),
      displayTextOneRowSelected: formatMessage({ id: 'tables.selectable.selected.singular' }),
      displayTextMoreThanOneRowSelected: formatMessage({
        id: 'tables.selectable.selected.plural',
      }),
      onDeselectAll: () => selectable.onSelected([]),
      onRowSelect: (row: GenericObject) => selectable.onSelected(row),
      onAllPageRowsSelect: (rows: GenericObject) => selectable.onSelected(rows),
      useCheckbox: true,
    };
  }, [selectable]);

  const getToolbarExtra = useCallback(() => {
    return (
      <TableExtra
        search={search}
        views={views}
        filters={filters}
        selectable={selectable}
        toolbarExtra={toolbarExtra}
      />
    );
  }, [search, filters, selectable.hasValue, toolbarExtra]);

  return (
    <TableContainer
      border="medium"
      elevated="small"
      data-testid="table-container"
      $hasselectable={selectable.hasValue}
    >
      <HexaTable
        loading={loading}
        columns={columns}
        data={data}
        fixedHeader
        tableHeight={tableHeight}
        tableWidth="100%"
        loadingMessage={messages.loading}
        emptyMessage={<EmptyMessage message={messages.empty.message} Icon={messages.empty.Icon} />}
        toolbarExtra={getToolbarExtra}
        selectable={tableSelectable}
        pagination={tablePagination}
        onRow={onRow && onRowClick}
      />
    </TableContainer>
  );
};
