import { Button as HexaButton, Paragraph } from '@hexa-ui/components';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Button = styled(HexaButton)<{ $width?: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  padding-right: 8px;
  ${({ $width }) => $width ?? 'auto'};

  :nth-child(1) {
    background: transparent;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  column-gap: 8px;
  width: 100%;

  background: transparent !important;
`;

export const CustomParagraph = styled(Paragraph)<{ $width?: number }>`
  max-width: ${({ $width }) => ($width ? `calc(${$width}px - ${$width / 4}px)` : 'auto')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
