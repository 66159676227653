import styled, { css } from 'styled-components';

export const CreditModalContent = styled.div<{ $align: boolean; $haspagination: boolean }>`
  display: flex;
  align-items: ${({ $align }) => ($align ? 'center' : 'flex-start')};
  justify-content: ${({ $align }) => ($align ? 'center' : 'flex-start')};
  flex-direction: column;

  width: 100%;
  min-width: 688px;
  height: 600px;
  overflow: hidden;

  ${({ $haspagination }) => {
    if (!$haspagination) {
      return css`
        padding-bottom: 24px;
      `;
    }
  }}
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  row-gap: 16px;
  background: white;
  z-index: 1;
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 24px 0 16px 32px;
  row-gap: 32px;
  overflow-y: auto;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  width: 100%;
  padding: 16px 32px 16px 32px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);

  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  position: sticky;
  bottom: 0;
  background: white;
  z-index: 1;

  div[data-testid='pagination-container'] {
    height: 48px !important;
  }
`;
