import { useIntl } from 'react-intl';
import { StatusCurrentCreditLine, StatusRecommendation } from '../interfaces';

interface IStatusLabelProps {
  background: string;
  color: string;
  icon: string;
  translation: string;
}

export const StatusLabelProps = (status: string): IStatusLabelProps => {
  const { formatMessage } = useIntl();

  const options: { [key: string]: IStatusLabelProps } = {
    [StatusCurrentCreditLine.ACTIVE]: {
      background: '#EAF8EE',
      color: '#2AA147',
      icon: '#31BD54',
      translation: formatMessage({ id: 'pages.poc-details.conditions.status.active' }),
    },
    [StatusRecommendation.PENDING]: {
      background: '#FFFBEB',
      color: '#D9B62B',
      icon: '#D9B62B',
      translation: formatMessage({ id: 'pages.poc-details.recommendation.status.pending' }),
    },
    [StatusRecommendation.DENIED]: {
      background: 'rgba(20, 20, 20, 0.08)',
      color: 'rgba(20, 20, 20, 0.32)',
      icon: 'rgba(20, 20, 20, 0.32)',
      translation: formatMessage({ id: 'pages.poc-details.recommendation.status.denied' }),
    },
    [StatusRecommendation.WAITING_ACTIVATION]: {
      background: '#F6F2FF',
      color: '#886DD9',
      icon: '#A080FF',
      translation: formatMessage({
        id: 'pages.poc-details.recommendation.status.waitingActivation',
      }),
    },
    [StatusRecommendation.WAITING_ONBOARDING]: {
      background: '#F6F2FF',
      color: '#886DD9',
      icon: '#A080FF',
      translation: formatMessage({
        id: 'pages.poc-details.recommendation.status.waitingOnboarding',
      }),
    },
    [StatusCurrentCreditLine.BLOCKED]: {
      background: '#FCECEB',
      color: '#C3332B',
      icon: '#E53C32',
      translation: formatMessage({ id: 'pages.poc-details.conditions.status.blocked' }),
    },
    [StatusCurrentCreditLine.OVERDUE]: {
      background: '#FFF7EB',
      color: '#D9912B',
      icon: '#FFAA33',
      translation: formatMessage({ id: 'pages.poc-details.conditions.status.overdue' }),
    },
    [StatusCurrentCreditLine.DUE]: {
      background: '#E7F2FF',
      color: '#0F7FFA',
      icon: '#0F7FFA',
      translation: formatMessage({ id: 'pages.poc-details.conditions.status.due' }),
    },
  };

  return options[status] || options[StatusRecommendation.WAITING_ACTIVATION];
};
