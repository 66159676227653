import Snippet from '@segment/snippet';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Application } from '../../../configs/application';
import { CountryConfigType } from '../../../interfaces';
import { useSelected } from '../../../store/hooks/selectedHook';

export const AnalyticsScriptTag = () => {
  const ApplicationConfig = Application();
  const [snippet, setSnippet] = useState<any>();

  const country = useSelected()?.selected?.country?.toLowerCase() as CountryConfigType;

  useEffect(() => {
    window.analytics && delete window.analytics;

    const analyticsCode = ApplicationConfig?.byCountry(country.toLowerCase() as CountryConfigType)
      .analytics?.analyticsCode;

    const snippet = Snippet.max({
      host: 'cdn.segment.com',
      apiKey: analyticsCode,
    });

    setSnippet(snippet);
  }, [country]);

  if (!Object.values(CountryConfigType).includes(country)) {
    return null;
  }

  return (
    <Helmet>
      <script>{snippet}</script>
    </Helmet>
  );
};
