import { Button } from '@hexa-ui/components';
import { Download, Upload } from '@hexa-ui/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Modal } from './Modal/Modal';
import { FileButtonsContainer } from './styles';

export const FileButtons: React.FC = () => {
  const { formatMessage } = useIntl();

  const { permissions } = useGetUserPreferences();

  const navigate = useNavigate();

  if (!permissions.has('actions.upload') && !permissions.has('actions.download.assessments')) {
    return null;
  }

  return (
    <FileButtonsContainer data-testid="file-buttons-container">
      {permissions.has('actions.upload') && (
        <Button
          id="upload-button"
          icon={Upload}
          size="large"
          leading
          variant="secondary"
          onClick={() => navigate('/bees-credit-management/upload')}
          css={{ minWidth: 'auto', whiteSpace: 'nowrap' }}
        >
          {formatMessage({ id: 'files.buttons.upload' })}
        </Button>
      )}

      <Modal
        trigger={
          <Button
            id="download-button"
            icon={Download}
            size="large"
            leading
            variant="primary"
            css={{ minWidth: 'auto', whiteSpace: 'nowrap' }}
          >
            {formatMessage({ id: 'files.buttons.download' })}
          </Button>
        }
      />
    </FileButtonsContainer>
  );
};
