import { Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { useCreditHistoryTitle } from '../../../../../../../hooks/useCreditHistoryTitle';
import { useFormatting } from '../../../../../../../hooks/useFormatting';
import { useGetUserPreferences } from '../../../../../../../hooks/useGetUserPreferences';
import { Content, Subheader, Title } from '../../../../styles';
import { CreditHistoryItemProps } from '../../CreditHistory.types';
import { DetailsContent, IconContainer, ItemContainer, ItemContent, TextContainer } from './styles';

export const ModalItem: React.FC<CreditHistoryItemProps & { hasDate: boolean }> = ({
  hasDate,
  history,
}) => {
  const { formatMessage } = useIntl();

  const { formatting } = useFormatting();
  const { datetime } = useGetUserPreferences();
  const { text, method, icon, backgroundColor } = useCreditHistoryTitle(
    history.status.toUpperCase()
  );

  return (
    <ItemContainer data-testid="item-container">
      <Paragraph size="small" colortype="disabled">
        {hasDate ? datetime.format(history.date, 'long') : null}
      </Paragraph>

      <ItemContent>
        <IconContainer $backgroundcolor={backgroundColor}>{icon}</IconContainer>

        <DetailsContent>
          <TextContainer>
            <Title size="H5">{text}</Title>

            <Paragraph size="basis">
              {formatMessage(
                { id: 'pages.poc-details.history.credit.labels.byAndHour' },
                {
                  identifier: history.identifier || '-',
                  hour: datetime.format(history.date, 'time').split(' ')[1] || '-',
                }
              )}
            </Paragraph>
          </TextContainer>

          {history.creditLimit && (
            <TextContainer>
              <Subheader size="H5">
                {formatMessage({ id: 'pages.poc-details.history.credit.labels.creditLimit' })}
              </Subheader>

              <Content size="basis">
                {formatting({ type: 'currency', value: history.creditLimit }).formatted.completed}
              </Content>
            </TextContainer>
          )}

          {history.termFee && (
            <TextContainer>
              <Subheader size="H5">
                {formatMessage({ id: 'pages.poc-details.history.credit.labels.termFee' })}
              </Subheader>

              <Content size="basis">
                {
                  formatting({
                    type: 'term-fee',
                    value: { term: history.termFee.term, fee: history.termFee.fee },
                  }).formatted.completed
                }
              </Content>
            </TextContainer>
          )}

          {history.updateMethod && method && (
            <TextContainer>
              <Subheader size="H5">{method}</Subheader>

              <Content size="basis">
                {
                  formatting({ type: 'approval-method', value: history.updateMethod }).formatted
                    .completed
                }
              </Content>
            </TextContainer>
          )}
        </DetailsContent>
      </ItemContent>
    </ItemContainer>
  );
};
