import React, { useCallback, useMemo, useState } from 'react';
import { useGetSources } from '../../../hooks/useGetSources';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../hooks/useRequester';
import { useToast } from '../../../hooks/useToast';
import { ActiveTabs, AssessmentTag, Status, Tables } from '../../../interfaces';
import { updateStatus } from '../../../services';
import { useViews } from '../../../store/hooks/customViewsHook';
import {
  ApprovalButtonsProps,
  ApprovalProps,
  MappedPropsFn,
  OnUpdateFnProps,
} from './ApprovalButtons.types';
import { Approve } from './Approve/Approve';
import { Deny } from './Deny/Deny';
import { ApprovalButtonsContainer } from './styles';

export const ApprovalButtons: React.FC<ApprovalButtonsProps> = ({
  mode,
  type,
  button,
  selected,
  callbackFn,
}) => {
  const { post } = useRequester();
  const { toast } = useToast();

  const { activetags } = useViews();

  const { user, permissions } = useGetUserPreferences();
  const { onboardingRule } = useGetSources();

  const [isLoading, setIsLoading] = useState({ APPROVED: false, DENIED: false });

  const approvalProps = useMemo(
    () => ({ mode, type, button, selected } as ApprovalProps),
    [mode, type, button, selected]
  );

  const { hasApproval } = useMemo(() => {
    const tab = sessionStorage.getItem(ActiveTabs.ACTIVE_TAB_KEY) as Tables | null;
    const { tag } = activetags.find(({ table }) => table === tab) ?? activetags[0];

    return { hasApproval: tag === AssessmentTag.RECOMMENDED };
  }, [activetags]);

  const mapped = useCallback(
    ({ status, attributes }: MappedPropsFn) => ({
      bees_account_id: attributes.beesAccountId,
      status,
      user_id: user.name,
      ...(attributes.has && {
        attributes: [
          { name: 'ONBOARDING_BYPASS', value: attributes.value.toString() },
          { name: 'POC_DOCUMENT', value: attributes.pocDocument },
        ],
      }),
    }),
    [user.name]
  );

  const onUpdate = async ({ params, toasts, status }: OnUpdateFnProps) => {
    const response = await updateStatus({ api: { post }, params });

    if (!response?.success) {
      toast.error({ message: 'errors.generic' });

      return;
    }

    if (status === Status.APPROVED) {
      toast.success({ message: toasts.message, attributes: toasts.attributes });
    } else {
      toast.error({ message: toasts.message, attributes: toasts.attributes });
    }

    if (callbackFn) callbackFn();
  };

  const onApproval = useCallback(
    async (status: Status, value?: boolean) => {
      setIsLoading((prev) => ({ ...prev, [status]: true }));
      const hasAttr = onboardingRule && status === Status.APPROVED;

      if (mode === 'multiple') {
        const params = selected.map((row) => {
          const { beesAccountId, pocDocument } = row.values.actions.selected;

          return mapped({
            status,
            attributes: { has: hasAttr, value, beesAccountId, pocDocument },
          });
        });

        onUpdate({
          status,
          params,
          toasts: {
            message: `approval.toasts.${status}.${selected.length > 1 ? 'multiple' : 'single'}`,
            attributes: {
              value: selected.length > 1 ? selected.length : selected[0].values.pocName,
            },
          },
        }).finally(() => setIsLoading((prev) => ({ ...prev, [status]: false })));

        return;
      }

      const { beesAccountId, pocDocument } = selected;

      onUpdate({
        status,
        params: [
          mapped({ status, attributes: { has: hasAttr, value, beesAccountId, pocDocument } }),
        ],
        toasts: {
          message: `approval.toasts.${status}.single`,
          attributes: { value: selected.pocName },
        },
      }).finally(() => setIsLoading((prev) => ({ ...prev, [status]: false })));
    },
    [onboardingRule, selected]
  );

  if (!permissions.has('actions.approve') && !permissions.has('actions.deny')) {
    return null;
  }

  return (
    <ApprovalButtonsContainer data-testid="approval-buttons-container">
      <Deny {...approvalProps} isLoading={isLoading.DENIED} onApproval={onApproval} />

      {hasApproval && (
        <Approve {...approvalProps} isLoading={isLoading.APPROVED} onApproval={onApproval} />
      )}
    </ApprovalButtonsContainer>
  );
};
