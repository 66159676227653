import { TypeToast, useToast as useBeesOneToast } from 'admin-portal-shared-services';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

interface ToastTypeProps {
  type: TypeToast;
}

interface ToastProps {
  message: string;
  attributes?: Record<string, string | number>;
}

export const useToast = () => {
  const { formatMessage } = useIntl();
  const beesOneToast = useBeesOneToast();

  const notify = useCallback(
    ({ type }: ToastTypeProps) =>
      ({ message, attributes }: ToastProps) =>
        beesOneToast.notify({ type, message: formatMessage({ id: message }, attributes) }),
    []
  );

  const toast = {
    info: notify({ type: TypeToast.INFO }),
    success: notify({ type: TypeToast.SUCCESS }),
    warning: notify({ type: TypeToast.WARNING }),
    error: notify({ type: TypeToast.ERROR }),
  };

  return { toast };
};
