export * from './components';
export * from './configs';
export * from './contexts';
export * from './hooks';
export * from './providers';
export * from './services';
export * from './stores';
export * from './utils';

export enum UserTypes {
  SELLER = 'SELLER',
  ANALYST = 'ANALYST',
  FULL = 'FULL',
}

export enum ActiveTabs {
  ACTIVE_TAB_KEY = 'activeTab',
}

export enum UserGroups {
  VIEWER = 'VIEWER',
  MANAGER = 'MANAGER',
  ADMIN = 'ADMIN',
}

export enum Tables {
  PENDING = 'pending',
  INPROGRESS = 'inprogress',
  COMPLETED = 'completed',
  FILES = 'files',
}

export enum CustomViewType {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

export enum CreditRecommendation {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
  NEW_CREDIT_OFFER = 'NEW_CREDIT_OFFER',
}

export enum ApprovalMethod {
  FILE_BATCH_UPDATE = 'FILE_BATCH_UPDATE',
  SYSTEM = 'SYSTEM',
}

export enum PaymentMethod {
  BANK_SLIP = 'BANK_SLIP',
  BANK_SLIP_INSTALLMENT = 'BANK_SLIP_INSTALLMENT',
  CREDIT = 'CREDIT',
  CASH = 'CASH',
}

export enum StatusCurrentCreditLine {
  DUE = 'DUE',
  OVERDUE = 'OVERDUE',
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
}

export enum StatusRecommendation {
  PENDING = 'PENDING',
  DENIED = 'DENIED',
  WAITING_ACTIVATION = 'WAITING_ACTIVATION',
  WAITING_ONBOARDING = 'WAITING_ONBOARDING',
}

export enum CreditHistoryStatus {
  NEW_CREDIT_RECOMMENDATION = 'NEW_CREDIT_RECOMMENDATION',
  CREDIT_RECOMMENDATION_APPROVED = 'CREDIT_RECOMMENDATION_APPROVED',
  CREDIT_SUGGESTION_EDITED = 'CREDIT_SUGGESTION_EDITED',
  CREDIT_CONDITIONS_EDITED = 'CREDIT_CONDITIONS_EDITED',
  CREDIT_RECOMMENDATION_DENIED = 'CREDIT_RECOMMENDATION_DENIED',
  CREDIT_LINE_BLOCKED = 'CREDIT_LINE_BLOCKED',
  CREDIT_LINE_UNBLOCKED = 'CREDIT_LINE_UNBLOCKED',
  CREDIT_LINE_ACTIVATED = 'CREDIT_LINE_ACTIVATED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  POC_IN_OVERDUE = 'POC_IN_OVERDUE',
  POC_OUT_OF_OVERDUE = 'POC_OUT_OF_OVERDUE',
  ACTIVATED = 'ACTIVATED',
  WAITING_FOR_BU = 'WAITING_FOR_BU',
}

export enum AssessmentTag {
  RECOMMENDED = 'RECOMMENDED',
  NOT_RECOMMENDED = 'NOT_RECOMMENDED',
}

export interface GenericObject {
  [key: string]: any;
}

export type GenericResponse = GenericObject | undefined | null;

export interface FilesConfig {
  output?: string;
  mimetype: string;
  responseType: string;
  accept?: string;
  size?: number;
}

export interface GetSet<T> {
  value: T;
  setter: React.Dispatch<React.SetStateAction<T>>;
}

export interface Pagination {
  page?: number;
  size?: number;
}

export type TextType =
  | 'currency'
  | 'percentage'
  | 'days'
  | 'fee'
  | 'datetime'
  | 'number'
  | 'text'
  | 'term-fee'
  | 'recommendation'
  | 'approval-method'
  | 'payment-method';

export interface AssessmentById {
  assessmentId: string;
  beesAccountId: string;
  accountId: string;
  pocName: string;
  pocDocument: string;
  vendorId: string;
  country: string;
  creditLimit: string;
  term: string;
  fee: string;
}
