import { HeaderConfigMapping } from '../../interfaces/hooks';

export const headerConfig: HeaderConfigMapping = {
  empty: {
    pageTitle: 'pages.credit.title',
    breadcrumbConfig: {
      homePath: '/',
      items: [],
    },
  },
  credit: {
    pageTitle: 'pages.credit.title',
    breadcrumbConfig: {
      homePath: '/bees-credit-management/',
      items: [
        {
          label: 'routes.credit',
          path: '/bees-credit-management/credit',
          isCurrentPage: true,
        },
      ],
    },
  },
  pocDetails: {
    pageTitle: '',
    breadcrumbConfig: {
      homePath: '/bees-credit-management/',
      items: [
        {
          label: 'routes.credit',
          path: '/bees-credit-management/credit',
          isCurrentPage: false,
        },
        {
          label: 'routes.pocDetails',
          path: '/bees-credit-management/poc-details',
          isCurrentPage: true,
        },
      ],
    },
  },
  files: {
    pageTitle: 'pages.files.title',
    breadcrumbConfig: {
      homePath: '/bees-credit-management/',
      items: [
        {
          label: 'routes.credit',
          path: '/bees-credit-management/credit',
          isCurrentPage: false,
        },
        {
          label: 'routes.files',
          path: '/bees-credit-management/files',
          isCurrentPage: true,
        },
      ],
    },
  },
  upload: {
    pageTitle: 'pages.upload.title',
    breadcrumbConfig: {
      homePath: '/bees-credit-management/',
      items: [
        {
          label: 'routes.credit',
          path: '/bees-credit-management/credit',
          isCurrentPage: false,
        },
        {
          label: 'routes.files',
          path: '/bees-credit-management/files',
          isCurrentPage: false,
        },
        {
          label: 'routes.upload',
          path: '/bees-credit-management/upload',
          isCurrentPage: true,
        },
      ],
    },
  },
  views: {
    pageTitle: 'pages.views.title',
    breadcrumbConfig: {
      homePath: '/bees-credit-management/',
      items: [
        {
          label: 'routes.credit',
          path: '/bees-credit-management/credit',
          isCurrentPage: false,
        },
        {
          label: 'routes.views',
          path: '/bees-credit-management/custom-view',
          isCurrentPage: true,
        },
      ],
    },
  },
};

export const pathnameRegex = {
  pocDetails: /^.*\/poc-details\/[^/]+$/,
  credit: /(^\/bees-credit-management$)|(^.*\/credit$)/,
};

export const countries = [
  'AQ',
  'AR',
  'BB',
  'BE',
  'BO',
  'BR',
  'CA',
  'IC',
  'CL',
  'CO',
  'CZ',
  'DO',
  'EC',
  'SV',
  'DE',
  'HN',
  'IN',
  'ID',
  'MX',
  'NL',
  'NG',
  'PA',
  'PY',
  'PE',
  'PH',
  'ZA',
  'KR',
  'TZ',
  'TH',
];
