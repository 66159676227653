import { Status, Text } from '../../../components/organisms/Tables/custom-columns';
import { Actions } from '../../../components/organisms/Tables/Files/custom-columns';
import { ColumnProps, Tables } from '../../../interfaces';

export const columns: ColumnProps[] = [
  {
    id: 'tables.files.columns.name',
    accessor: 'fileName',
    customRender: (value: string) => <Text type="text" params={{ value, width: 20 }} hasEllipsis />,
  },
  {
    id: 'tables.files.columns.description',
    accessor: 'fileDescription',
    disableSortBy: true,
    customRender: (value: string) => <Text type="text" params={{ value, width: 25 }} hasEllipsis />,
  },
  {
    id: 'tables.files.columns.totalPOCs',
    accessor: 'total',
    disableSortBy: true,
    customRender: (value: string) => <Text type="number" params={{ value, width: 5 }} />,
  },
  {
    id: 'tables.files.columns.uploadedBy',
    accessor: 'uploadedBy',
    customRender: (value: string) => <Text type="text" params={{ value, width: 10 }} hasEllipsis />,
  },
  {
    id: 'tables.files.columns.uploadedAt',
    accessor: 'uploadedAt',
    customRender: ({ value, type }) => <Text type="datetime" params={{ value, width: 20, type }} />,
  },
  {
    id: 'tables.files.columns.status',
    accessor: 'status',
    disableSortBy: true,
    style: { width: '15%' },
    customRender: (status: string) => <Status table={Tables.FILES} status={status} />,
  },
  {
    id: 'tables.files.columns.actions',
    accessor: 'actions',
    disableSortBy: true,
    style: { width: '5%' },
    customRender: ({ type, fileId, fileName, filesConfig }) => (
      <Actions type={type} fileId={fileId} fileName={fileName} filesConfig={filesConfig} />
    ),
  },
];

export const filters = [];
