import styled from 'styled-components';

export const InformationContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 24px;

  width: 100%;

  > p {
    margin-bottom: 16px;
  }
`;

export const InformationContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;

  width: 100%;
  column-gap: 24px;
`;

export const DetailsContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
`;

export const IconContainer = styled.div<{ $backgroundcolor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  width: 24px;
  border-radius: 50%;

  background-color: ${(props) => props.$backgroundcolor};

  svg {
    width: 24px;
  }
`;
