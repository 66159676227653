import { Badge } from '@hexa-ui/components';
import styled from 'styled-components';
import { StatusLabelProps } from '../../../utils/StatusLabelProps';

const { Status } = Badge;

export const Label = styled(Status)<{
  $status: string;
}>`
  border-radius: 4px;
  background-color: ${(props) => StatusLabelProps(props.$status).background};
  height: 28px;
  margin: 8px 0;

  p {
    color: ${(props) => StatusLabelProps(props.$status).color};
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  p::before,
  p:before {
    content: '';
    display: block;
    background: ${(props) => StatusLabelProps(props.$status).icon};
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
`;
