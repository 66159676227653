import { Divider, Heading, LoadingDots, Paragraph } from '@hexa-ui/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { MappedSummary } from '../../../../interfaces';
import { getSummary } from '../../../../services';
import { useCredit } from '../Context/CreditContext';
import { SummaryContainer, SummaryItem } from './styles';

export const Summary: React.FC = () => {
  const { formatMessage } = useIntl();
  const { get } = useRequester();
  const { language, configs, permissions } = useGetUserPreferences();
  const { status } = configs;

  const { hasApproval } = useCredit();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [summary, setSummary] = useState<MappedSummary>({
    pending: 0,
    inprogress: 0,
    active: 0,
    denied: 0,
    total: 0,
  });

  const summaryMapping = useMemo(() => {
    return {
      total: summary.total,
      pending: summary.pending,
      inprogress: summary.inprogress,
      active: summary.active,
      denied: summary.denied,
    };
  }, [summary]);

  const getData = async () => {
    setIsLoading(true);

    const response = await getSummary({ api: { get }, params: { status } });

    if (!response?.success) {
      setSummary({ pending: 0, inprogress: 0, active: 0, denied: 0, total: 0 });
      setIsLoading(false);

      return;
    }

    setSummary(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [hasApproval.value]);

  if (!permissions.has('sections.summary')) {
    return <></>;
  }

  return (
    <SummaryContainer elevated="small" border="medium" $isloading={isLoading}>
      {isLoading ? (
        <LoadingDots />
      ) : (
        Object.entries(summaryMapping).map(([key, value], index, array) => (
          <React.Fragment key={key}>
            <SummaryItem>
              <Paragraph size="small" css={{ lineHeight: '24px' }}>
                {formatMessage({ id: `pages.credit.summary.${key}` })}
              </Paragraph>

              <Heading size="H4">{Number(value).toLocaleString(language.locale)}</Heading>
            </SummaryItem>

            {index !== array.length - 1 && (
              <Divider orientation="vertical" css={{ height: '52px !important' }} />
            )}
          </React.Fragment>
        ))
      )}
    </SummaryContainer>
  );
};
