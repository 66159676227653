import { Card } from '@hexa-ui/components';
import styled from 'styled-components';

export const UploaderCard = styled(Card)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 60%;
  padding: 24px;
  gap: 24px;

  div[data-testid='drag-and-drop-area'] {
    justify-content: flex-start !important;

    & > :nth-child(2) {
      align-items: flex-start !important;
    }
  }

  #file-description:disabled {
    opacity: 0.5;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  row-gap: 8px;
`;

export const TextAreaContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  row-gap: 8px;
`;

export const DescriptionTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  text-align: center;

  width: 100%;
  column-gap: 8px;
`;
