import {
  useAuthenticationService,
  UserMetadata,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { AxiosInstance } from 'axios';
import { useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { Page } from '../../components/pages/Page/Page';
import { AppContext } from '../../contexts';
import { messages } from '../../i18n';
import { ProviderProps } from '../../interfaces';

export const AppProvider: React.FC<ProviderProps> = ({ children }) => {
  const { data: metadata } = useUserMetadata();
  const authentication = useAuthenticationService();

  const { parsedJwt, data } = useMemo(() => {
    const parsedJwt = authentication.parseJwt();

    if (!metadata || !parsedJwt) {
      return { parsedJwt, data: null };
    }

    const enhancedMetadata = Object.entries(metadata).reduce((acc, [key, value]) => {
      if (key === 'authorization') {
        acc[key] = {
          groups: value.groups.filter((item: string) => item.includes('BeesCreditManagement')),
          scopes: value.scopes.filter((item: string) => item.includes('BeesCreditManagement')),
        };

        return acc;
      }

      acc[key] = value;
      return acc;
    }, {} as UserMetadata);

    if (!metadata.profile) {
      const locale = authentication.getLanguageByCountry(parsedJwt.country ?? 'US');
      const { hour12, timeZone } = new Intl.DateTimeFormat(locale).resolvedOptions();

      enhancedMetadata.profile = {
        preferredLanguage: locale,
        timezone: timeZone,
        preferredTimeFormat: hour12 ? 'h:mm a' : 'H:mm',
      };
    }

    return { parsedJwt, data: enhancedMetadata };
  }, [metadata, authentication]);

  const enhancedAxios = (axiosInstance: AxiosInstance, options?: any) => {
    return authentication.enhancedAxios(axiosInstance, options);
  };

  const getLanguageByCountry = (country: string) => {
    return authentication.getLanguageByCountry(country);
  };

  const value = useMemo(
    () => ({
      data,
      name: { full: parsedJwt?.name, first: parsedJwt?.given_name, last: parsedJwt?.family_name },
      enhancedAxios,
      getLanguageByCountry,
    }),
    [data, parsedJwt]
  );

  if (!data || !authentication || !parsedJwt) {
    return <Page.Loading />;
  }

  return (
    <AppContext.Provider value={value}>
      <IntlProvider
        locale={data.profile.preferredLanguage}
        defaultLocale={'en-US'}
        messages={messages[data.profile.preferredLanguage] ?? messages['en-US']}
      >
        {children}
      </IntlProvider>
    </AppContext.Provider>
  );
};
