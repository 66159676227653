import {
  Alert,
  Divider,
  Heading,
  Paragraph,
  ProgressMeter,
  TextLink,
  Tooltip,
} from '@hexa-ui/components';
import { Download, Info } from '@hexa-ui/icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { useToast } from '../../../../hooks/useToast';
import { UploadStatus } from '../../../../interfaces';
import { downloadFailedFile, getProgress } from '../../../../services';
import SegmentService from '../../../../services/segment';
import { useUpload } from '../Context';
import {
  ProgressCard,
  ProgressContainer,
  ProgressMeterContainer,
  TooltipContainer,
  TotalContainer,
  TotalTextContent,
} from './styles';

export const Progress: React.FC = () => {
  const { formatMessage } = useIntl();

  const { uploaded, progress, uploadError, processingStarted } = useUpload();
  const { fileId } = uploaded.value;
  const { status, total, totalAnalysis, validated, failed, processed } = progress.value;
  const [isSlowUpload, setIsSlowUpload] = useState(false);

  const { get } = useRequester();
  const { toast } = useToast();

  const { configs, selected } = useGetUserPreferences();
  const { filesConfig } = configs;

  const [analysisFailed, setAnalysisFailed] = useState(false);

  const interval = useRef<NodeJS.Timeout | null>(null);

  const checkProgressRetries = useRef(0);
  const shouldRetryOnErrors = useRef(true);

  const hasAnalyzed = useMemo(() => {
    return status === UploadStatus.ANALYZED;
  }, [status]);

  const hasFailed = useMemo(() => {
    return failed > 0;
  }, [failed]);

  const isProcessing = useMemo(() => {
    return status === UploadStatus.PROCESSING || status === UploadStatus.DONE;
  }, [status]);

  const progressValue = useMemo(() => {
    return isProcessing ? processed : totalAnalysis;
  }, [isProcessing, processed, totalAnalysis]);

  const progressMaxValue = useMemo(() => {
    return isProcessing ? validated : total;
  }, [isProcessing, validated, total]);

  const progressLabel = useMemo(() => {
    if (analysisFailed) {
      return isProcessing
        ? formatMessage({ id: 'pages.upload.progress.error.processing' })
        : formatMessage({ id: 'pages.upload.progress.error.analysis' });
    }
    if (isSlowUpload === true && progressValue > 0 && progressValue < progressMaxValue) {
      return `${formatMessage(
        { id: 'pages.upload.progress.meter.progress' },
        { progressValue, progressMaxValue }
      )}\n${formatMessage({ id: 'pages.upload.progress.meter.slow' })}`;
    }

    if (progressValue === 0 && progressMaxValue !== 0) {
      return formatMessage({ id: 'pages.upload.progress.meter.starting' });
    }

    return formatMessage(
      { id: 'pages.upload.progress.meter.progress' },
      { progressValue, progressMaxValue }
    );
  }, [progressValue, progressMaxValue, analysisFailed, isSlowUpload, isProcessing]);

  const onDownload = useCallback(async () => {
    SegmentService.paymentsButtonClicked(
      'Download error list',
      'Download Failed Files',
      selected?.vendorId,
      'Credit Management Files Download Errors',
      'CREDIT_MANAGEMENT_FILE_PAGE'
    );
    toast.info({ message: 'files.modal.toasts.info' });

    const response = await downloadFailedFile({
      api: { get },
      params: { fileId, filesConfig },
    });

    if (!response?.success) {
      toast.error({ message: 'errors.generic' });
    }
  }, [fileId]);

  const getData = async () => {
    shouldRetryOnErrors.current = true;

    const slowUpdateId = setTimeout(() => {
      setIsSlowUpload(true);
    }, 60000);

    const shouldRetryTimeout = setTimeout(() => {
      shouldRetryOnErrors.current = false;
    }, 60000);

    const intervalId = setInterval(async () => {
      const response = await getProgress({
        api: { get },
        params: { fileId },
      });

      if (!response?.success && shouldRetryOnErrors.current) return;

      clearTimeout(shouldRetryTimeout);

      if (!response?.success || response?.data?.status === UploadStatus.FAILED) {
        if (response?.data?.status === UploadStatus.FAILED) {
          progress.setter(response?.data);
        }

        clearInterval(intervalId);
        clearTimeout(slowUpdateId);
        interval.current = intervalId;

        setAnalysisFailed(true);
        setIsSlowUpload(false);
        return;
      }

      if (
        response?.data?.status === UploadStatus.ANALYZED ||
        response?.data?.status === UploadStatus.DONE
      ) {
        clearInterval(intervalId);
        clearTimeout(slowUpdateId);
        setAnalysisFailed(false);
        setIsSlowUpload(false);
      }

      setAnalysisFailed(false);
      checkProgressRetries.current = 0;
      progress.setter(response?.data);
      interval.current = intervalId;
    }, 1000);
  };

  useEffect(() => {
    if (fileId) {
      getData();

      return () => {
        if (interval.current) clearTimeout(interval.current);
      };
    }
  }, [fileId, processingStarted.value]);

  if (!fileId && !uploadError.value) {
    return <></>;
  }

  return (
    <ProgressCard border="medium" elevated="small">
      <Heading size="H4">
        {isProcessing
          ? formatMessage({ id: 'pages.upload.progress.processingTitle' })
          : formatMessage({ id: 'pages.upload.progress.title' })}
      </Heading>

      <ProgressContainer>
        {hasAnalyzed ? (
          <React.Fragment>
            <TotalContainer data-testid="total-container">
              <TotalTextContent>
                <Paragraph size="small">
                  {formatMessage({ id: 'pages.upload.progress.finished.pocs.success' })}
                </Paragraph>

                <Heading size="H4">{validated}</Heading>
              </TotalTextContent>

              <Divider orientation="vertical" css={{ height: '100%' }} />

              <TotalTextContent>
                <TooltipContainer>
                  <Paragraph size="small">
                    {formatMessage({ id: 'pages.upload.progress.finished.pocs.error' })}
                  </Paragraph>

                  <Tooltip
                    placement="bottom"
                    text={formatMessage({ id: 'pages.upload.progress.finished.pocs.tooltip' })}
                  >
                    <Info size="tiny" />
                  </Tooltip>
                </TooltipContainer>

                <Heading size="H4" style={{ color: '#C9201D' }}>
                  {failed}
                </Heading>
              </TotalTextContent>
            </TotalContainer>

            {hasFailed && (
              <React.Fragment>
                <Alert
                  type="warning"
                  message={formatMessage({ id: 'pages.upload.progress.finished.alert' })}
                  css={{ width: '100%' }}
                />

                <TextLink
                  hasUnderline={false}
                  colorOption="black"
                  css={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}
                  onClick={onDownload}
                >
                  <Download size="large" style={{ color: '#047AF1' }} />

                  {formatMessage({ id: 'pages.upload.progress.finished.download' })}
                </TextLink>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <ProgressMeterContainer>
            {uploadError.value ? (
              <ProgressMeter
                value={100}
                maxValue={100}
                label={formatMessage({ id: 'pages.upload.progress.meter.error' })}
                hasError
              />
            ) : (
              <ProgressMeter
                css={{ whiteSpace: 'pre-line' }}
                value={progressValue}
                maxValue={progressMaxValue}
                label={progressLabel}
                hasError={analysisFailed}
              />
            )}
            <Paragraph size="small" colortype="secondary">
              {isProcessing
                ? formatMessage({ id: 'pages.upload.progress.meter.descriptionProgressing' })
                : formatMessage({ id: 'pages.upload.progress.meter.description' })}
            </Paragraph>
          </ProgressMeterContainer>
        )}
      </ProgressContainer>
    </ProgressCard>
  );
};
