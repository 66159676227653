import { createContext } from 'react';

type Validation = {
  id: string;
  description: string;
  field: string;
  error_code: string;
  expression: any;
};

export type SourceContextProps = {
  validations: Validation[] | null;
  onboardingRule: boolean;
};

export const SourceContext = createContext<SourceContextProps | null>(null);
