import React from 'react';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { FilesTable } from '../../organisms/Tables';
import { Page } from '../Page/Page';
import { FilesPageContainer } from './styles';

export const FilesPage: React.FC = () => {
  const { permissions } = useGetUserPreferences();

  if (!permissions.has('tables.files')) {
    return <Page.Error type="403" />;
  }

  return (
    <FilesPageContainer data-testid="files-page-container">
      <FilesTable />
    </FilesPageContainer>
  );
};
