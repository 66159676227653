import styled from 'styled-components';

export const DateInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;

  min-height: 92px;
  width: auto;
  row-gap: 8px;

  position: relative;

  input ~ button {
    position: absolute;
    width: 100%;
    justify-content: flex-end;

    svg {
      background: #fff;
      padding-left: 5px;
      width: 30px;
    }
  }

  input {
    pointer-events: none;
  }
`;
