import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Page } from '../Page/Page';
import { Buttons } from './Buttons';
import { UploadProvider } from './Context';
import { Progress } from './Progress';
import { UploadPageContainer } from './styles';
import { Uploader } from './Uploader';

export const UploadPage: React.FC = () => {
  const { permissions } = useGetUserPreferences();

  if (!permissions.has('actions.upload')) {
    return <Page.Error type="403" />;
  }

  return (
    <UploadProvider>
      <UploadPageContainer data-testid="upload-page-container">
        <Uploader />

        <Progress />

        <Buttons />
      </UploadPageContainer>
    </UploadProvider>
  );
};
