import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '../interfaces';
import {
  setSnackbarMessage,
  setSnackbarType,
  setUpdateSummary,
  setUploadAction,
} from '../store/ducks/uiDuck';

export const useUiStore = () => {
  const dispatch = useDispatch();

  const { snackbarMessage, snackbarType, uploadFile, updateSummary } = useSelector(({ ui }) => ui);

  const showSnackbar = ({
    message,
    type = 'info',
    timeout = 10000,
  }: {
    message: string;
    type?: Snackbar;
    timeout?: number;
  }) => {
    dispatch(setSnackbarMessage(message));
    dispatch(setSnackbarType(type));

    setTimeout(() => {
      dispatch(setSnackbarMessage(undefined));
    }, timeout);
  };

  const uploadAction = ({ upload }: { upload: boolean }) => {
    dispatch(setUploadAction(upload));
  };

  const updateSummaryAction = ({ update }: { update: boolean }) => {
    dispatch(setUpdateSummary(update));
  };

  return {
    snackbarMessage,
    snackbarType,
    uploadFile,
    updateSummary,
    showSnackbar,
    uploadAction,
    updateSummaryAction,
  };
};
