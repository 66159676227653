import {
  Alert,
  Divider,
  Heading,
  Paragraph,
  ProgressMeter,
  TextLink,
  Tooltip,
} from '@hexa-ui/components';
import { Download, Info } from '@hexa-ui/icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { useToast } from '../../../../hooks/useToast';
import { UploadStatus } from '../../../../interfaces';
import { downloadFailedFile, getProgress } from '../../../../services';
import { useUpload } from '../Context';
import {
  ProgressCard,
  ProgressContainer,
  ProgressMeterContainer,
  TooltipContainer,
  TotalContainer,
  TotalTextContent,
} from './styles';

export const Progress: React.FC = () => {
  const { formatMessage } = useIntl();

  const { files, uploaded, progress, uploadError } = useUpload();
  const { fileId } = uploaded.value;
  const { status, total, totalAnalysis, processed, failed } = progress.value;
  const [isSlowUpload, setIsSlowUpload] = useState(false);

  const { get } = useRequester();
  const { toast } = useToast();

  const { configs } = useGetUserPreferences();
  const { filesConfig } = configs;

  const [analysisFailed, setAnalysisFailed] = useState(false);

  const interval = useRef<NodeJS.Timeout | null>(null);

  const checkProgressRetries = useRef(0);
  const shouldRetryOnErrors = useRef(true);

  const hasProgress = useMemo(() => {
    return files.value.some((file) => !file.errors.length) && fileId;
  }, [files.value, fileId]);

  const hasAnalyzed = useMemo(() => {
    return status === UploadStatus.ANALYZED;
  }, [status]);

  const hasFailed = useMemo(() => {
    return failed > 0;
  }, [failed]);

  const progressLabel = useMemo(() => {
    if (analysisFailed) {
      return formatMessage({ id: 'pages.upload.progress.error.analysis' });
    }

    if (isSlowUpload === true && totalAnalysis > 0 && totalAnalysis < total) {
      return `${formatMessage(
        { id: 'pages.upload.progress.meter.progress' },
        { totalAnalysis, total }
      )}\n${formatMessage({ id: 'pages.upload.progress.meter.slow' })}`;
    }

    if (totalAnalysis === 0 && total !== 0) {
      return formatMessage({ id: 'pages.upload.progress.meter.starting' });
    }

    return formatMessage({ id: 'pages.upload.progress.meter.progress' }, { totalAnalysis, total });
  }, [totalAnalysis, total, analysisFailed, isSlowUpload]);

  const onDownload = useCallback(async () => {
    toast.info({ message: 'files.modal.toasts.info' });

    const response = await downloadFailedFile({
      api: { get },
      params: { fileId, filesConfig },
    });

    if (!response?.success) {
      toast.error({ message: 'errors.generic' });
    }
  }, [fileId]);

  const getData = async () => {
    shouldRetryOnErrors.current = true;

    const slowUpdateId = setTimeout(() => {
      setIsSlowUpload(true);
    }, 60000);

    const shouldRetryTimeout = setTimeout(() => {
      shouldRetryOnErrors.current = false;
    }, 60000);

    const intervalId = setInterval(async () => {
      const response = await getProgress({
        api: { get },
        params: { fileId },
      });

      if (!response?.success && shouldRetryOnErrors.current) return;

      clearTimeout(shouldRetryTimeout);

      if (!response?.success || response.data?.status === UploadStatus.FAILED) {
        clearInterval(intervalId);
        clearTimeout(slowUpdateId);
        interval.current = intervalId;

        setAnalysisFailed(true);
        setIsSlowUpload(false);
        return;
      }

      if (response.data?.status === UploadStatus.ANALYZED) {
        clearInterval(intervalId);
        clearTimeout(slowUpdateId);

        setIsSlowUpload(false);
      }

      checkProgressRetries.current = 0;
      progress.setter(response.data);
      interval.current = intervalId;
    }, 1000);
  };

  useEffect(() => {
    if (hasProgress) {
      getData();

      return () => {
        if (interval.current) clearTimeout(interval.current);
      };
    }
  }, [hasProgress]);

  if (!hasProgress && !uploadError.value) {
    return <></>;
  }

  return (
    <ProgressCard border="medium" elevated="small">
      <Heading size="H4">{formatMessage({ id: 'pages.upload.progress.title' })}</Heading>

      <ProgressContainer>
        {hasAnalyzed ? (
          <React.Fragment>
            <TotalContainer data-testid="total-container">
              <TotalTextContent>
                <Paragraph size="small">
                  {formatMessage({ id: 'pages.upload.progress.finished.pocs.success' })}
                </Paragraph>

                <Heading size="H4">{processed}</Heading>
              </TotalTextContent>

              <Divider orientation="vertical" css={{ height: '100%' }} />

              <TotalTextContent>
                <TooltipContainer>
                  <Paragraph size="small">
                    {formatMessage({ id: 'pages.upload.progress.finished.pocs.error' })}
                  </Paragraph>

                  <Tooltip
                    placement="bottom"
                    text={formatMessage({ id: 'pages.upload.progress.finished.pocs.tooltip' })}
                  >
                    <Info size="tiny" />
                  </Tooltip>
                </TooltipContainer>

                <Heading size="H4" style={{ color: '#C9201D' }}>
                  {failed}
                </Heading>
              </TotalTextContent>
            </TotalContainer>

            {hasFailed && (
              <React.Fragment>
                <Alert
                  type="warning"
                  message={formatMessage({ id: 'pages.upload.progress.finished.alert' })}
                  css={{ width: '100%' }}
                />

                <TextLink
                  hasUnderline={false}
                  colorOption="black"
                  css={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}
                  onClick={onDownload}
                >
                  <Download size="large" style={{ color: '#047AF1' }} />

                  {formatMessage({ id: 'pages.upload.progress.finished.download' })}
                </TextLink>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <ProgressMeterContainer>
            {uploadError.value ? (
              <ProgressMeter
                value={100}
                maxValue={100}
                label={formatMessage({ id: 'pages.upload.progress.meter.error' })}
                hasError
              />
            ) : (
              <ProgressMeter
                css={{ whiteSpace: 'pre-line' }}
                value={totalAnalysis}
                maxValue={total}
                label={progressLabel}
                hasError={analysisFailed}
              />
            )}
            <Paragraph size="small" colortype="secondary">
              {formatMessage({ id: 'pages.upload.progress.meter.description' })}
            </Paragraph>
          </ProgressMeterContainer>
        )}
      </ProgressContainer>
    </ProgressCard>
  );
};
