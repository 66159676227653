import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserPreferences } from '../../hooks/useGetUserPreferences';
import {
  AppState,
  CustomViewType,
  HasFn,
  MappedView,
  Tables,
  UseViews,
  View,
} from '../../interfaces';
import {
  createView as creatterView,
  deleteView as deleterView,
  editView as editorView,
  setActive as setterActive,
} from '../ducks/customViewsDuck';

export const useViews = (table?: Tables): UseViews => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { user, configs } = useGetUserPreferences();
  const { tables } = configs;

  const customSelector = useSelector((state: AppState) => state.customViews);

  const viewActive = customSelector[user.id]?.[table]?.active || [0, CustomViewType.DEFAULT];

  const standard: MappedView[] = useMemo(() => {
    return (
      tables[table]?.views?.map((view: View) => ({
        name: formatMessage({ id: `tables.views.card.standard.${view.name}` }),
        tag: view.tag,
        columns: view.columns,
        type: CustomViewType.DEFAULT,
      })) ?? []
    );
  }, [tables, table]);

  const custom: MappedView[] = useMemo(() => {
    return (
      customSelector[user.id]?.[table]?.view?.map((view: View) => ({
        name: view.name,
        tag: view.tag,
        columns: view.columns,
        type: CustomViewType.CUSTOM,
      })) ?? []
    );
  }, [customSelector, table, user.id]);

  const all = useMemo(() => standard.concat(custom), [standard, custom]);

  const active = useMemo(() => {
    const [index, type] = viewActive;

    return type === CustomViewType.CUSTOM ? custom[index] : standard[index];
  }, [viewActive, standard, custom, user.id, table, setterActive, dispatch]);

  const activetags = useMemo(() => {
    if (!customSelector[user.id]) return [];

    return Object.entries(customSelector[user.id]).reduce((acc, [key, value]) => {
      const [index, type] = value?.active ?? [];
      const view =
        type === CustomViewType.CUSTOM ? value.view?.[index] : tables[key]?.views?.[index];

      acc.push({ table: key, tag: view?.tag });
      return acc;
    }, []);
  }, [customSelector, user.id, tables]);

  const has = (tkey: Tables): HasFn => ({
    structure: Boolean(customSelector[user.id]?.[tkey]),
    type: customSelector[user.id]?.[tkey]?.active?.[1] as CustomViewType,
  });

  const create = (view: View) => dispatch(creatterView({ userId: user.id, table, view }));

  const edit = (index: number, view: View) =>
    dispatch(editorView({ userId: user.id, table, index, view }));

  const remove = (index: number) => dispatch(deleterView({ userId: user.id, table, index }));

  const setter = (active: [number, string], tkey?: Tables) =>
    dispatch(setterActive({ userId: user.id, table: tkey ?? table, active }));

  return {
    standard,
    custom,
    all,
    active,
    activetags,
    views: { has, create, edit, remove, active: setter },
  };
};
