import { GenericObject } from '../interfaces';
import enUS from './languages/en-US';
import esAL from './languages/es-AL';
import ptBR from './languages/pt-BR';

const flattenObject = (obj: GenericObject): GenericObject => {
  const flatObject = {};

  for (const property in obj) {
    if (typeof obj[property] === 'object' && obj[property] !== null) {
      const flattenedProperty = flattenObject(obj[property]);

      for (const subProperty in flattenedProperty) {
        flatObject[`${property}.${subProperty}`] = flattenedProperty[subProperty];
      }
    } else {
      flatObject[property] = obj[property];
    }
  }

  return flatObject;
};

export const messages = {
  'en-US': flattenObject(enUS),
  'pt-BR': flattenObject(ptBR),
  'es-419': flattenObject(esAL),
};
