import { UploadableFile } from '@hexa-ui/components';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { GetSet, ProgressProps, UploadedProps } from '../../../../interfaces';

interface UploadContext {
  files: GetSet<UploadableFile[]>;
  description: GetSet<string>;
  uploaded: GetSet<UploadedProps>;
  progress: GetSet<ProgressProps>;
  uploadError: GetSet<boolean>;
  uploadStarted: GetSet<boolean>;
  onClear: () => void;
}

const UploadContext = createContext<UploadContext | null>(null);

export const UploadProvider: React.FC = ({ children }) => {
  const [files, setFiles] = useState<UploadableFile[] | null>([]);
  const [description, setDescription] = useState<string>('');
  const [uploaded, setUploaded] = useState<UploadedProps>({ fileId: '', fileName: '' });
  const [progress, setProgress] = useState<ProgressProps>({
    status: '',
    total: 100,
    totalAnalysis: 0,
    processed: 0,
    failed: 0,
  });
  const [uploadError, setUploadError] = useState(false);
  const [uploadStarted, setUploadStarted] = useState(false);

  const onClear = useCallback(() => {
    setFiles([]);
    setDescription('');
    setUploaded({ fileId: '', fileName: '' });
    setProgress({ status: '', total: 100, totalAnalysis: 0, processed: 0, failed: 0 });
    setUploadError(false);
    setUploadStarted(false);
  }, []);

  const value = useMemo(() => {
    return {
      files: { value: files, setter: setFiles },
      description: { value: description, setter: setDescription },
      uploaded: { value: uploaded, setter: setUploaded },
      progress: { value: progress, setter: setProgress },
      uploadError: { value: uploadError, setter: setUploadError },
      uploadStarted: { value: uploadStarted, setter: setUploadStarted },
      onClear,
    };
  }, [files, description, uploaded, progress, uploadError, uploadStarted]);

  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
};

export const useUpload = () => useContext(UploadContext);
