import { GetCreditHistoryProps, ServicesResponse } from '../../interfaces';

const service = {
  getCreditHistory: async ({
    api,
    params,
  }: GetCreditHistoryProps): Promise<ServicesResponse | null> => {
    try {
      const { beesAccountId, pagination } = params;

      const response = await api.get({
        path: `/v1/credit-audit/assessments?beesAccountId=${beesAccountId}&page=${pagination.page}`,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
};

export const { getCreditHistory } = service;
