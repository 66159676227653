import { Input as HexaInput } from '@hexa-ui/components';
import { useCallback } from 'react';
import { useFormatting } from '../../../../hooks/useFormatting';
import { TextAttributes } from './Text.types';

export const TextInput: React.FC<TextAttributes> = ({
  id,
  size,
  format,
  value,
  width = '100%',
  height = 'auto',
  label = '',
  placeholder = '',
  maxLength,
  counter = false,
  prefix = '',
  suffix = '',
  hint = '',
  error = { has: false, message: '' },
  min,
  max,
  onChange,
  style,
}) => {
  const { formatting } = useFormatting();

  const onValueChange = useCallback(
    (text: string) => {
      const { value, formatted } = formatting({ type: format, value: text });

      onChange({ id, value, formatted });
    },
    [id]
  );

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];

    if (format === 'text') return;

    if (format === 'percentage' && !/[^0-9,]/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();

      return;
    }

    if (!/\D/g.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <HexaInput
      id={id}
      size={size}
      width={width}
      height={height}
      label={label}
      placeholder={placeholder}
      prefix={prefix}
      sufix={suffix}
      hint={hint}
      hasError={error.has}
      errorText={error.message}
      min={min}
      max={max}
      maxLength={maxLength}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onValueChange(e.target.value)}
      onKeyUp={onKeyUp}
      characterCounter={counter}
      style={style}
    />
  );
};
