import { useIntl } from 'react-intl';
import { FormattingProps, MappedFormatting, UseFormatting } from '../interfaces';
import { format } from '../utils/formatting';
import { useGetUserPreferences } from './useGetUserPreferences';

export const useFormatting = (): UseFormatting => {
  const { formatMessage } = useIntl();
  const { datetime, currency, language } = useGetUserPreferences();

  const formatting = ({ type, value, config }: FormattingProps): MappedFormatting => {
    const formatMapping = { currency: currency.format, datetime: datetime.format };

    return (
      format[type]?.({
        value,
        type,
        config,
        locale: language.locale,
        format: formatMapping[type] ?? formatMessage,
      }) ?? { value, formatted: { simple: value, completed: value } }
    );
  };

  return { formatting };
};
