import { List } from '@hexa-ui/icons';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useViews } from '../../../../store/hooks/customViewsHook';
import { CollapseButton } from '../../CollapseButton/CollapseButton';
import { ViewList } from './List/List';
import { ViewSelectorProps } from './ViewSelector.types';

export const ViewSelector: React.FC<ViewSelectorProps> = React.memo(({ table }) => {
  const { formatMessage } = useIntl();

  const { active } = useViews(table);

  const [open, setOpen] = useState<boolean>(false);

  const onClose = () => setOpen(false);

  return (
    <CollapseButton
      open={{ value: open, setter: setOpen }}
      label={formatMessage({ id: 'tables.views.selector.label' }, { name: active.name })}
      icon={List}
      style={{ width: 400 }}
    >
      <ViewList table={table} onClose={onClose} />
    </CollapseButton>
  );
});
