import { useIntl } from 'react-intl';
import { AssessmentsItem } from '../../../../../../interfaces';
import { OfferCredit } from '../../../../../molecules/OfferCredit/OfferCredit';
import { IconButton, Tooltip } from '@hexa-ui/components';
import { Invoice } from '@hexa-ui/icons';

interface ActionsNotRecommendedProps {
  selected: AssessmentsItem;
  callbackFn: () => Promise<void>;
}

export const ActionsNotRecommended: React.FC<ActionsNotRecommendedProps> = ({
  selected,
  callbackFn,
}) => {
  const { formatMessage } = useIntl();

  return (
    <OfferCredit
      data={selected}
      trigger={
        <IconButton
          id="offer-credit-button"
          variant="tertiary"
          size="small"
          icon={() => (
            <Tooltip placement="bottom" text={formatMessage({ id: 'offerCredit.tooltip' })}>
              <Invoice size="large" style={{ display: 'flex' }} />
            </Tooltip>
          )}
          onClick={(event) => event.stopPropagation()}
        />
      }
      callbackFn={callbackFn}
    />
  );
};
