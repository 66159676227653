import { Divider, SearchField } from '@hexa-ui/components';
import React from 'react';
import { keys } from '../../../../constants';
import { FeatureToggle } from '../../FeatureToggle/FeatureToggle';
import { Filter } from '../Filter/Filter';
import { ViewSelector } from '../Views/ViewSelector';
import { TableExtraProps } from './TableExtra.types';
import { ExtraContainer, LeftContainer, TopContainer } from './styles';

export const TableExtra: React.FC<TableExtraProps> = ({
  search,
  filters,
  views,
  selectable,
  toolbarExtra: Component,
}) => {
  return (
    <ExtraContainer data-testid="extra-container">
      <Filter.Context>
        <TopContainer $hassearch={search.has} $hasfilter={true} $hasextra={!!Component}>
          <LeftContainer>
            {views.has && !selectable.hasValue && (
              <FeatureToggle
                type="some"
                keys={[keys.cm_has_not_recommended, keys.cm_has_custom_views]}
              >
                <ViewSelector table={views.table} />

                <Divider orientation="vertical" css={{ height: '40px !important' }} />
              </FeatureToggle>
            )}

            {filters.has && !selectable.hasValue && (
              <Filter.Root
                filters={filters.config}
                onFilterFn={filters.onFilter}
                onClearFn={filters.onClear}
              />
            )}

            {search.has && !selectable.hasValue && (
              <SearchField.Root
                width={search.width ?? 400}
                value={search.value}
                placeholder={search.placeholder}
                onClear={() => search.onChange('')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  search.onChange(event.target.value)
                }
              />
            )}
          </LeftContainer>

          {Component &&
            ((selectable.has && selectable.hasValue) ||
              (!selectable.has && !selectable.hasValue)) && <Component />}
        </TopContainer>

        {filters.has && filters.chips && !selectable.hasValue && <Filter.Chips />}
      </Filter.Context>
    </ExtraContainer>
  );
};
