import { GenericObject } from '../interfaces';

export const getURLParams = (obj: GenericObject): string => {
  const params = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (!value) return;

    if (typeof value === 'string') {
      params.append(key, value);
    }

    if (Array.isArray(value) && value.length) {
      params.append(key, value.join(','));
    }

    if (typeof value === 'object' && Object.keys(value).length) {
      params.append(key, Object.values(value).join(','));
    }
  });

  return params.toString();
};
