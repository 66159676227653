import { OptimizelyFeature as Feature } from '@optimizely/react-sdk';
import { useCallback } from 'react';
import { Page } from '../../../components/pages/Page/Page';
import { ProviderProps } from '../../../interfaces';
import { useSelected } from '../../../store/hooks/selectedHook';
import { UserProvider } from './User/UserProvider';

export const OptimizelyFeature: React.FC<ProviderProps> = ({ children }) => {
  const { selected } = useSelected();

  const getUserProvider = useCallback(
    (isEnabled, variables) => {
      if (!isEnabled) {
        return <Page.Error type="404" />;
      }

      const { defaultConfig, countryConfig, userConfig } = variables;
      const { allowedCountries } = defaultConfig;

      if (selected.country && !allowedCountries.includes(selected.country.toLowerCase())) {
        return <Page.Error type="404" />;
      }

      return (
        <UserProvider variables={{ defaultConfig, countryConfig, userConfig }}>
          {children}
        </UserProvider>
      );
    },
    [children, selected.country, selected.vendorId]
  );

  return (
    <Feature feature={'cm_configs'} key={`feature-${selected.country}`}>
      {getUserProvider}
    </Feature>
  );
};
