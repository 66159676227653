import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetTables } from '../../../../hooks/useGetTables';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { GenericResponse, Tables } from '../../../../interfaces';
import { getFiles } from '../../../../services';
import { Table } from '../../../atoms/Table/Table';
import { FileButtons } from '../../../molecules/FileButtons/FileButtons';

export const FilesTable: React.FC = () => {
  const { formatMessage } = useIntl();
  const { get } = useRequester();

  const { columns } = useGetTables(Tables.FILES);
  const { configs } = useGetUserPreferences();
  const { filesConfig } = configs;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [files, setFiles] = useState({ value: [], filtered: [] });
  const [search, setSearch] = useState<string>('');

  const onSearch = async (value: string) => {
    setSearch(value);

    setFiles((prev) => ({
      ...prev,
      filtered: prev.value.filter((item) =>
        item.fileName.toLowerCase().includes(value.toLowerCase())
      ),
    }));
  };

  const getData = async () => {
    setIsLoading(true);
    const response = await getFiles({ api: { get } });

    if (!response?.success) {
      setFiles({ value: [], filtered: [] });
      setIsLoading(false);
      return;
    }

    const fileHistory = response.data?.map((item: GenericResponse) => ({
      status: item.status,
      actions: { fileId: item.id, filesConfig },
      fileId: item.id,
      fileName: item.reference_name,
      fileDescription: item.description,
      total: String(item.total_count),
      processed: String(item.processed_count),
      uploadedBy: item.uploaded_by,
      uploadedAt: { value: item.updated_at, type: 'long-time' },
    }));

    setFiles({ value: fileHistory, filtered: fileHistory });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Table
      loading={isLoading}
      columns={columns}
      data={files.filtered}
      toolbarExtra={FileButtons}
      search={{
        has: true,
        width: 320,
        placeholder: formatMessage({ id: 'tables.files.search' }),
        value: search,
        onChange: onSearch,
      }}
      messages={{
        loading: formatMessage({ id: 'tables.loading' }),
        empty: { message: formatMessage({ id: 'tables.files.empty' }) },
      }}
    />
  );
};
