import { IconButton, Tooltip } from '@hexa-ui/components';
import { Edit2 } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { AssessmentsItem } from '../../../../../../interfaces';
import { ActionsRecommendedContainer } from './styles';
import { ApprovalButtons } from '../../../../../molecules/ApprovalButtons/ApprovalButtons';
import { EditRecommendation } from '../../../../../molecules/EditRecommendation/EditRecommendation';

interface ActionsRecommendedProps {
  selected: AssessmentsItem;
  callbackFn: () => Promise<void>;
}

export const ActionsRecommended: React.FC<ActionsRecommendedProps> = ({ selected, callbackFn }) => {
  const { formatMessage } = useIntl();

  return (
    <ActionsRecommendedContainer data-testid="actions-recommended-container">
      <ApprovalButtons
        mode="single"
        type="icon-button"
        button={{ size: 'small' }}
        selected={selected}
        callbackFn={callbackFn}
      />

      <EditRecommendation
        data={selected}
        trigger={
          <IconButton
            id="edit-button"
            variant="tertiary"
            size="small"
            icon={() => (
              <Tooltip
                placement="bottom"
                text={formatMessage({ id: 'editRecommendation.buttons.tooltip' })}
              >
                <Edit2 size="medium" />
              </Tooltip>
            )}
            onClick={(event) => event.stopPropagation()}
          />
        }
        callbackFn={callbackFn}
      />
    </ActionsRecommendedContainer>
  );
};
