import styled from 'styled-components';

export const CustomErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 100%;

  row-gap: 16px;
`;
