import { PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Page } from '../../components/pages/Page/Page';
import { countries, headerConfig } from '../../constants';
import { HeaderContext } from '../../contexts';
import { HeaderState, SetPageHeaderProps } from '../../contexts/Header/HeaderContext.types';
import { useApplication } from '../../hooks/useApplication';
import { useQuery } from '../../hooks/useQuery';
import { ProviderProps } from '../../interfaces';
import { useSelected } from '../../store/hooks/selectedHook';

export const HeaderProvider: React.FC<ProviderProps> = ({ children }) => {
  const { formatMessage } = useIntl();

  const { data, name } = useApplication();
  const { selected, setSelected, setUserName } = useSelected();

  const { get } = useQuery();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isFirstRender = useRef(true);

  const reducer = useCallback(
    (state: HeaderState, { payload }: PayloadAction<Partial<HeaderState>>) => {
      if (payload?.country || payload?.vendorId) {
        setSelected({
          country: payload?.country ?? selected.country,
          vendorId: payload?.vendorId ?? selected.vendorId,
        });
      }

      return { ...state, ...payload };
    },
    []
  );

  const [state, dispatch] = useReducer(reducer, {
    title: { value: '', translate: true },
    country: '',
    vendorId: '',
    breadcrumbs: { homePath: '', items: [] },
  });

  const countryOptions = useMemo(() => {
    return data.supportedCountries?.reduce(
      (acc, country) => {
        if (selected.country && selected.country === country) {
          acc.value = country;
        }

        const name = countries.includes(country)
          ? formatMessage({ id: `countries.${country}` })
          : country;

        acc.countries.push({ id: country, name });
        return acc;
      },
      { value: '', countries: [] }
    );
  }, [data.supportedCountries, selected]);

  const vendorOptions = useMemo(() => {
    return data.vendors?.reduce(
      (acc, vendor) => {
        if (selected.country && selected.vendorId === vendor.id) {
          acc.value = vendor.id;
        }

        acc.vendors.push({ id: vendor.id, name: vendor.displayName, country: vendor.country });
        return acc;
      },
      { value: '', vendors: [] }
    );
  }, [data.vendors, selected]);

  const setAppHeader = (payload: Partial<HeaderState>) => {
    dispatch({ type: 'SETTER', payload });
  };

  const setPageHeader = ({ page, attributes }: SetPageHeaderProps) => {
    const { pageTitle, breadcrumbConfig } = headerConfig[page];
    const { homePath, items } = breadcrumbConfig;

    dispatch({
      type: 'SETTER',
      payload: {
        title: { value: pageTitle || attributes?.pageTitle, translate: Boolean(pageTitle) },
        breadcrumbs: { homePath, items },
      },
    });
  };

  const setDefaultSelected = useCallback(() => {
    setIsLoading(true);
    setUserName(name.full);
    const config = data.vendors?.find((vendor) => vendor);
    const hasRedux = selected.country && selected.vendorId;

    const { pageTitle, breadcrumbConfig } = headerConfig['empty'];
    const { homePath, items } = breadcrumbConfig;

    const country = hasRedux ? selected.country : config?.country ?? '';
    const vendorId = hasRedux ? selected.vendorId : config?.id ?? '';

    setAppHeader({
      title: { value: pageTitle, translate: Boolean(pageTitle) },
      country: get('country')?.toUpperCase() ?? country,
      vendorId: get('vendorId')?.toLowerCase() ?? vendorId,
      breadcrumbs: { homePath, items },
    });

    setIsLoading(false);
  }, [data.vendors, selected, setSelected, get]);

  const value = useMemo(
    () => ({
      setAppHeader,
      setPageHeader,
      title: state.title,
      country: state.country,
      vendorId: state.vendorId,
      breadcrumbs: state.breadcrumbs,
      countryOptions: countryOptions,
      vendorOptions: vendorOptions,
    }),
    [state, vendorOptions, countryOptions]
  );

  useEffect(() => {
    if (isFirstRender.current) {
      setDefaultSelected();

      isFirstRender.current = false;
    }
  }, []);

  if (isLoading) {
    return <Page.Loading />;
  }

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};
