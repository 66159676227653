import { useMemo } from 'react';
import { Page } from '../../components/pages/Page/Page';
import { useHeader } from '../../hooks/useHeader';
import { ProviderProps } from '../../interfaces';
import { useSelected } from '../../store/hooks/selectedHook';

export const OptionsProvider: React.FC<ProviderProps> = ({ children }) => {
  const { countryOptions, vendorOptions } = useHeader();
  const { selected } = useSelected();

  const hasPermission = useMemo(() => {
    return (
      countryOptions.countries.some((country) => country.id === selected.country) &&
      vendorOptions.vendors.some((vendor) => vendor.id === selected.vendorId)
    );
  }, [selected.country, selected.vendorId]);

  if (
    !countryOptions.countries.length ||
    !vendorOptions.vendors.length ||
    !selected.country ||
    !selected.vendorId ||
    !hasPermission
  ) {
    return <Page.Error type="403" />;
  }

  return children;
};
