import { GetPocDetailsProps, ServicesResponse } from '../../interfaces';

const service = {
  getPocDetails: async ({ api, params }: GetPocDetailsProps): Promise<ServicesResponse | null> => {
    try {
      const { assessmentId, beesAccountId } = params;

      const response = await api.get({
        path: `/v1/poc-details/${assessmentId}?beesAccountId=${beesAccountId}`,
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
};

export const { getPocDetails } = service;
