import { Label } from './styles';

interface StatusColumnProps {
  color: string;
  text: string;
}

export const StatusColumn: React.FC<StatusColumnProps> = ({ color, text }) => {
  return <Label label={text} $color={color} />;
};
