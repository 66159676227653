import { Button, IconButton, LoadingDots, Tooltip } from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { Status } from '../../../../interfaces';
import { ApprovalProps } from '../ApprovalButtons.types';

export const Deny: React.FC<ApprovalProps> = ({ mode, type, button, isLoading, onApproval }) => {
  const { formatMessage } = useIntl();

  const { permissions } = useGetUserPreferences();

  const onDeny = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>, status: Status) => {
      event.stopPropagation();

      await onApproval(status);
    },
    [onApproval]
  );

  if (!permissions.has('actions.deny')) {
    return null;
  }

  return type === 'button' ? (
    <Button
      id="deny-button"
      data-testid="deny-button"
      leading
      icon={() => (isLoading ? <LoadingDots dataTestId="loading-dots" /> : <X size="medium" />)}
      size={button.size}
      variant="secondary"
      disabled={isLoading}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => onDeny(event, Status.DENIED)}
    >
      {formatMessage({ id: `approval.buttons.DENIED.${mode}` })}
    </Button>
  ) : (
    <IconButton
      id="deny-icon-button"
      data-testid="deny-icon-button"
      icon={() => (
        <Tooltip placement="bottom" text={formatMessage({ id: 'approval.buttons.DENIED.tooltip' })}>
          <X size="medium" />
        </Tooltip>
      )}
      size={button.size}
      variant="tertiary"
      loading={isLoading}
      disabled={isLoading}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => onDeny(event, Status.DENIED)}
    />
  );
};
