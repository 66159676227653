import { FilterState } from '../../components/atoms/Table/Filter/Context/FilterContext.types';
import { getURLParams } from '../url';

export const formatDate = (date: string | Date = new Date()): string => {
  return new Date(date).toISOString().split('T')[0];
};

const getFilters = (filters: FilterState) => {
  const filterMapping: { [key: string]: string } = {
    creditLimit: 'limit.model',
    term: 'term.days',
    fee: 'term.fee',
    updatedAt: 'updatedAt',
    recommendation: 'recommendation',
  };

  const mapped = Object.entries(filters).reduce(
    (acc, [key, { value, formatted }]) => {
      const [name, id, type] = key.split(':');
      const keyParam = filterMapping[name];

      if (type === 'range-value') {
        acc.range[keyParam] = { ...acc.range[keyParam], [id]: value };
      }

      if (type === 'range-date') {
        acc.date[keyParam] = { ...acc.date[keyParam], [id]: formatDate(value[0]) };
      }

      if (type === 'checkbox' && name === 'recommendation') {
        acc.tag.push(formatted.simple);
      }

      if (type === 'checkbox' && name === 'status') {
        acc.status.push(formatted.simple);
      }

      return acc;
    },
    { range: {}, date: {}, tag: [], status: [] }
  );

  const serialized = Object.entries(mapped).reduce((acc, [key, value]) => {
    if ((key === 'tag' || key === 'status') && Array(value).length) {
      acc[key] = Array(value).join(',');

      return acc;
    }

    acc[key] = Object.entries(value).reduce((typeAcc, [typeKey, typeValue]) => {
      if (key === 'range') {
        const { min, max } = typeValue;

        typeAcc[typeKey] = `${typeKey}:${min ?? '0'}:${max ?? '999999999'}`;
        return typeAcc;
      }

      const { startDate, endDate } = typeValue;

      typeAcc[typeKey] = `${typeKey}:${startDate ?? formatDate('1969-12-31')}:${
        endDate ?? formatDate()
      }`;

      return typeAcc;
    }, {});

    return acc;
  }, {});

  return getURLParams(serialized);
};

const getChips = (filters: FilterState) => {
  return Object.entries(filters).reduce((acc, [key, { formatted }]) => {
    const [name, id, type] = key.split(':');

    if (['range-value', 'range-date'].includes(type)) {
      if (!acc[name]) {
        acc[name] = {
          filtername: `tables.filters.inputs.${name}.name`,
          label: `tables.filters.chips.${id}`,
          value: { [id]: formatted.completed },
        };

        return acc;
      }

      acc[name].label = 'tables.filters.chips.to';
      acc[name].value[id] = formatted.completed;

      return acc;
    }

    if (['checkbox'].includes(type)) {
      if (!acc[name]) {
        acc[name] = {
          filtername: `tables.filters.inputs.${name}.name`,
          label: id,
          value: formatted.completed,
        };

        return acc;
      }

      acc[name].value = `${acc[name].value}, ${formatted.completed}`;
    }

    return acc;
  }, {});
};

export const serializer = (state: FilterState) => {
  const filters = getFilters(state);
  const chips = getChips(state);

  return { filters, chips };
};
