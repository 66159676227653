import { Card } from '@hexa-ui/components';
import styled from 'styled-components';

export const TableContainer = styled(Card)<{ $hasselectable: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  div[data-testid='table-toolbar'] {
    height: auto !important;
    min-height: 72px !important;
    padding: 0 !important;

    > :nth-child(1) {
      width: ${({ $hasselectable }) => ($hasselectable ? 'auto' : '100% !important')};
      padding-left: ${({ $hasselectable }) => ($hasselectable ? '16px' : '0')};
    }

    ~ div > table > thead > tr > th > div {
      justify-content: flex-start;
    }
  }
`;
