import { FilterChips } from './Chips/FilterChips';
import { FilterProvider } from './Context/FilterContext';
import { FilterComponentProps } from './Filter.types';
import { FilterRoot } from './Root/FilterRoot';

export const Context: React.FC = ({ children }) => {
  return <FilterProvider>{children}</FilterProvider>;
};

export const Filter: FilterComponentProps = { Root: FilterRoot, Chips: FilterChips, Context };
