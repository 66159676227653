import { Card as HexaCard, ListItem as ListItemHexa, TextButton } from '@hexa-ui/components';
import styled, { css } from 'styled-components';

export const Card = styled(HexaCard)`
  width: 100%;
  position: absolute;
  z-index: 20;

  padding: 16px 0 16px 0;

  @media screen and (max-height: 720px) {
    height: 200px;
    overflow-y: scroll;
  }
`;

export const ViewsContainer = styled.div<{ $scroll?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  row-gap: 12px;

  > h4 {
    margin-left: 16px;
  }

  ${({ $scroll }) =>
    $scroll &&
    css`
      @media screen and (min-height: 720px) {
        overflow-y: auto;
        max-height: calc(100vh - 700px);
      }
    `}
`;

export const List = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;
`;

export const ListItem = styled(ListItemHexa)<{ $active: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;

  width: 100%;

  [class*='-selectable'] {
    width: 100%;
    padding: 0 8px;
  }

  ${({ $active }) => {
    if ($active) {
      return css`
        background: #f6f2ff;
        font-weight: 600;
        font-size: 16px;
      `;
    }
  }}
`;

export const IconButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
`;

export const CreateButton = styled(TextButton)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  column-gap: 8px;

  svg {
    color: #0f7ffa !important;
    fill: #0f7ffa !important;
  }
`;
