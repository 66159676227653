import { Card } from '@hexa-ui/components';
import styled from 'styled-components';

export const ProgressCard = styled(Card)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 60%;
  padding: 24px;
  gap: 24px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  row-gap: 24px;
`;

export const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  width: 100%;
  column-gap: 12px;
`;

export const TotalTextContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 40%;
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  width: 100%;
  column-gap: 6px;
`;

export const ProgressMeterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  row-gap: 12px;
`;
