import {
  paymentsButtonClicked,
  PaymentsButtonClicked,
  paymentsPageViewed,
  PaymentsPageViewed,
  ValueStream,
} from '../../typewriter/segment';

class SegmentService {
  paymentsButtonClicked(
    buttonLabel: string,
    buttonName: string,
    vendorId: string,
    screen_name: string,
    referrer: string,
    storeId: string | null = null
  ) {
    const props: PaymentsButtonClicked = {
      vendorId,
      button_label: buttonLabel,
      button_name: buttonName,
      screen_name,
      referrer,
      store_id: storeId,
      value_stream: ValueStream.Payments,
    };

    paymentsButtonClicked(props);
  }

  paymentsPageViewed(
    vendorId: string,
    screen_name: string,
    referrer: string,
    storeId: string | null = null
  ) {
    const props: PaymentsPageViewed = {
      vendorId,
      screen_name,
      referrer,
      store_id: storeId,
      value_stream: ValueStream.Payments,
    };

    paymentsPageViewed(props);
  }

  paymentsCreditManagementFileDownloadMode(
    buttonLabel: string,
    buttonName: string,
    vendorId: string,
    screen_name: string,
    referrer: string,
    statusesIncluded: string,
    storeId: string | null = null
  ) {
    const props: PaymentsButtonClicked = {
      button_label: buttonLabel,
      button_name: buttonName,
      vendorId,
      screen_name,
      referrer,
      store_id: storeId,
      value_stream: ValueStream.Payments,
      statuses_included: statusesIncluded,
    };

    paymentsButtonClicked(props);
  }

  paymentsCreditManagementEditLineActiveConditionChange(
    buttonLabel: string,
    buttonName: string,
    vendorId: string,
    screen_name: string,
    referrer: string,
    changedField: { field: string; value: string },
    storeId: string | null = null
  ) {
    const props: PaymentsButtonClicked = {
      button_label: buttonLabel,
      button_name: buttonName,
      vendorId,
      screen_name,
      referrer,
      store_id: storeId,
      value_stream: ValueStream.Payments,
      [changedField?.field]: changedField?.value,
    };

    paymentsButtonClicked(props);
  }

  paymentsCreditManagementEditLineSuggestionConditionChange(
    buttonLabel: string,
    buttonName: string,
    vendorId: string,
    screen_name: string,
    referrer: string,
    changedField: { field: string; value: string }
  ) {
    const props: PaymentsButtonClicked = {
      button_label: buttonLabel,
      button_name: buttonName,
      vendorId,
      screen_name,
      referrer,
      store_id: null,
      value_stream: ValueStream.Payments,
      [changedField?.field]: changedField?.value,
    };

    paymentsButtonClicked(props);
  }
}

export default new SegmentService();
