import Axios from 'axios';
import { RequestParams } from '../interfaces';
import { useApplication } from './useApplication';
import { useGetUserPreferences } from './useGetUserPreferences';

export const useRequester = () => {
  const { enhancedAxios } = useApplication();

  const { selected } = useGetUserPreferences();

  const headers: Record<string, any> = {
    noorders: localStorage.getItem('noOrders') || '',
    country: selected.country,
    vendorId: selected.vendorId,
  };

  const requestProvider = Axios.create({
    baseURL: process.env.REACT_APP_BFF_URL,
  });

  enhancedAxios(requestProvider, { headers: [headers] });

  const get = async ({ path, config }: RequestParams<'get'>): Promise<any> => {
    return requestProvider.get(path, config).catch((error) => ({ hasError: true, ...error }));
  };

  const post = async ({ path, body, config }: RequestParams<'post'>): Promise<any> => {
    return requestProvider
      .post(path, body, config)
      .catch((error) => ({ hasError: true, ...error }));
  };

  const patch = async ({ path, body, config }: RequestParams<'patch'>): Promise<any> => {
    return requestProvider
      .patch(path, body, config)
      .catch((error) => ({ hasError: true, ...error }));
  };

  const remove = async ({ path, config }: RequestParams<'delete'>): Promise<any> => {
    return requestProvider.delete(path, config).catch((error) => ({ hasError: true, ...error }));
  };

  const downloadFromBackend = async ({ config }: RequestParams<'get'>): Promise<any> => {
    return requestProvider
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/credit-assessment-service/v1/assessments/vendor/${selected.vendorId}/country/${selected.country}`,
        {
          ...config,
        }
      )
      .catch((error) => ({ hasError: true, ...error }));
  };

  const uploadToBackend = async ({ body, config }: RequestParams<'post'>): Promise<any> => {
    return requestProvider
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/credit-assessment-service/v1/assessments/vendor/${selected.vendorId}/country/${selected.country}/update`,
        body,
        {
          ...config,
        }
      )
      .catch((error) => ({ hasError: true, ...error }));
  };

  const downloadFileFromBackend = async ({ config }: RequestParams<'get'>): Promise<any> => {
    return requestProvider
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/credit-assessment-service/v1/assessment-processes/${config.params.fileId}/download`,
        {
          ...config,
        }
      )
      .catch((error) => ({ hasError: true, ...error }));
  };

  return {
    get,
    post,
    patch,
    remove,
    downloadFromBackend,
    uploadToBackend,
    downloadFileFromBackend,
  };
};
