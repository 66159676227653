import {
  FileMultiUploader,
  Heading,
  Paragraph,
  TextArea,
  UploadableFile,
} from '@hexa-ui/components';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useUpload } from '../Context';
import { DescriptionTextContainer, TextAreaContainer, TextContainer, UploaderCard } from './styles';

export const Uploader: React.FC = () => {
  const { formatMessage } = useIntl();

  const { files, description, uploaded, onClear } = useUpload();
  const { fileId } = uploaded.value;

  const { configs } = useGetUserPreferences();
  const { filesConfig } = configs;

  const hasFile = useMemo(() => {
    return files.value.some((file) => !file.errors.length);
  }, [files]);

  const onClean = useCallback(() => {
    onClear();
  }, []);

  const onDrop = useCallback((filesDroped: UploadableFile[]) => {
    files.setter(filesDroped);
  }, []);

  return (
    <UploaderCard border="medium" elevated="small" data-testid="uploader-card">
      <TextContainer>
        <Heading size="H4">{formatMessage({ id: 'pages.upload.uploader.title' })}</Heading>

        <Paragraph size="small" colortype="secondary">
          {formatMessage({ id: 'pages.upload.uploader.description' })}
        </Paragraph>
      </TextContainer>

      <FileMultiUploader
        accept={filesConfig.accept}
        maxFileSize={filesConfig.size}
        disabled={!!fileId}
        hasPreviewButton={false}
        title=""
        inputLabel={formatMessage({ id: 'pages.upload.uploader.fileUploader.label' })}
        message={formatMessage({ id: 'pages.upload.uploader.fileUploader.description' })}
        value={files.value}
        onDrop={onDrop}
        onClean={onClean}
      />

      {hasFile && (
        <TextAreaContainer data-testid="text-area-container">
          <TextContainer>
            <DescriptionTextContainer>
              <Paragraph size="small" weight="semibold">
                {formatMessage({ id: 'pages.upload.uploader.fileDescription.title' })}
              </Paragraph>

              <Paragraph size="small" colortype="secondary">
                {formatMessage({ id: 'pages.upload.uploader.fileDescription.subTitle' })}
              </Paragraph>
            </DescriptionTextContainer>
          </TextContainer>

          <TextArea
            id="file-description"
            width={'100%'}
            optionalText=""
            characterCounter
            maxLength={150}
            disabled={!!fileId}
            value={description.value}
            placeholder={formatMessage({ id: 'pages.upload.uploader.fileDescription.description' })}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              description.setter(e.target.value)
            }
          />
        </TextAreaContainer>
      )}
    </UploaderCard>
  );
};
