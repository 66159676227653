import { Button, Divider, Heading, Paragraph } from '@hexa-ui/components';
import { PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useMemo, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useFormatting } from '../../../hooks/useFormatting';
import { useGetSources } from '../../../hooks/useGetSources';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../hooks/useRequester';
import { useToast } from '../../../hooks/useToast';
import { Status } from '../../../interfaces';
import { updateRecommendation, updateStatus } from '../../../services';
import { Input } from '../../atoms/Input/Input';
import {
  EditRecommendationProps,
  EditState,
  InitialMapping,
  PayloadEdit,
} from './EditRecommendation.types';
import { ButtonContainer, Close, Container, InputContainer, ModalContent, Root } from './styles';

export const EditRecommendation: React.FC<EditRecommendationProps> = ({
  data,
  trigger,
  conditions = false,
  callbackFn,
}) => {
  const { formatMessage } = useIntl();
  const { patch, post } = useRequester();

  const { getValidation } = useGetSources();
  const { formatting } = useFormatting();
  const { toast } = useToast();
  const { user, currency, permissions, configs } = useGetUserPreferences();

  const { validations } = configs;

  const reducer = useCallback((state: EditState, { type, payload }: PayloadAction<PayloadEdit>) => {
    if (type === 'CLEAR' || !payload) return initial;

    const { has, message } = getValidation(
      payload.id,
      payload.state.value === '00' ? '' : payload.state.value,
      validations[payload.id]
    );

    if (type === 'SETTER' && payload.state.value && payload.state.value !== '00') {
      return {
        ...state,
        [payload.id]: {
          value: payload.state.value,
          formatted: payload.state.formatted,
          error: { has, message },
        },
      };
    }

    return {
      ...state,
      [payload.id]: {
        value: '',
        formatted: { simple: '', completed: '' },
        error: { has, message },
      },
    };
  }, []);

  const initial = useMemo(() => {
    const initalMapping: InitialMapping = {
      limit: { type: 'currency', text: data.creditLimit ?? '' },
      term: { type: 'days', text: data.term ?? '' },
      'term.Fee': { type: 'fee', text: data.fee ?? '' },
    };

    return Object.entries(initalMapping).reduce((acc, [key, { type, text }]) => {
      const { value, formatted } = formatting({ type, value: text });

      acc[key] = {
        value,
        formatted: formatted,
        error: { has: false, message: '' },
      };

      return acc;
    }, {});
  }, [data.creditLimit, data.term, data.fee]);

  const [state, dispatch] = useReducer(reducer, initial);

  const { isDisabled } = useMemo(() => {
    const keyMapping: { [key: string]: string } = {
      limit: 'creditLimit',
      term: 'term',
      'term.Fee': 'fee',
    };

    const { hasErrors, allEqual } = Object.entries(state as EditState).reduce(
      (acc, [key, { error, value }]) => {
        acc.hasErrors.push(error.has || !value);
        acc.allEqual.push(value === data[keyMapping[key]]);

        return acc;
      },
      { hasErrors: [], allEqual: [] }
    );

    return { isDisabled: hasErrors.some((error) => error) || allEqual.every((equal) => equal) };
  }, [state]);

  const onChange = useCallback(({ id, value, formatted }) => {
    dispatch({ type: 'SETTER', payload: { id, state: { value, formatted } } });
  }, []);

  const onClear = () => dispatch({ type: 'CLEAR', payload: null });

  const onCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onClear();
  };

  const onApply = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (conditions) {
      const response = await updateStatus({
        api: { post },
        params: [
          {
            bees_account_id: data.beesAccountId,
            status: Status.APPROVED,
            user_id: user.name,
            limit: Number(state['limit'].value),
            term: Number(state['term'].value),
            fee: Number(state['term.Fee'].value) / 100,
          },
        ],
      });

      if (!response?.success) {
        toast.error({ message: 'errors.generic' });

        return;
      }

      toast.success({
        message: 'approval.toasts.APPROVED.single',
        attributes: { value: data.pocName },
      });

      if (callbackFn) callbackFn();
      return;
    }

    const response = await updateRecommendation({
      api: { patch },
      params: {
        assessmentId: data.assessmentId,
        attributes: {
          limit: Number(state['limit'].value),
          term: Number(state['term'].value),
          fee: Number(state['term.Fee'].value),
        },
      },
    });

    if (!response?.success) {
      toast.error({ message: 'errors.generic' });

      return;
    }

    toast.success({
      message: 'editRecommendation.toasts.success',
      attributes: { value: data.pocName },
    });

    if (callbackFn) callbackFn();
  };

  if (!permissions.has('actions.edit')) {
    return null;
  }

  return (
    <Container data-testid="edit-container" onClick={(e) => e.stopPropagation()}>
      <Root
        title={<Heading size="H2">{formatMessage({ id: 'editRecommendation.title' })}</Heading>}
        trigger={trigger}
        onClose={onCancel}
        onInteractOutside={onClear}
        actions={
          <ButtonContainer>
            <Close>
              <Button id="on-cancel" size="medium" variant="secondary" onClick={onCancel}>
                {formatMessage({ id: 'editRecommendation.buttons.cancel' })}
              </Button>
            </Close>

            <Close>
              <Button
                id="on-apply"
                size="medium"
                variant="primary"
                disabled={isDisabled}
                onClick={onApply}
              >
                {formatMessage({ id: 'editRecommendation.buttons.apply' })}
              </Button>
            </Close>
          </ButtonContainer>
        }
      >
        <ModalContent data-testid="modal-content" onClick={(e) => e.stopPropagation()}>
          <Paragraph size="basis">
            {formatMessage({ id: 'editRecommendation.description' })}
          </Paragraph>

          <Divider orientation="horizontal" />

          <Heading size="H4">{`${data.pocName} - ${data.accountId}`}</Heading>

          <InputContainer data-testid="input-container">
            <Input.Text
              id="limit"
              format="currency"
              size="large"
              width="100%"
              height="115px"
              prefix={currency.config.symbol}
              label={formatMessage({ id: 'editRecommendation.inputs.creditLimit.label' })}
              placeholder={formatting({ type: 'currency', value: '1000000' }).formatted.simple}
              hint={formatMessage(
                { id: 'editRecommendation.inputs.creditLimit.hint' },
                { value: currency.format(10000) }
              )}
              error={{ has: state['limit'].error.has, message: state['limit'].error.message }}
              value={state['limit'].formatted.simple}
              onChange={onChange}
            />

            <Input.Text
              id="term"
              format="days"
              size="large"
              width="100%"
              height="115px"
              suffix={formatMessage(
                { id: 'formatting.input.suffix.days' },
                { value: state['term'].formatted.simple }
              )}
              label={formatMessage({ id: 'editRecommendation.inputs.term.label' })}
              placeholder={'14'}
              hint={formatMessage({ id: 'editRecommendation.inputs.term.hint' }, { value: 14 })}
              error={{ has: state['term'].error.has, message: state['term'].error.message }}
              value={state['term'].formatted.simple}
              onChange={onChange}
            />

            <Input.Text
              id="term.Fee"
              format="percentage"
              size="large"
              width="100%"
              height="115px"
              suffix={formatMessage({ id: 'formatting.input.suffix.percentage' })}
              label={formatMessage({ id: 'editRecommendation.inputs.fee.label' })}
              placeholder={'2,30'}
              error={{ has: state['term.Fee'].error.has, message: state['term.Fee'].error.message }}
              value={state['term.Fee'].formatted.simple}
              onChange={onChange}
            />
          </InputContainer>
        </ModalContent>
      </Root>
    </Container>
  );
};
