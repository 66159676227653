import { Format } from '../interfaces';

export const formatInformation = ({ type, value, config }: Format) => {
  if (type === 'document-number' || type === 'phone-number' || type === 'zip-code') {
    const regex = new RegExp(config.regex);

    return value?.replace(regex, config.format);
  }

  if (type === 'address') {
    const cityState = [value?.city, value?.state?.toUpperCase()].filter(Boolean).join(' - ');

    const addressParts = [
      value.street,
      cityState,
      formatInformation({ type: 'zip-code', value: value?.zipCode, config }),
    ].filter(Boolean);

    return addressParts.length > 0 ? addressParts.join(', ') : '';
  }

  return value;
};
