import { currencies } from '../constants';

export const getCurrencySymbol = (locale: string): string | null => {
  const currencyCode = currencies.find((value) => value.cc === locale.split('-')[1])?.cd;

  if (!currencyCode) return null;

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const parts = formatter.formatToParts(0);

  for (const part of parts) {
    if (part.type === 'currency') {
      return part.value;
    }
  }
};

export const formatCurrency = (locale: string): Intl.NumberFormat['format'] => {
  const currencyCode = currencies.find((value) => value.cc === locale.split('-')[1])?.cd;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode ?? 'BRL',
  }).format;
};
