import { Button } from '@hexa-ui/components';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { useToast } from '../../../../hooks/useToast';
import { UploadStatus } from '../../../../interfaces';
import { applyUpdate, uploadToBackend as serviceUploadToBackend } from '../../../../services';
import { useUpload } from '../Context';
import { ButtonsContainer } from './styles';

export const Buttons: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { files, description, uploaded, progress, uploadStarted, onClear } = useUpload();
  const { fileId, fileName } = uploaded.value;
  const { status, processed } = progress.value;

  const { post, uploadToBackend } = useRequester();
  const { toast } = useToast();

  const { user } = useGetUserPreferences();

  const file = useMemo(() => {
    const { fileContent: file } = files.value.find((file) => !file.errors.length) || {};

    return file;
  }, [files]);

  const onBack = useCallback(() => {
    onClear();

    navigate('/bees-credit-management/files');
  }, []);

  const onUpload = useCallback(async () => {
    toast.info({
      message: 'pages.upload.buttons.toasts.processing',
      attributes: { fileName },
    });

    uploadStarted.setter(true);

    const response = await serviceUploadToBackend({
      api: { uploadToBackend },
      params: { userId: user.name, file, fileName: file.name, fileDescription: description.value },
    });

    if (!response?.success) {
      uploaded.setter({ fileId: '', fileName: '' });
      toast.warning({
        message: 'pages.upload.buttons.toasts.error',
        attributes: { fileName },
      });

      return;
    }

    uploaded.setter({ fileId: response.data?.id, fileName: response.data?.reference_name });
    toast.success({
      message: 'pages.upload.buttons.toasts.success',
      attributes: { fileName },
    });
  }, [file, description.value, fileName]);

  const onConfirm = useCallback(async () => {
    const response = await applyUpdate({
      api: { post },
      params: { fileId },
    });

    if (!response?.success) {
      toast.warning({
        message: 'pages.upload.buttons.toasts.error',
        attributes: { fileName },
      });

      return;
    }

    toast.success({
      message: 'pages.upload.buttons.toasts.analysis',
      attributes: { processed: processed },
    });

    navigate('/bees-credit-management/files');
  }, [fileId, processed]);

  return (
    <ButtonsContainer data-testid="buttons-container">
      <Button id="back-button" size="medium" variant="secondary" onClick={onBack}>
        {formatMessage({ id: 'pages.upload.buttons.back' })}
      </Button>

      {fileId ? (
        <Button
          id="apply-button"
          size="medium"
          variant="primary"
          disabled={status !== UploadStatus.ANALYZED}
          onClick={onConfirm}
        >
          {formatMessage({ id: 'pages.upload.buttons.apply' })}
        </Button>
      ) : (
        <Button
          id="upload-button"
          size="medium"
          variant="primary"
          disabled={!file || uploadStarted.value}
          onClick={onUpload}
        >
          {formatMessage({ id: 'pages.upload.buttons.upload' })}
        </Button>
      )}
    </ButtonsContainer>
  );
};
