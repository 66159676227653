import { AssessmentsItem, AssessmentTag } from '../../../../../interfaces';
import { ActionsNotRecommended } from './ActionsNotRecommended';
import { ActionsRecommended } from './ActionsRecommended';

interface ActionsProps {
  selected: AssessmentsItem;
  callbackFn: () => Promise<void>;
  tag: string;
}

export type ActionsMapping = {
  [key in AssessmentTag]: JSX.Element;
};

export const Actions: React.FC<ActionsProps> = ({ selected, callbackFn, tag }) => {
  const actionsMapping: ActionsMapping = {
    [AssessmentTag.RECOMMENDED]: <ActionsRecommended selected={selected} callbackFn={callbackFn} />,
    [AssessmentTag.NOT_RECOMMENDED]: (
      <ActionsNotRecommended selected={selected} callbackFn={callbackFn} />
    ),
  };

  return actionsMapping[tag];
};
