import { Alert, Divider, Heading, IconButton, Paragraph, Tooltip } from '@hexa-ui/components';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { keys } from '../../../../../constants';
import { CustomViewType, MappedView } from '../../../../../interfaces';
import { useViews } from '../../../../../store/hooks/customViewsHook';
import { FeatureToggle } from '../../../FeatureToggle/FeatureToggle';
import { Modal } from '../Modal/Modal';
import { ViewListProps } from '../ViewSelector.types';
import {
  Card,
  CreateButton,
  FooterContainer,
  IconButtonsContainer,
  List,
  ListItem,
  ViewsContainer,
} from './styles';

export const ViewList: React.FC<ViewListProps> = ({ table, onClose }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { standard, custom, active, views } = useViews(table);

  const onViewChange = (index: number, type: CustomViewType) => {
    views.active([index, type]);

    onClose();
  };

  const onGo = (
    event: React.MouseEvent<HTMLButtonElement>,
    type: 'edit' | 'create',
    index?: number
  ) => {
    event.stopPropagation();

    navigate(
      `/bees-credit-management/custom-view/${type}/${table}${
        index !== undefined ? `/${index}` : ''
      }`
    );
  };

  return (
    <Card data-testid="view-selector-card" elevated="small" border="small">
      <FeatureToggle keys={[keys.cm_has_not_recommended]}>
        <ViewsContainer data-testid="views-container-standard">
          <Heading size="H4">{formatMessage({ id: 'tables.views.card.standard.title' })}</Heading>

          <List>
            {standard.map((view: MappedView, index: number) => (
              <ListItem
                key={`${index}-${view.name}`}
                id={view.name}
                variant="selectable"
                $active={view.name === active.name}
                onClick={() => onViewChange(index, CustomViewType.DEFAULT)}
              >
                <Paragraph size="basis">{view.name}</Paragraph>
              </ListItem>
            ))}
          </List>
        </ViewsContainer>
      </FeatureToggle>

      <FeatureToggle keys={[keys.cm_has_not_recommended, keys.cm_has_custom_views]}>
        <Divider orientation="horizontal" css={{ marginBottom: 16 }} />
      </FeatureToggle>

      <FeatureToggle keys={[keys.cm_has_custom_views]}>
        <ViewsContainer $scroll data-testid="views-container-custom">
          <Heading size="H4">{formatMessage({ id: 'tables.views.card.custom.title' })}</Heading>

          <List>
            {custom.map((view: MappedView, index: number) => (
              <ListItem
                key={`${index}-${view.name}`}
                id={view.name}
                variant="selectable"
                $active={view.name === active.name}
                onClick={() => onViewChange(index, CustomViewType.CUSTOM)}
              >
                <Paragraph size="basis">{view.name}</Paragraph>

                <IconButtonsContainer data-testid="icon-buttons-container">
                  <IconButton
                    id="edit-button"
                    size="small"
                    variant="inherit"
                    onClick={(event) => onGo(event, 'edit', index)}
                    icon={() => (
                      <Tooltip
                        placement="bottom"
                        text={formatMessage({ id: 'tables.views.card.tooltips.edit' })}
                      >
                        <Edit2 size="medium" />
                      </Tooltip>
                    )}
                  />

                  <Modal
                    table={table}
                    index={index}
                    onClose={onClose}
                    trigger={
                      <IconButton
                        id="delete-button"
                        size="small"
                        variant="inherit"
                        onClick={(event) => event.stopPropagation()}
                        icon={() => (
                          <Tooltip
                            placement="bottom"
                            text={formatMessage({ id: 'tables.views.card.tooltips.delete' })}
                          >
                            <Trash2 size="medium" />
                          </Tooltip>
                        )}
                      />
                    }
                  />
                </IconButtonsContainer>
              </ListItem>
            ))}
          </List>
        </ViewsContainer>

        <FooterContainer>
          {custom.length < 10 ? (
            <CreateButton
              id="create-button"
              size="medium"
              icon={() => <Plus size="large" />}
              iconPosition="leading"
              onClick={(event) => onGo(event, 'create')}
            >
              <Paragraph size="basis" css={{ color: '#0F7FFA' }}>
                {formatMessage({ id: 'tables.views.card.buttons.create' })}
              </Paragraph>
            </CreateButton>
          ) : (
            <Alert
              type="info"
              message={formatMessage({ id: 'tables.views.card.alert' })}
              css={{ width: '100%' }}
            />
          )}
        </FooterContainer>
      </FeatureToggle>
    </Card>
  );
};
