import { Divider, Heading, Paragraph, ProgressTracker, TextButton } from '@hexa-ui/components';
import { Edit2 } from '@hexa-ui/icons';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFormatting } from '../../../../hooks/useFormatting';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { StatusRecommendation } from '../../../../interfaces';
import { StatusLabel } from '../../../atoms/StatusLabel';
import { ApprovalButtons } from '../../../molecules/ApprovalButtons/ApprovalButtons';
import { EditRecommendation } from '../../../molecules/EditRecommendation/EditRecommendation';
import { Subheader } from '../styles';
import { FieldsMapping, RecommendationCardProps } from './Recommendation.types';
import {
  ButtonsContainer,
  Card,
  HeaderContainer,
  HeaderContent,
  InfoContainer,
  InfoContent,
  TimelineContainer,
} from './styles';

export const Recommendation: React.FC<RecommendationCardProps> = ({
  data,
  recommendation,
  timeline,
  onCallback,
  onOpenEditRecommendationModal,
}) => {
  const { formatMessage } = useIntl();

  const { formatting } = useFormatting();
  const { datetime } = useGetUserPreferences();

  const { isPending, isDenied } = useMemo(() => {
    const isPending = recommendation?.status === StatusRecommendation.PENDING;
    const isDenied = recommendation?.status === StatusRecommendation.DENIED;

    return { isPending, isDenied };
  }, [recommendation?.status]);

  const shouldDisplayStatusLabel =
    recommendation?.status !== StatusRecommendation.WAITING_ACTIVATION &&
    recommendation?.status !== StatusRecommendation.WAITING_ONBOARDING;

  const fieldsMapping: FieldsMapping = useMemo(
    () => ({
      creditLimit: { type: 'currency', value: recommendation?.creditLimit },
      termFee: { type: 'term-fee', value: recommendation?.termFee },
      recommendation: { type: 'recommendation', value: recommendation?.creditRecommendation },
      updatedOn: { type: 'datetime', value: recommendation?.updatedOn },
      approvedBy: {
        type: 'text',
        value: recommendation?.approvedBy,
        notShow: isPending || isDenied,
      },
      approvalMethod: {
        type: 'approval-method',
        value: recommendation?.approvalMethod,
        notShow: isPending || isDenied,
      },
    }),
    [recommendation]
  );

  if (!recommendation) {
    return null;
  }

  return (
    <Card border="large" elevated="large">
      <HeaderContainer>
        <HeaderContent>
          <Heading size="H3">
            {formatMessage({ id: 'pages.poc-details.recommendation.title' })}
          </Heading>
          {shouldDisplayStatusLabel && <StatusLabel status={recommendation?.status} />}
        </HeaderContent>

        {isPending && (
          <EditRecommendation
            data={data}
            callbackFn={onCallback}
            onOpenModal={onOpenEditRecommendationModal}
            trigger={
              <TextButton
                size="medium"
                iconPosition="leading"
                icon={Edit2}
                css={{ color: '#0F7FFA' }}
              >
                {formatMessage({ id: 'editRecommendation.buttons.edit.recommendation' })}
              </TextButton>
            }
          />
        )}
      </HeaderContainer>

      <InfoContainer>
        {Object.entries(fieldsMapping).map(([key, attributes]) => {
          if (attributes?.notShow) return null;

          const { formatted } = formatting({
            type: attributes.type,
            value: attributes.value,
            config: { datetimeType: 'time' },
          });

          return (
            <InfoContent key={key}>
              <Subheader>
                {formatMessage({ id: `pages.poc-details.recommendation.labels.${key}` })}
              </Subheader>

              <Paragraph size="basis">{formatted.completed || '-'}</Paragraph>
            </InfoContent>
          );
        })}
      </InfoContainer>

      {isPending && (
        <ButtonsContainer>
          <ApprovalButtons
            mode="single"
            type="button"
            button={{ size: 'medium' }}
            selected={data}
            callbackFn={onCallback}
          />
        </ButtonsContainer>
      )}

      {!isPending && !isDenied && timeline && (
        <React.Fragment>
          <Divider orientation="horizontal" />

          <Heading size="H5">
            {formatMessage({ id: 'pages.poc-details.recommendation.timeline.title' })}
          </Heading>

          <TimelineContainer>
            <ProgressTracker.Root
              size="large"
              orientation="horizontal"
              currentStep={timeline.currentStep}
            >
              <ProgressTracker.Header>
                {timeline.steps.map((item, index) => (
                  <ProgressTracker.Step
                    index={index}
                    key={item.label}
                    completed={timeline.completedSteps.includes(index)}
                    disabled={
                      timeline.currentStep !== index && !timeline.completedSteps.includes(index)
                    }
                  >
                    <Paragraph>{formatMessage({ id: item.label })}</Paragraph>

                    <Paragraph>{item.date ? datetime.format(item.date, 'long') : ''}</Paragraph>
                  </ProgressTracker.Step>
                ))}
              </ProgressTracker.Header>
            </ProgressTracker.Root>
          </TimelineContainer>
        </React.Fragment>
      )}
    </Card>
  );
};
