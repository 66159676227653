import { Paragraph } from '@hexa-ui/components';
import { EmptyMessageProps } from './EmptyMessage.types';
import { EmptyMessageContainer } from './styles';

export const EmptyMessage: React.FC<EmptyMessageProps> = ({ message, Icon }) => {
  return (
    <EmptyMessageContainer data-testid="empty-message-container">
      {Icon && <Icon />}

      <Paragraph size="basis">{message}</Paragraph>
    </EmptyMessageContainer>
  );
};
