import { Alert, Grid, LoadingDots } from '@hexa-ui/components';
import { useSidebar } from 'admin-portal-shared-services';
import { Suspense } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useGetUserPreferences } from '../hooks/useGetUserPreferences';
import { useUiStore } from '../hooks/useUiStore';
import { SideBarItem } from '../interfaces';
import { CreditPage } from '../pages/Credit/CreditPage';
import { FilesPage } from '../pages/Files/FilesPage';
import { PocDetailsPage } from '../pages/PocDetails/PocDetailsPage';
import { UploadPage } from '../pages/Upload/UploadPage';
import { ViewsPage } from '../pages/Views/ViewsPage';

interface IconProps {
  icon: React.ForwardRefExoticComponent<any>;
}

const getIcon = ({ icon }: IconProps): React.ReactElement => {
  const Component = icon;

  return <Component />;
};

export const Router = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const { snackbarMessage, snackbarType } = useUiStore();
  const { configs, permissions } = useGetUserPreferences();

  const { sidebar } = configs;

  useSidebar({
    items: sidebar
      .filter((item: SideBarItem) => permissions.has(item.permission))
      .map((item: SideBarItem, index: number) => ({
        id: `${index}-${item.title}`,
        title: formatMessage({ id: item.title }),
        icon: () => getIcon({ icon: item.icon }),
        path: item.path,
      })),
    utils: [],
  });

  return (
    <Grid.Container sidebar type="fluid" data-testid="router-container">
      {snackbarMessage && (
        <Alert
          css={{ position: 'fixed', zIndex: '4000', bottom: '$10', right: '$10' }}
          type={snackbarType}
          message={snackbarMessage}
        />
      )}

      <Routes>
        <Route
          path={'/bees-credit-management/credit'}
          element={
            <Suspense fallback={<LoadingDots />}>
              <CreditPage />
            </Suspense>
          }
        />

        <Route
          path={'/bees-credit-management/files'}
          element={
            <Suspense fallback={<LoadingDots />}>
              <FilesPage />
            </Suspense>
          }
        />

        <Route
          path={'/bees-credit-management/upload'}
          element={
            <Suspense fallback={<LoadingDots />}>
              <UploadPage />
            </Suspense>
          }
        />

        <Route
          path={'/bees-credit-management/upload/progress/:fileId/:fileName'}
          element={
            <Suspense fallback={<LoadingDots />}>
              <UploadPage />
            </Suspense>
          }
        />

        <Route
          path={'/bees-credit-management/poc-details/:id'}
          element={
            <Suspense fallback={<LoadingDots />}>
              <PocDetailsPage />
            </Suspense>
          }
        />

        <Route
          path={'/bees-credit-management/custom-view/:mode/:table/:index?'}
          element={
            <Suspense fallback={<LoadingDots />}>
              <ViewsPage />
            </Suspense>
          }
        />

        <Route path={'*'} element={<Navigate to="/bees-credit-management/credit" />} />
      </Routes>
    </Grid.Container>
  );
};
