import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomViewState, CustomViewType, Tables, View } from '../../interfaces';

const initialState: CustomViewState = {};

const customViewsSlice = createSlice({
  name: 'customViews',
  initialState,
  reducers: {
    createView: (
      state: CustomViewState,
      action: PayloadAction<{
        userId: string;
        table: Tables;
        view: View;
      }>
    ) => {
      const { userId, table, view } = action.payload;

      if (!state[userId][table].view) {
        state[userId][table].view = [];
      }

      const index = state[userId][table].view?.push(view) - 1;
      state[userId][table].active = [index, CustomViewType.CUSTOM];
    },
    setActive: (
      state: CustomViewState,
      action: PayloadAction<{
        userId: string;
        table: Tables;
        active: [number, string];
      }>
    ) => {
      const { userId, table, active } = action.payload;

      if (!state[userId]) {
        state[userId] = {};
      }

      if (!state[userId][table]) {
        state[userId][table] = {
          active: [0, ''],
        };
      }

      state[userId][table].active = active;
    },
    deleteView: (
      state: CustomViewState,
      action: PayloadAction<{
        userId: string;
        table: Tables;
        index: number;
      }>
    ) => {
      const { userId, table, index } = action.payload;

      const [activeIndex, activeType] = state[userId]?.[table]?.active ?? [];
      const activeName =
        activeType === CustomViewType.CUSTOM && state[userId][table].view[activeIndex].name;

      if (state[userId]?.[table]?.view) {
        state[userId][table].view?.splice(index, 1);
      }

      const activeIndexAfterDelete =
        activeType === CustomViewType.CUSTOM
          ? state[userId][table].view.findIndex((view) => view.name === activeName)
          : activeIndex;

      if (activeIndex === index && activeType === CustomViewType.CUSTOM) {
        state[userId][table].active = [0, CustomViewType.DEFAULT];
      } else if (state[userId][table]?.active) {
        state[userId][table].active = [activeIndexAfterDelete, activeType];
      }
    },
    editView: (
      state: CustomViewState,
      action: PayloadAction<{
        userId: string;
        table: Tables;
        index: number;
        view: View;
      }>
    ) => {
      const { userId, table, index, view } = action.payload;

      if (state[userId]?.[table]?.view) {
        state[userId][table].view[index] = view;

        state[userId][table].active = [index, CustomViewType.CUSTOM];
      }
    },
  },
});

export const { createView, setActive, deleteView, editView } = customViewsSlice.actions;

export default customViewsSlice.reducer;
