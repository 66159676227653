import { IconButton } from '@hexa-ui/components';
import { Download } from '@hexa-ui/icons';
import React, { useCallback, useState } from 'react';
import { useRequester } from '../../../../../hooks/useRequester';
import { FilesConfig } from '../../../../../interfaces';
import { downloadFileFromBackend as serviceDownloadFileFromBackend } from '../../../../../services';

interface ActionsProps {
  fileId: string;
  filesConfig: FilesConfig;
}

export const Actions: React.FC<ActionsProps> = ({ fileId, filesConfig }) => {
  const { downloadFileFromBackend } = useRequester();

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setIsLoading(true);

    await serviceDownloadFileFromBackend({
      api: { downloadFileFromBackend },
      params: { fileId, filesConfig },
    }).finally(() => setIsLoading(false));
  }, [fileId]);

  return (
    <IconButton
      id="download-button"
      variant="tertiary"
      icon={Download}
      loading={isLoading}
      onClick={handleClick}
      style={{ backgroundColor: '#14141429' }}
    />
  );
};
