import { Dialog, Heading, LoadingDots, Pagination, Paragraph } from '@hexa-ui/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRequester } from '../../../../../../hooks/useRequester';
import { getCreditHistory } from '../../../../../../services';
import { CreditHistoryProps } from '../CreditHistory.types';
import { CreditModalProps } from './CreditModal.types';
import { ModalError } from './Error/ModalError';
import { ModalItem } from './Item/ModalItem';
import { Content, CreditModalContent, HeaderContainer, PaginationContainer } from './styles';

export const CreditModal: React.FC<CreditModalProps> = ({ beesAccountId, trigger }) => {
  const { formatMessage } = useIntl();
  const { get } = useRequester();

  const datetime = { previous: '' };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [historical, setHistorical] = useState<CreditHistoryProps[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalElements: 0,
  });

  const status = useMemo(() => {
    if (isLoading) return 'LOADING';
    if (!isLoading && hasError) return 'ERROR';

    return 'SUCCESS';
  }, [isLoading, hasError]);

  const getData = async () => {
    setIsLoading(true);
    const response = await getCreditHistory({
      api: { get },
      params: { beesAccountId, pagination: { page: pagination.page } },
    });

    if (!response?.success) {
      setHistorical([]);
      setHasError(true);
      setIsLoading(false);

      return;
    }

    const { data, pagination: pageble } = response.data;

    setHistorical(data);
    setHasError(false);
    setIsLoading(false);

    setPagination((prev) => ({
      page: prev.page,
      pageSize: pageble.pageSize,
      totalPages: pageble.totalPages,
      totalElements: pageble.totalElements,
    }));
  };

  useEffect(() => {
    getData();
  }, [pagination.page]);

  return (
    <Dialog.Root
      trigger={trigger}
      headerHeight="100px"
      contentCss={{ padding: 0, margin: 0 }}
      title={
        <HeaderContainer>
          <Heading size="H2">
            {formatMessage({ id: 'pages.poc-details.history.credit.title' })}
          </Heading>

          <Paragraph size="basis">
            {formatMessage({ id: 'pages.poc-details.history.credit.description' })}
          </Paragraph>
        </HeaderContainer>
      }
    >
      <CreditModalContent $align={isLoading || hasError} $haspagination={pagination.totalPages > 1}>
        {(() => {
          switch (status) {
            case 'LOADING':
              return <LoadingDots />;
            case 'ERROR':
              return <ModalError />;
            default:
              return (
                <React.Fragment>
                  <Content>
                    {historical.map((history) => {
                      const previous = history.date.substring(0, 10);
                      const hasDate = datetime.previous !== previous;

                      datetime.previous = previous;

                      return (
                        <ModalItem
                          key={`${history.status}-${history.date}`}
                          hasDate={hasDate}
                          history={history}
                        />
                      );
                    })}
                  </Content>

                  {pagination.totalPages > 1 && (
                    <PaginationContainer>
                      <Pagination
                        showPageSizeSelector={false}
                        current={pagination.page}
                        pageSize={pagination.pageSize}
                        total={pagination.totalElements}
                        onChange={(page: number) => {
                          setPagination((prev) => ({ ...prev, page }));
                        }}
                      />
                    </PaginationContainer>
                  )}
                </React.Fragment>
              );
          }
        })()}
      </CreditModalContent>
    </Dialog.Root>
  );
};
