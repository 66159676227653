import { Button, Heading, Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { GenericError } from '../../../../icons/Error/Error.svg';
import { CustomErrorProps } from './CustomError.types';
import { CustomErrorContainer } from './styles';

export const CustomError: React.FC<CustomErrorProps> = ({
  title,
  description,
  button = { has: false },
}) => {
  const { formatMessage } = useIntl();

  return (
    <CustomErrorContainer data-testid="custom-error-container">
      <GenericError />

      <Heading size="H2">{formatMessage({ id: title })}</Heading>

      <Paragraph size="basis">{formatMessage({ id: description })}</Paragraph>

      {button.has && <Button onClick={button.onClick}>{formatMessage({ id: button.text })}</Button>}
    </CustomErrorContainer>
  );
};
