import { Card as HexaCard, Radio as HexaRadio } from '@hexa-ui/components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  row-gap: 24px;
`;

export const Card = styled(HexaCard)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  padding: 32px;
  row-gap: 24px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;

  width: 100%;
  column-gap: 18px;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 50%;

  #radio-label {
    position: initial;
    font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.9);
  }
`;

export const Radio = styled(HexaRadio.Root)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;

  width: 100%;
  column-gap: 32px;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  column-gap: 8px;

  div[data-testid='tooltip'] {
    display: flex;
    align-items: center;
  }
`;

export const Item = styled(HexaRadio.Item)``;

export const Columns = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;

  border: 1px solid var(--border-secondary, #1414141f);
  border-radius: 16px;

  width: 100%;
  padding: 32px;
  column-gap: 18px;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  row-gap: 24px;
`;

export const CheckboxList = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  row-gap: 24px;
`;

export const CheckboxItem = styled.div`
  display: flex;
  flex-direction: column;

  width: 45%;
  row-gap: 24px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  width: 100%;
  column-gap: 24px;
`;
