import { Badge } from '@hexa-ui/components';
import styled from 'styled-components';

const { Status } = Badge;

export const Label = styled(Status)<{ $color: string }>`
  border-radius: 4px;
  background-color: transparent;
  height: 28px;
  margin: 8px 0;

  p {
    color: ${({ $color }) => $color};
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  p::before,
  p:before {
    content: '';
    display: block;
    background: ${({ $color }) => $color};
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
`;
