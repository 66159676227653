import { Paragraph } from '@hexa-ui/components';
import React from 'react';
import { useIntl } from 'react-intl';
import { ErrorLoading } from '../../../../../../icons/ErrorLoading.svg';

export const ModalError: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      <ErrorLoading />

      <Paragraph size="basis">{formatMessage({ id: 'errors.generic' })}</Paragraph>
    </React.Fragment>
  );
};
