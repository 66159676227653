import { Heading, Paragraph } from '@hexa-ui/components';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { formatInformation } from '../../../../utils/poc-details';
import { Subheader } from '../styles';
import { FieldsMapping, InformationCardProps } from './Information.types';
import { Card, InfoContainer, InfoContent } from './styles';

export const Information: React.FC<InformationCardProps> = ({ information }) => {
  const { formatMessage } = useIntl();

  const { configs } = useGetUserPreferences();
  const { formatting } = configs;

  const fieldsMapping: FieldsMapping = useMemo(
    () => ({
      pocName: { value: information.pocName, type: 'default' },
      pocDocument: { value: information.pocDocument, type: 'document-number' },
      pocAddress: { value: information.pocAddress, type: 'address' },
      pocEmail: { value: information.pocEmail, type: 'default' },
      pocPhoneNumber: { value: information.pocPhoneNumber, type: 'phone-number' },
    }),
    [information]
  );

  return (
    <Card border="large" elevated="large">
      <Heading size="H3">{formatMessage({ id: 'pages.poc-details.information.title' })}</Heading>

      <InfoContainer>
        {Object.entries(fieldsMapping).map(([key, attributes]) => {
          const formatted = formatInformation({
            type: attributes.type,
            value: attributes.value,
            config: formatting[attributes.type],
          });

          return (
            <InfoContent key={key}>
              <Subheader type="small">
                {formatMessage({ id: `pages.poc-details.information.labels.${key}` })}
              </Subheader>

              <Paragraph size="basis">{formatted || '-'}</Paragraph>
            </InfoContent>
          );
        })}
      </InfoContainer>
    </Card>
  );
};
