import { Card as HexaCard } from '@hexa-ui/components';
import styled from 'styled-components';

export const Card = styled(HexaCard)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  padding: 24px;
  row-gap: 24px;
`;

export const InfoContainer = styled.div`
  display: grid;
  justify-items: start;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;

  width: 100%;
`;

export const InfoContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;

  & > h5 {
    margin: 0;
  }

  & > p {
    margin: 0;
    word-break: break-word;
  }
`;
