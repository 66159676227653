import { OnRowReturnType } from '@hexa-ui/components';
import { DateType, GenericObject, TextType } from '..';
import { FilterType, InputProps } from '../../components/atoms/Table/Filter/Filter.types';

/**
 * @namespace Tables
 * @interface Columns
 */

export interface ColumnProps {
  id: string;
  accessor: string;
  disableSortBy?: boolean;
  customCell?: boolean;
  style?: GenericObject;
  permissions?: string[];
  customRender?: (value: any) => JSX.Element;
}

export interface FilterProps {
  type: FilterType;
  identifier: string;
  name: string;
  title: string;
  input: InputProps[];
}

/**
 * @namespace Tables
 * @interface CustomColumns
 */

interface TextParams {
  value: any;
  width: number;
}

export interface TextDateParams extends TextParams {
  type: DateType;
}

export type TextProps<T extends TextType> = (T extends 'datetime'
  ? { type: T; params: TextDateParams }
  : { type: T; params: TextParams }) & { hasEllipsis?: boolean };

/**
 * @namespace Tables
 * @interface Table
 */

interface TableMessages {
  empty: string;
  loading: string;
}

interface TableSearch {
  has: boolean;
  width?: string | number;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}

interface TablePagination {
  size: number;
  total?: number;
  onChange?: (page: number) => void;
}

interface TableFilters {
  has: boolean;
  config: FilterProps[];
  pageSetter?: (value: number) => void;
}

interface TableSelectable {
  has: boolean;
  hasValue: boolean;
  onSelected: (rows: GenericObject) => void;
}

export interface TableProps {
  data: GenericObject[];
  columns: ColumnProps[];
  loading: boolean;
  messages: TableMessages;
  search?: TableSearch;
  filters?: TableFilters;
  pagination?: TablePagination;
  selectable?: TableSelectable;
  toolbarExtra?: React.FC<any>;
  onRow?: (row: GenericObject) => OnRowReturnType;
}

/**
 * @namespace Pages
 * @interface Upload
 */

export enum UploadStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  UPLOADED = 'UPLOADED',
  ANALYZING = 'ANALYZING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  ANALYZED = 'ANALYZED',
}

export interface UploadedProps {
  fileId: string;
  fileName: string;
}

export interface ProgressProps {
  status: string;
  total: number;
  totalAnalysis: number;
  processed: number;
  failed: number;
}

/**
 * @namespace Summary
 * @interface Summary
 */

export interface MappedSummary {
  pending: number;
  inprogress: number;
  active: number;
  denied: number;
  total: number;
}
