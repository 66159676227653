import { Button, Checkbox, Divider, Heading, LoadingBuzz, Paragraph } from '@hexa-ui/components';
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { useToast } from '../../../../hooks/useToast';
import { downloadFromBackend as serviceDownloadFromBackend } from '../../../../services';
import {
  ActionsContainer,
  CheckboxContainer,
  Close,
  DivLoading,
  ModalContent,
  Root,
  TextContainer,
} from './styles';

interface ModalProps {
  trigger: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ trigger }) => {
  const { formatMessage } = useIntl();
  const { toast } = useToast();
  const [isLoading, setLoading] = useState(false);
  const buttonCloseId = useRef(null);

  const { downloadFromBackend } = useRequester();
  const { configs } = useGetUserPreferences();

  const { status: statusConfig, filesConfig } = configs;

  const [checkboxes, setCheckboxes] = useState({
    all: false,
    pending: false,
    approved: false,
    denied: false,
  });

  const onClear = () =>
    setCheckboxes({
      all: false,
      pending: false,
      approved: false,
      denied: false,
    });

  const onChecked = (name = '') => {
    if (name) {
      setCheckboxes((prev) => ({ ...prev, [name]: !prev[name] }));
      return;
    }

    setCheckboxes((prev) => ({
      all: !prev.all,
      pending: !prev.all,
      approved: !prev.all,
      denied: !prev.all,
    }));
  };

  const onDownload = async () => {
    toast.info({ message: 'files.modal.toasts.info' });
    setLoading(true);

    const status = Object.entries(statusConfig).reduce((acc, [key, value]) => {
      if (
        checkboxes['all'] ||
        checkboxes[key] ||
        ((key === 'inprogress' || key === 'active') && checkboxes['approved'])
      ) {
        return [...acc, ...(value as any)];
      }

      return acc;
    }, []);

    const response = await serviceDownloadFromBackend({
      api: { downloadFromBackend },
      params: { status: status.join(','), filesConfig },
    });

    if (!response?.success) {
      onClear();
      setLoading(false);
      /**
       * Future improvement: Discuss with UX error scenario!
       */

      return;
    }

    setLoading(false);

    if (buttonCloseId.current) {
      buttonCloseId.current.click();
    }

    onClear();
  };

  const itemsListMapping = {
    first: 'files.modal.itemsList.first',
    second: 'files.modal.itemsList.second',
    third: 'files.modal.itemsList.third',
  };

  const checkboxesMapping = {
    all: {
      checked: checkboxes.all,
      disabled: false,
      label: 'files.modal.fileDetails.checkboxes.all',
      onChecked: () => onChecked(),
    },
    pending: {
      checked: checkboxes.pending,
      disabled: checkboxes.all,
      label: 'files.modal.fileDetails.checkboxes.pending',
      onChecked: () => onChecked('pending'),
    },
    approved: {
      checked: checkboxes.approved,
      disabled: checkboxes.all,
      label: 'files.modal.fileDetails.checkboxes.approved',
      onChecked: () => onChecked('approved'),
    },
    denied: {
      checked: checkboxes.denied,
      disabled: checkboxes.all,
      label: 'files.modal.fileDetails.checkboxes.denied',
      onChecked: () => onChecked('denied'),
    },
  };

  return (
    <Root
      title={<Heading size="H3">{formatMessage({ id: 'files.modal.title' })}</Heading>}
      trigger={trigger}
      onClose={onClear}
      actions={
        <ActionsContainer>
          <Close>
            <Button id="close-button-loading" style={{ visibility: 'hidden' }} ref={buttonCloseId}>
              <></>
            </Button>
          </Close>
          <Close>
            <Button id="back-button" size="medium" variant="secondary" onClick={onClear}>
              {formatMessage({ id: 'files.modal.buttons.back' })}
            </Button>
          </Close>

          <Button
            id="confirm-button"
            size="medium"
            variant="primary"
            disabled={Object.entries(checkboxes).every(([, value]) => !value) || isLoading}
            onClick={onDownload}
          >
            {formatMessage({ id: 'files.modal.buttons.confirm' })}
          </Button>
        </ActionsContainer>
      }
    >
      <ModalContent>
        {isLoading ? (
          <DivLoading>
            <LoadingBuzz />
          </DivLoading>
        ) : (
          <>
            <Paragraph size="basis">{formatMessage({ id: 'files.modal.description' })}</Paragraph>
            <ul style={{ color: '#0000008c', margin: '0 8px' }}>
              {Object.entries(itemsListMapping).map(([key, value]) => (
                <li key={key}>
                  <Paragraph size="small" colortype="secondary">
                    {formatMessage({ id: value })}
                  </Paragraph>
                </li>
              ))}
            </ul>
            <Divider orientation="horizontal" />
            <TextContainer>
              <Heading size="H4">{formatMessage({ id: 'files.modal.fileDetails.title' })}</Heading>

              <Paragraph size="basis">
                {formatMessage({ id: 'files.modal.fileDetails.description' })}
              </Paragraph>
            </TextContainer>
            <TextContainer>
              <Heading size="H5">
                {formatMessage({ id: 'files.modal.fileDetails.subTitle' })}
              </Heading>

              <Paragraph size="basis">
                {formatMessage({ id: 'files.modal.fileDetails.subDescription' })}
              </Paragraph>
            </TextContainer>
            <CheckboxContainer>
              {Object.entries(checkboxesMapping).map(([key, value]) => (
                <Checkbox
                  key={key}
                  id={key}
                  checked={value.checked}
                  disabled={value.disabled}
                  label={formatMessage({ id: value.label })}
                  onCheckedChange={value.onChecked}
                />
              ))}
            </CheckboxContainer>
          </>
        )}
      </ModalContent>
    </Root>
  );
};
