import { Button } from '@hexa-ui/components';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { useToast } from '../../../../hooks/useToast';
import { UploadStatus } from '../../../../interfaces';
import { applyUpdate, uploadFile } from '../../../../services';
import SegmentService from '../../../../services/segment';
import { useUpload } from '../Context';
import { ButtonsContainer } from './styles';
export const Buttons: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { files, description, uploaded, progress, uploadStarted, processingStarted, onClear } =
    useUpload();

  const { fileId, fileName } = uploaded.value;
  const { status, validated, failed, total } = progress.value;
  const { post } = useRequester();
  const { toast } = useToast();

  const { user, selected } = useGetUserPreferences();

  const file = useMemo(() => {
    const { fileContent: file } = files.value.find((file) => !file.errors.length) || {};

    return file;
  }, [files]);

  const onBack = useCallback(() => {
    if (fileId) {
      SegmentService.paymentsButtonClicked(
        'File Uploader',
        'File Uploader Return Button',
        selected?.vendorId,
        'Credit Management Files Upload Return',
        'CREDIT_MANAGEMENT_FILE_PAGE'
      );
    }
    onClear();
    navigate('/bees-credit-management/files');
  }, [fileId]);

  const onUpload = useCallback(async () => {
    toast.info({
      message: 'pages.upload.buttons.toasts.processing',
      attributes: { fileName },
    });

    uploadStarted.setter(true);

    const response = await uploadFile({
      api: { post },
      params: { userId: user.name, file, fileName: file.name, fileDescription: description.value },
    });

    if (response?.data?.request?.status === 412) {
      uploaded.setter({ fileId: '', fileName: '' });
      toast.warning({
        message: 'files.errors.412.description',
        attributes: { fileName },
      });

      return;
    }

    if (!response?.success) {
      uploaded.setter({ fileId: '', fileName: '' });
      toast.warning({
        message: 'pages.upload.buttons.toasts.error',
        attributes: { fileName },
      });

      return;
    }

    uploaded.setter({ fileId: response.data?.id, fileName: response.data?.reference_name });
    toast.success({
      message: 'pages.upload.buttons.toasts.success',
      attributes: { fileName },
    });

    navigate(
      `/bees-credit-management/upload/progress/${response.data?.id}/${response.data?.reference_name}`
    );
  }, [file, description.value, fileName]);

  const onConfirm = useCallback(async () => {
    if (status === UploadStatus.DONE) {
      onClear();
      navigate('/bees-credit-management/credit');
      return;
    }

    if (status === UploadStatus.FAILED || (status === UploadStatus.ANALYZED && failed === total)) {
      onBack();
      return;
    }

    const response = await applyUpdate({
      api: { post },
      params: { fileId },
    });

    if (!response?.success) {
      toast.warning({
        message: 'pages.upload.buttons.toasts.error',
        attributes: { fileName },
      });

      return;
    }

    processingStarted.setter(true);

    toast.success({
      message: 'pages.upload.buttons.toasts.analysis',
      attributes: { validated: validated },
    });
  }, [fileId, validated, status]);

  const label = useMemo(() => {
    if (status === UploadStatus.ANALYZED) {
      if (failed === total) return 'pages.upload.buttons.goToFiles';

      return 'pages.upload.buttons.apply';
    }

    if (status === UploadStatus.DONE) return 'pages.upload.buttons.goToCredit';

    if (status === UploadStatus.FAILED) return 'pages.upload.buttons.goToFiles';

    return 'pages.upload.buttons.apply';
  }, [status, failed, total]);

  const onConfirmDisabled =
    status !== UploadStatus.ANALYZED &&
    status !== UploadStatus.DONE &&
    status !== UploadStatus.FAILED;

  const showGoBackButton = status !== UploadStatus.DONE && status !== UploadStatus.FAILED;

  return (
    <ButtonsContainer data-testid="buttons-container">
      {showGoBackButton && (
        <Button id="back-button" size="medium" variant="secondary" onClick={onBack}>
          {formatMessage({ id: 'pages.upload.buttons.back' })}
        </Button>
      )}

      {fileId ? (
        <Button
          id="apply-button"
          size="medium"
          variant="primary"
          disabled={onConfirmDisabled}
          onClick={onConfirm}
        >
          {formatMessage({ id: label })}
        </Button>
      ) : (
        <Button
          id="upload-button"
          size="medium"
          variant="primary"
          disabled={!file || uploadStarted.value}
          onClick={onUpload}
        >
          {formatMessage({ id: 'pages.upload.buttons.upload' })}
        </Button>
      )}
    </ButtonsContainer>
  );
};
