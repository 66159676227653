import { DateType, FormatDate, GenericObject } from '../interfaces';

const formatShort = (date: Date, locale: string) =>
  date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  });

const formatTime = (date: Date, locale: string, config: GenericObject) =>
  `${date.toLocaleDateString(locale)} ${date.toLocaleTimeString(locale, {
    timeZone: config.timezone,
    hour12: config.preferredTimeFormat,
    hour: '2-digit',
    minute: '2-digit',
  })}`;

const formatLong = (date: Date, locale: string) =>
  date.toLocaleDateString(locale, {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  });

const formatLongTime = (date: Date, locale: string, config: GenericObject): string => {
  const formattedDate = date.toLocaleDateString(locale, {
    timeZone: config.timezone,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString(locale, {
    timeZone: config.timezone,
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });

  return config.formatMessage(
    { id: 'formatting.datetime.long-time' },
    { date: formattedDate, time: formattedTime }
  );
};

export const format = (config: GenericObject): FormatDate => {
  return (dateString: string, type: DateType): string => {
    const { locale, timezone, preferredTimeFormat, formatMessage } = config;
    const date = new Date(dateString);

    const configs: GenericObject = {
      short: formatShort(date, locale),
      time: formatTime(date, locale, { timezone, preferredTimeFormat }),
      long: formatLong(date, locale),
      'long-time': formatLongTime(date, locale, { timezone, preferredTimeFormat, formatMessage }),
    };

    return configs[type];
  };
};

export const custom = (locale: string) => {
  switch (locale) {
    case 'pt-BR':
      return 'pt';
    case 'en-US':
      return 'en';
    case 'es-419':
      return 'es';
    default:
      return 'en';
  }
};
