import { useCallback, useMemo } from 'react';
import { sidebar as sidebarConstant } from '../constants';
import { FilesConfig, GeneralConfig, MappedConfigs, SideBarItem, Variables } from '../interfaces';
import { useSelected } from '../store/hooks/selectedHook';
import { getUserType } from '../utils/configs';
import { useApplication } from './useApplication';

interface UseConfigProps {
  variables: Variables;
}

export const useConfig = ({ variables }: UseConfigProps) => {
  const { data } = useApplication();
  const { selected } = useSelected();

  const { defaultConfig, countryConfig } = variables;

  const { groups, scopes } = data.authorization;

  const { type, group, userConfig } = getUserType(
    { userMapping: defaultConfig.users.types, groups },
    variables.userConfig
  );

  const permissions = useMemo(() => {
    if (!userConfig.permissions || !defaultConfig.permissions) return [];

    const allPermissions = Object.entries(defaultConfig.permissions).reduce<string[]>(
      (acc, [key, value]: any) => {
        if (scopes.includes(value.key)) {
          acc.push(key);
        }

        return acc;
      },
      []
    );

    return allPermissions.filter((permission: string) =>
      userConfig.permissions.includes(permission)
    );
  }, [scopes, userConfig.permissions]);

  const sidebar = useMemo(() => {
    if (!userConfig.sidebar) return [];

    return Object.entries(sidebarConstant).reduce<SideBarItem[]>((acc, [key, value]) => {
      if (userConfig.sidebar.includes(key)) {
        acc.push(value);
      }

      return acc;
    }, []);
  }, [userConfig.sidebar]);

  const general = useMemo((): GeneralConfig => {
    const { files, pagination, customColumns, ...rest } = defaultConfig.general;
    const { formats, active, accept, size } = files;

    const { output, mimetype, responseType } = formats.find(
      (format: FilesConfig) => format.output === active
    );

    return {
      filesConfig: { output, mimetype, responseType, accept: accept.join(','), size },
      pagination,
      customColumns,
      ...rest,
    };
  }, [defaultConfig.general]);

  const getConfigs = useCallback((): MappedConfigs | null => {
    if (!type || !group || !defaultConfig.users.allowed.includes(`${type}_${group}`)) {
      return null;
    }

    const { status, tables } = userConfig;
    const { currency, ...country } = countryConfig;

    return {
      user: { id: data.userId, name: selected.userName, type, group },
      currency,
      permissions,
      configs: { ...general, ...country, status, tables, sidebar },
    };
  }, [type, group, permissions, sidebar, general, countryConfig, userConfig, selected, data]);

  return { getConfigs };
};
