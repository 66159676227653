import { createContext, useContext, useMemo, useState } from 'react';
import { GetSet } from '../../../../interfaces';

interface CreditContext {
  hasApproval: GetSet<boolean>;
}

const CreditContext = createContext<CreditContext | null>(null);

export const CreditProvider: React.FC = ({ children }) => {
  const [hasApproval, setHasApproval] = useState<boolean>(false);

  const value = useMemo(
    () => ({ hasApproval: { value: hasApproval, setter: setHasApproval } }),
    [hasApproval]
  );

  return <CreditContext.Provider value={value}>{children}</CreditContext.Provider>;
};

export const useCredit = () => useContext(CreditContext);
