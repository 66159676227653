import { Button, Heading, Paragraph } from '@hexa-ui/components';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from '../../../../../hooks/useToast';
import { useViews } from '../../../../../store/hooks/customViewsHook';
import { ViewModalProps } from '../ViewSelector.types';
import { ButtonsContainer, Close, Container, Root } from './styles';

export const Modal: React.FC<ViewModalProps> = ({ table, index, trigger, onClose }) => {
  const { formatMessage } = useIntl();
  const { toast } = useToast();

  const { views } = useViews(table);

  const onDelete = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      views.remove(index);
      toast.success({ message: 'tables.views.card.modal.toasts.success' });

      onClose();
    },
    [index, views.remove]
  );

  return (
    <Container data-testid="modal-delete-view-container" onClick={(e) => e.stopPropagation()}>
      <Root
        title={
          <Heading size="H2">{formatMessage({ id: 'tables.views.card.modal.title' })}</Heading>
        }
        trigger={trigger}
        contentCss={{ minWidth: '570px' }}
        actions={
          <ButtonsContainer>
            <Close>
              <Button size="large" variant="destructive" onClick={onDelete}>
                {formatMessage({ id: 'tables.views.card.modal.buttons.delete' })}
              </Button>
            </Close>

            <Close>
              <Button size="large" variant="secondary">
                {formatMessage({ id: 'tables.views.card.modal.buttons.cancel' })}
              </Button>
            </Close>
          </ButtonsContainer>
        }
      >
        <Paragraph size="basis">
          {formatMessage({ id: 'tables.views.card.modal.description' })}
        </Paragraph>
      </Root>
    </Container>
  );
};
