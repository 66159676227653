import { ChevronLeft, ChevronRight } from '@hexa-ui/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SliderButtons, SliderProps } from './Slider.types';
import { IconButton, SliderContainer, SliderContent } from './styles';

export const Slider: React.FC<SliderProps> = ({ children }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [hasButtons, setHasButtons] = useState<SliderButtons>({
    prev: false,
    next: false,
  });

  const onResize = useCallback(() => {
    const { scrollWidth, clientWidth, scrollLeft } = sliderRef.current;

    const hasPrev = scrollLeft > 0;
    const hasNext = scrollLeft + clientWidth < scrollWidth;

    setHasButtons({ prev: hasPrev, next: hasNext });
  }, []);

  const onScroll = useCallback((direction: 'prev' | 'next') => {
    const { scrollLeft } = sliderRef.current;

    sliderRef.current.scrollTo({
      left: direction === 'prev' ? scrollLeft - 300 : scrollLeft + 300,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    onResize();
  }, []);

  return (
    <SliderContainer data-testid="slider-container">
      {hasButtons.prev && (
        <IconButton
          id="icon-button-prev"
          $direction="prev"
          variant="inherit"
          size="medium"
          icon={ChevronLeft}
          onClick={() => onScroll('prev')}
        />
      )}

      <SliderContent ref={sliderRef} onScroll={onResize} data-testid="slider-content">
        {children}
      </SliderContent>

      {hasButtons.next && (
        <IconButton
          id="icon-button-next"
          $direction="next"
          variant="inherit"
          size="medium"
          icon={ChevronRight}
          onClick={() => onScroll('next')}
        />
      )}
    </SliderContainer>
  );
};
