import { IconButton } from '@hexa-ui/components';
import { Download, Info } from '@hexa-ui/icons';
import React, { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserPreferences } from '../../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../../hooks/useRequester';
import { useToast } from '../../../../../hooks/useToast';
import { FilesConfig } from '../../../../../interfaces';
import { downloadFileFromBackend as serviceDownloadFileFromBackend } from '../../../../../services';
import SegmentService from '../../../../../services/segment';
interface ActionsProps {
  type: 'download-button' | 'progress-button';
  fileId: string;
  fileName: string;
  filesConfig: FilesConfig;
}

export const Actions: React.FC<ActionsProps> = ({ type, fileId, fileName, filesConfig }) => {
  const { downloadFileFromBackend } = useRequester();
  const { toast } = useToast();

  const navigate = useNavigate();
  const { selected } = useGetUserPreferences();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onNavigate = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      navigate(`/bees-credit-management/upload/progress/${fileId}/${fileName}`);
    },
    [fileId]
  );

  const handleClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      setIsLoading(true);
      SegmentService.paymentsButtonClicked(
        'Download Icon',
        'Download Uploaded File Button',
        selected?.vendorId,
        'Credit Management Files Download from Table',
        'CREDIT_MANAGEMENT_FILE_PAGE'
      );

      const response = await serviceDownloadFileFromBackend({
        api: { downloadFileFromBackend },
        params: { fileId, filesConfig },
      });

      if (!response?.success) {
        toast.error({ message: 'errors.500.title' });
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
    },
    [fileId]
  );

  const buttonMapping: { [key: string]: ReactElement } = {
    'download-button': (
      <IconButton
        id="download-button"
        variant="tertiary"
        icon={Download}
        loading={isLoading}
        onClick={handleClick}
        style={{ backgroundColor: '#14141429' }}
      />
    ),
    'progress-button': (
      <IconButton
        id="progress-button"
        variant="tertiary"
        icon={Info}
        onClick={onNavigate}
        style={{ backgroundColor: '#14141429' }}
      />
    ),
  };

  return buttonMapping[type];
};
