import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { Page } from '../Page/Page';
import { Buttons } from './Buttons';
import { UploadProvider } from './Context';
import { Progress } from './Progress';
import { UploadPageContainer } from './styles';
import { Uploader } from './Uploader';

export const UploadPage: React.FC = () => {
  const { pathname } = useLocation();
  const { fileId, fileName } = useParams();

  const { permissions } = useGetUserPreferences();

  const isProgress = useMemo(
    () => pathname.includes('progress') && fileId && fileName,
    [pathname, fileId, fileName]
  );

  if (!permissions.has('actions.upload')) {
    return <Page.Error type="403" />;
  }

  return (
    <UploadProvider fileId={fileId} fileName={fileName}>
      <UploadPageContainer data-testid="upload-coordinator-container">
        {isProgress ? <Progress /> : <Uploader />}

        <Buttons />
      </UploadPageContainer>
    </UploadProvider>
  );
};
