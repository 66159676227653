import { Button, Checkbox, Divider, Heading, LoadingDots, Paragraph } from '@hexa-ui/components';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDownload } from '../../../../hooks/useDownload';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { GetSet } from '../../../../interfaces';
import SegmentService from '../../../../services/segment';
import {
  ActionsContainer,
  CheckboxContainer,
  Close,
  ModalContent,
  Overlay,
  Root,
  TextContainer,
} from './styles';

interface ModalProps {
  open: GetSet<boolean>;
  trigger: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ open, trigger }) => {
  const { formatMessage } = useIntl();

  const { isLoading, getDownload } = useDownload();

  const { configs, selected } = useGetUserPreferences();
  const { status: statusConfig } = configs;

  const [checkboxes, setCheckboxes] = useState({
    all: false,
    pending: false,
    approved: false,
    denied: false,
  });

  const onClear = () => {
    setCheckboxes({
      all: false,
      pending: false,
      approved: false,
      denied: false,
    });
    open.setter(false);
  };

  const closeModal = () => {
    SegmentService.paymentsButtonClicked(
      'Back',
      'Download Close Button',
      selected?.vendorId,
      'Credit Management Files Download Return',
      'CREDIT_MANAGEMENT_FILE_PAGE'
    );

    setCheckboxes({
      all: false,
      pending: false,
      approved: false,
      denied: false,
    });
    open.setter(false);
  };

  const onChecked = (name = '') => {
    if (name) {
      setCheckboxes((prev) => ({ ...prev, [name]: !prev[name] }));
      return;
    }

    setCheckboxes((prev) => ({
      all: !prev.all,
      pending: !prev.all,
      approved: !prev.all,
      denied: !prev.all,
    }));
  };

  function concatLabelsChecked(obj: {
    all: boolean;
    pending: boolean;
    approved: boolean;
    denied: boolean;
  }): string {
    const labels = [];
    for (const key in obj) {
      if (obj[key]) {
        labels.push(key);
      }
    }
    return labels?.join(', ');
  }

  const onDownload = async () => {
    const status = Object.entries(statusConfig).reduce((acc, [key, value]) => {
      if (
        checkboxes['all'] ||
        checkboxes[key] ||
        ((key === 'inprogress' || key === 'active') && checkboxes['approved'])
      ) {
        return [...acc, ...(value as any)];
      }

      return acc;
    }, []);

    SegmentService.paymentsCreditManagementFileDownloadMode(
      'Generate and download file',
      'Generate and download file Button',
      selected?.vendorId,
      'Credit Management Files Download Mode',
      'CREDIT_MANAGEMENT_FILE_PAGE',
      concatLabelsChecked(checkboxes)
    );

    await getDownload(status, onClear);
  };

  const itemsListMapping = {
    first: 'files.modal.itemsList.first',
    second: 'files.modal.itemsList.second',
    third: 'files.modal.itemsList.third',
  };

  const checkboxesMapping = {
    all: {
      checked: checkboxes.all,
      disabled: false,
      label: 'files.modal.fileDetails.checkboxes.all',
      onChecked: () => onChecked(),
    },
    pending: {
      checked: checkboxes.pending,
      disabled: checkboxes.all,
      label: 'files.modal.fileDetails.checkboxes.pending',
      onChecked: () => onChecked('pending'),
    },
    approved: {
      checked: checkboxes.approved,
      disabled: checkboxes.all,
      label: 'files.modal.fileDetails.checkboxes.approved',
      onChecked: () => onChecked('approved'),
    },
    denied: {
      checked: checkboxes.denied,
      disabled: checkboxes.all,
      label: 'files.modal.fileDetails.checkboxes.denied',
      onChecked: () => onChecked('denied'),
    },
  };

  return (
    <Root
      title={<Heading size="H3">{formatMessage({ id: 'files.modal.title' })}</Heading>}
      open={open.value}
      trigger={trigger}
      onClose={closeModal}
      actions={
        <ActionsContainer>
          <Close>
            <Button id="back-button" size="medium" variant="secondary" onClick={closeModal}>
              {formatMessage({ id: 'files.modal.buttons.back' })}
            </Button>
          </Close>

          <Button
            id="confirm-button"
            size="medium"
            variant="primary"
            disabled={Object.entries(checkboxes).every(([, value]) => !value) || isLoading}
            onClick={onDownload}
          >
            {formatMessage({ id: 'files.modal.buttons.confirm' })}
          </Button>
        </ActionsContainer>
      }
    >
      <ModalContent>
        {isLoading && (
          <Overlay data-testid="loading-overlay">
            <LoadingDots />
          </Overlay>
        )}

        <Paragraph size="basis">{formatMessage({ id: 'files.modal.description' })}</Paragraph>

        <ul style={{ color: '#0000008c', margin: '0 8px' }}>
          {Object.entries(itemsListMapping).map(([key, value]) => (
            <li key={key}>
              <Paragraph size="small" colortype="secondary">
                {formatMessage({ id: value })}
              </Paragraph>
            </li>
          ))}
        </ul>

        <Divider orientation="horizontal" />

        <TextContainer>
          <Heading size="H4">{formatMessage({ id: 'files.modal.fileDetails.title' })}</Heading>

          <Paragraph size="basis">
            {formatMessage({ id: 'files.modal.fileDetails.description' })}
          </Paragraph>
        </TextContainer>

        <TextContainer>
          <Heading size="H5">{formatMessage({ id: 'files.modal.fileDetails.subTitle' })}</Heading>

          <Paragraph size="basis">
            {formatMessage({ id: 'files.modal.fileDetails.subDescription' })}
          </Paragraph>
        </TextContainer>

        <CheckboxContainer>
          {Object.entries(checkboxesMapping).map(([key, value]) => (
            <Checkbox
              key={key}
              id={key}
              checked={value.checked}
              disabled={value.disabled}
              label={formatMessage({ id: value.label })}
              onCheckedChange={value.onChecked}
            />
          ))}
        </CheckboxContainer>
      </ModalContent>
    </Root>
  );
};
