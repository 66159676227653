import { LoadingDots, Paragraph } from '@hexa-ui/components';
import { LoadingPageProps } from './LoadingPage.types';
import { LoadingContainer } from './styles';

export const LoadingPage: React.FC<LoadingPageProps> = ({ description = '' }) => {
  return (
    <LoadingContainer data-testid="loading-page-container">
      <LoadingDots />

      {description && <Paragraph size="basis">{description}</Paragraph>}
    </LoadingContainer>
  );
};
