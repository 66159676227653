import React from 'react';

export const GenericError = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      ref={ref}
      {...props}
      data-testid="generic-error-icon"
      width="217"
      height="217"
      viewBox="0 0 217 217"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M111.213 165.123V166.141L179.025 176.312C179.364 176.312 179.703 176.312 180.042 176.312C183.772 176.312 187.163 173.6 187.841 169.531L203.438 63.0656C203.438 62.7266 203.438 62.3875 203.438 62.0484C203.438 58.3187 200.725 54.9281 196.656 54.25L126.809 44.0781L113.586 63.0656L128.166 94.9375L104.77 124.436L115.281 145.797L109.856 166.141L111.213 166.48V165.123L112.23 165.462L117.994 145.458L107.483 124.436L130.878 95.2766L116.298 63.4047L128.166 46.7906L196.317 56.6234C199.03 56.9625 201.064 59.3359 201.064 62.0484C201.064 62.3875 201.064 62.7266 201.064 62.7266L185.806 169.192C185.467 171.905 183.094 173.939 180.381 173.939C180.042 173.939 179.703 173.939 179.703 173.939L111.552 164.106L111.213 165.123Z"
        fill="#DDDCDC"
      />
      <path d="M114.942 64.4223L200.386 76.6285L200.725 74.5941L115.281 62.0488" fill="#DDDCDC" />
      <path d="M16.9531 71.2035L83.4094 66.1176V63.7441L16.9531 69.1691" fill="#DDDCDC" />
      <path
        d="M48.1469 61.709C50.0195 61.709 51.5375 60.1909 51.5375 58.3184C51.5375 56.4458 50.0195 54.9277 48.1469 54.9277C46.2743 54.9277 44.7562 56.4458 44.7562 58.3184C44.7562 60.1909 46.2743 61.709 48.1469 61.709Z"
        fill="#DDDCDC"
      />
      <path
        d="M37.6359 62.3867C39.5085 62.3867 41.0266 60.8687 41.0266 58.9961C41.0266 57.1235 39.5085 55.6055 37.6359 55.6055C35.7634 55.6055 34.2453 57.1235 34.2453 58.9961C34.2453 60.8687 35.7634 62.3867 37.6359 62.3867Z"
        fill="#DDDCDC"
      />
      <path
        d="M27.125 63.4043C28.9976 63.4043 30.5156 61.8863 30.5156 60.0137C30.5156 58.1411 28.9976 56.623 27.125 56.623C25.2524 56.623 23.7344 58.1411 23.7344 60.0137C23.7344 61.8863 25.2524 63.4043 27.125 63.4043Z"
        fill="#DDDCDC"
      />
      <path
        d="M82.3922 65.7773L103.753 92.9023L87.1391 127.148L103.753 145.457L101.38 164.105L31.5328 169.87H31.1938C28.4813 169.87 26.1078 167.835 25.7688 164.784L17.2922 57.3008V56.9617C17.2922 54.2492 19.3266 51.8758 22.3781 51.5367L89.8516 46.1117L82.3922 64.4211L84.4266 65.4383L93.2422 43.7383L22.0391 49.5023C17.9703 49.8414 14.9188 53.232 14.9188 57.3008C14.9188 57.6398 14.9188 57.6398 14.9188 57.9789L23.3953 165.462C23.7344 168.852 27.125 171.904 31.1938 171.904C31.5328 171.904 31.5328 171.904 31.8719 171.904L103.075 166.14L105.788 144.44L89.5125 126.47L106.127 92.2242L84.0875 64.082L82.3922 65.7773Z"
        fill="#DDDCDC"
      />
      <path
        d="M80.6969 130.2L104.092 90.8687C106.127 87.8172 110.534 87.8172 112.23 90.8687L135.625 130.2C137.659 133.591 135.286 137.659 131.556 137.659H84.7657C81.036 137.32 78.6625 133.252 80.6969 130.2Z"
        fill="#46406D"
      />
      <path
        d="M79.6797 127.827L102.058 90.191C103.753 87.1395 108.161 87.1395 109.856 90.191L132.234 127.827C133.93 130.879 131.895 134.947 128.166 134.947H83.4094C80.0187 134.947 77.6453 130.879 79.6797 127.827Z"
        fill="#7C74B1"
      />
      <path
        d="M102.736 125.454C102.736 124.436 103.075 123.419 103.753 123.08C104.431 122.402 105.109 122.063 106.466 122.063C107.483 122.063 108.5 122.402 109.178 123.08C109.856 123.758 110.195 124.436 110.195 125.454C110.195 126.471 109.856 127.488 109.178 127.827C108.5 128.505 107.822 128.844 106.805 128.844C105.787 128.844 104.77 128.505 104.092 127.827C103.414 127.149 102.736 126.81 102.736 125.454ZM108.839 118.672H104.092L103.075 99.3457H109.856L108.839 118.672Z"
        fill="#F0E44C"
      />
    </svg>
  )
);
