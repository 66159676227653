import { Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { useCreditHistoryTitle } from '../../../../../../hooks/useCreditHistoryTitle';
import { useFormatting } from '../../../../../../hooks/useFormatting';
import { useGetUserPreferences } from '../../../../../../hooks/useGetUserPreferences';
import { Content, Subheader } from '../../../styles';
import { CreditHistoryItemProps } from '../CreditHistory.types';
import { DetailsContent, IconContainer, InformationContainer, InformationContent } from './styles';

export const CreditInformation: React.FC<CreditHistoryItemProps> = ({ history }) => {
  const { formatMessage } = useIntl();

  const { formatting } = useFormatting();
  const { datetime } = useGetUserPreferences();
  const { text, icon, backgroundColor } = useCreditHistoryTitle(history.status.toUpperCase());

  return (
    <InformationContainer data-testid="information-container">
      <Paragraph size="small" colortype="disabled">
        {datetime.format(history.date, 'long')}
      </Paragraph>

      <InformationContent>
        <IconContainer $backgroundcolor={backgroundColor}>{icon}</IconContainer>

        <DetailsContent>
          <Subheader type="large">{text}</Subheader>

          <Content size="basis">
            {formatMessage(
              { id: 'pages.poc-details.history.credit.information.resume' },
              {
                limit: formatting({ type: 'currency', value: history.creditLimit }).formatted
                  .completed,
                term: formatting({ type: 'days', value: history.termFee.term }).formatted.completed,
                fee: formatting({ type: 'fee', value: history.termFee.fee }).formatted.completed,
              }
            )}
          </Content>

          {history.identifier && (
            <Content size="basis">
              {formatMessage(
                { id: 'pages.poc-details.history.credit.information.by' },
                { identifier: history.identifier }
              )}
            </Content>
          )}
        </DetailsContent>
      </InformationContent>
    </InformationContainer>
  );
};
