import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { AppHeader } from './components/organisms/AppHeader/AppHeader';
import {
  AppProvider,
  CustomViewsProvider,
  HeaderProvider,
  OptimizelyProvider,
  OptionsProvider,
  SourceProvider,
} from './providers';
import { Router } from './routes/Router';
import { persistor, store } from './store/store';

export default function App() {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-bees-fintech-credit-mfe',
    seed: 'bees-hub-bees-fintech-credit-mfe',
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppProvider>
            <BrowserRouter>
              <HeaderProvider>
                <React.Fragment>
                  <AppHeader />

                  <OptionsProvider>
                    <OptimizelyProvider>
                      <SourceProvider>
                        <CustomViewsProvider>
                          <Router />
                        </CustomViewsProvider>
                      </SourceProvider>
                    </OptimizelyProvider>
                  </OptionsProvider>
                </React.Fragment>
              </HeaderProvider>
            </BrowserRouter>
          </AppProvider>
        </PersistGate>
      </Provider>
    </StylesProvider>
  );
}
