export default [
  {
    cc: 'BA',
    cd: 'BAM',
  },
  {
    cc: 'BB',
    cd: 'BBD',
  },
  {
    cc: 'BD',
    cd: 'BDT',
  },
  {
    cc: 'BE',
    cd: 'EUR',
  },
  {
    cc: 'BF',
    cd: 'XOF',
  },
  {
    cc: 'BG',
    cd: 'BGN',
  },
  {
    cc: 'BH',
    cd: 'BHD',
  },
  {
    cc: 'BI',
    cd: 'BIF',
  },
  {
    cc: 'BJ',
    cd: 'XOF',
  },
  {
    cc: 'BL',
    cd: 'EUR',
  },
  {
    cc: 'BM',
    cd: 'BMD',
  },
  {
    cc: 'BN',
    cd: 'BND',
  },
  {
    cc: 'BO',
    cd: 'BOB',
  },
  {
    cc: 'BQ',
    cd: 'USD',
  },
  {
    cc: 'BR',
    cd: 'BRL',
  },
  {
    cc: 'BS',
    cd: 'BSD',
  },
  {
    cc: 'BT',
    cd: 'BTN',
  },
  {
    cc: 'BV',
    cd: 'NOK',
  },
  {
    cc: 'BW',
    cd: 'BWP',
  },
  {
    cc: 'BY',
    cd: 'BYR',
  },
  {
    cc: 'BZ',
    cd: 'BZD',
  },
  {
    cc: 'CA',
    cd: 'CAD',
  },
  {
    cc: 'CC',
    cd: 'AUD',
  },
  {
    cc: 'CD',
    cd: 'CDF',
  },
  {
    cc: 'CF',
    cd: 'XAF',
  },
  {
    cc: 'CG',
    cd: 'XAF',
  },
  {
    cc: 'CH',
    cd: 'CHF',
  },
  {
    cc: 'CI',
    cd: 'XOF',
  },
  {
    cc: 'CK',
    cd: 'NZD',
  },
  {
    cc: 'CL',
    cd: 'CLP',
  },
  {
    cc: 'CM',
    cd: 'XAF',
  },
  {
    cc: 'CN',
    cd: 'CNY',
  },
  {
    cc: 'CO',
    cd: 'COP',
  },
  {
    cc: 'CR',
    cd: 'CRC',
  },
  {
    cc: 'CU',
    cd: 'CUP',
  },
  {
    cc: 'CV',
    cd: 'CVE',
  },
  {
    cc: 'CW',
    cd: 'ANG',
  },
  {
    cc: 'CX',
    cd: 'AUD',
  },
  {
    cc: 'CY',
    cd: 'EUR',
  },
  {
    cc: 'CZ',
    cd: 'CZK',
  },
  {
    cc: 'DE',
    cd: 'EUR',
  },
  {
    cc: 'DJ',
    cd: 'DJF',
  },
  {
    cc: 'DK',
    cd: 'DKK',
  },
  {
    cc: 'DM',
    cd: 'XCD',
  },
  {
    cc: 'DO',
    cd: 'DOP',
  },
  {
    cc: 'DZ',
    cd: 'DZD',
  },
  {
    cc: 'EC',
    cd: 'USD',
  },
  {
    cc: 'EE',
    cd: 'EUR',
  },
  {
    cc: 'EG',
    cd: 'EGP',
  },
  {
    cc: 'EH',
    cd: 'MAD',
  },
  {
    cc: 'ER',
    cd: 'ERN',
  },
  {
    cc: 'ES',
    cd: 'EUR',
  },
  {
    cc: 'ET',
    cd: 'ETB',
  },
  {
    cc: 'FI',
    cd: 'EUR',
  },
  {
    cc: 'FJ',
    cd: 'FJD',
  },
  {
    cc: 'FK',
    cd: 'FKP',
  },
  {
    cc: 'FM',
    cd: 'USD',
  },
  {
    cc: 'FO',
    cd: 'DKK',
  },
  {
    cc: 'FR',
    cd: 'EUR',
  },
  {
    cc: 'GA',
    cd: 'XAF',
  },
  {
    cc: 'GB',
    cd: 'GBP',
  },
  {
    cc: 'GD',
    cd: 'XCD',
  },
  {
    cc: 'GE',
    cd: 'GEL',
  },
  {
    cc: 'GF',
    cd: 'EUR',
  },
  {
    cc: 'GG',
    cd: 'GBP',
  },
  {
    cc: 'GH',
    cd: 'GHS',
  },
  {
    cc: 'GI',
    cd: 'GIP',
  },
  {
    cc: 'GL',
    cd: 'DKK',
  },
  {
    cc: 'GM',
    cd: 'GMD',
  },
  {
    cc: 'GN',
    cd: 'GNF',
  },
  {
    cc: 'GP',
    cd: 'EUR',
  },
  {
    cc: 'GQ',
    cd: 'XAF',
  },
  {
    cc: 'GR',
    cd: 'EUR',
  },
  {
    cc: 'GS',
    cd: 'GBP',
  },
  {
    cc: 'GT',
    cd: 'GTQ',
  },
  {
    cc: 'GU',
    cd: 'USD',
  },
  {
    cc: 'GW',
    cd: 'XOF',
  },
  {
    cc: 'GY',
    cd: 'GYD',
  },
  {
    cc: 'HK',
    cd: 'HKD',
  },
  {
    cc: 'HM',
    cd: 'AUD',
  },
  {
    cc: 'HN',
    cd: 'HNL',
  },
  {
    cc: 'HR',
    cd: 'HRK',
  },
  {
    cc: 'HT',
    cd: 'HTG',
  },
  {
    cc: 'HU',
    cd: 'HUF',
  },
  {
    cc: 'ID',
    cd: 'IDR',
  },
  {
    cc: 'IE',
    cd: 'EUR',
  },
  {
    cc: 'IL',
    cd: 'ILS',
  },
  {
    cc: 'IM',
    cd: 'GBP',
  },
  {
    cc: 'IN',
    cd: 'INR',
  },
  {
    cc: 'IO',
    cd: 'USD',
  },
  {
    cc: 'IQ',
    cd: 'IQD',
  },
  {
    cc: 'IR',
    cd: 'IRR',
  },
  {
    cc: 'IS',
    cd: 'ISK',
  },
  {
    cc: 'IT',
    cd: 'EUR',
  },
  {
    cc: 'JE',
    cd: 'GBP',
  },
  {
    cc: 'JM',
    cd: 'JMD',
  },
  {
    cc: 'JO',
    cd: 'JOD',
  },
  {
    cc: 'JP',
    cd: 'JPY',
  },
  {
    cc: 'KE',
    cd: 'KES',
  },
  {
    cc: 'KG',
    cd: 'KGS',
  },
  {
    cc: 'KH',
    cd: 'KHR',
  },
  {
    cc: 'KI',
    cd: 'AUD',
  },
  {
    cc: 'KM',
    cd: 'KMF',
  },
  {
    cc: 'KN',
    cd: 'XCD',
  },
  {
    cc: 'KP',
    cd: 'KPW',
  },
  {
    cc: 'KR',
    cd: 'KRW',
  },
  {
    cc: 'KW',
    cd: 'KWD',
  },
  {
    cc: 'KY',
    cd: 'KYD',
  },
  {
    cc: 'KZ',
    cd: 'KZT',
  },
  {
    cc: 'LA',
    cd: 'LAK',
  },
  {
    cc: 'LB',
    cd: 'LBP',
  },
  {
    cc: 'LC',
    cd: 'XCD',
  },
  {
    cc: 'LI',
    cd: 'CHF',
  },
  {
    cc: 'LK',
    cd: 'LKR',
  },
  {
    cc: 'LR',
    cd: 'LRD',
  },
  {
    cc: 'LS',
    cd: 'LSL',
  },
  {
    cc: 'LT',
    cd: 'EUR',
  },
  {
    cc: 'LU',
    cd: 'EUR',
  },
  {
    cc: 'LV',
    cd: 'EUR',
  },
  {
    cc: 'LY',
    cd: 'LYD',
  },
  {
    cc: 'MA',
    cd: 'MAD',
  },
  {
    cc: 'MC',
    cd: 'EUR',
  },
  {
    cc: 'MD',
    cd: 'MDL',
  },
  {
    cc: 'ME',
    cd: 'EUR',
  },
  {
    cc: 'MF',
    cd: 'EUR',
  },
  {
    cc: 'MG',
    cd: 'MGA',
  },
  {
    cc: 'MH',
    cd: 'USD',
  },
  {
    cc: 'MK',
    cd: 'MKD',
  },
  {
    cc: 'ML',
    cd: 'XOF',
  },
  {
    cc: 'MM',
    cd: 'MMK',
  },
  {
    cc: 'MN',
    cd: 'MNT',
  },
  {
    cc: 'MO',
    cd: 'MOP',
  },
  {
    cc: 'MP',
    cd: 'USD',
  },
  {
    cc: 'MQ',
    cd: 'EUR',
  },
  {
    cc: 'MR',
    cd: 'MRO',
  },
  {
    cc: 'MS',
    cd: 'XCD',
  },
  {
    cc: 'MT',
    cd: 'EUR',
  },
  {
    cc: 'MU',
    cd: 'MUR',
  },
  {
    cc: 'MV',
    cd: 'MVR',
  },
  {
    cc: 'MW',
    cd: 'MWK',
  },
  {
    cc: 'MX',
    cd: 'MXN',
  },
  {
    cc: 'MY',
    cd: 'MYR',
  },
  {
    cc: 'MZ',
    cd: 'MZN',
  },
  {
    cc: 'NA',
    cd: 'NAD',
  },
  {
    cc: 'NC',
    cd: 'XPF',
  },
  {
    cc: 'NE',
    cd: 'XOF',
  },
  {
    cc: 'NF',
    cd: 'AUD',
  },
  {
    cc: 'NG',
    cd: 'NGN',
  },
  {
    cc: 'NI',
    cd: 'NIO',
  },
  {
    cc: 'NL',
    cd: 'EUR',
  },
  {
    cc: 'NO',
    cd: 'NOK',
  },
  {
    cc: 'NP',
    cd: 'NPR',
  },
  {
    cc: 'NR',
    cd: 'AUD',
  },
  {
    cc: 'NU',
    cd: 'NZD',
  },
  {
    cc: 'NZ',
    cd: 'NZD',
  },
  {
    cc: 'OM',
    cd: 'OMR',
  },
  {
    cc: 'PA',
    cd: 'PAB',
  },
  {
    cc: 'PE',
    cd: 'PEN',
  },
  {
    cc: 'PF',
    cd: 'XPF',
  },
  {
    cc: 'PG',
    cd: 'PGK',
  },
  {
    cc: 'PH',
    cd: 'PHP',
  },
  {
    cc: 'PK',
    cd: 'PKR',
  },
  {
    cc: 'PL',
    cd: 'PLN',
  },
  {
    cc: 'PM',
    cd: 'EUR',
  },
  {
    cc: 'PN',
    cd: 'NZD',
  },
  {
    cc: 'PR',
    cd: 'USD',
  },
  {
    cc: 'PS',
    cd: 'ILS',
  },
  {
    cc: 'PT',
    cd: 'EUR',
  },
  {
    cc: 'PW',
    cd: 'USD',
  },
  {
    cc: 'PY',
    cd: 'PYG',
  },
  {
    cc: 'QA',
    cd: 'QAR',
  },
  {
    cc: 'RE',
    cd: 'EUR',
  },
  {
    cc: 'RO',
    cd: 'RON',
  },
  {
    cc: 'RS',
    cd: 'RSD',
  },
  {
    cc: 'RU',
    cd: 'RUB',
  },
  {
    cc: 'RW',
    cd: 'RWF',
  },
  {
    cc: 'SA',
    cd: 'SAR',
  },
  {
    cc: 'SB',
    cd: 'SBD',
  },
  {
    cc: 'SC',
    cd: 'SCR',
  },
  {
    cc: 'SD',
    cd: 'SDG',
  },
  {
    cc: 'SE',
    cd: 'SEK',
  },
  {
    cc: 'SG',
    cd: 'SGD',
  },
  {
    cc: 'SH',
    cd: 'SHP',
  },
  {
    cc: 'SI',
    cd: 'EUR',
  },
  {
    cc: 'SJ',
    cd: 'NOK',
  },
  {
    cc: 'SK',
    cd: 'EUR',
  },
  {
    cc: 'SL',
    cd: 'SLL',
  },
  {
    cc: 'SM',
    cd: 'EUR',
  },
  {
    cc: 'SN',
    cd: 'XOF',
  },
  {
    cc: 'SO',
    cd: 'SOS',
  },
  {
    cc: 'SR',
    cd: 'SRD',
  },
  {
    cc: 'SS',
    cd: 'SSP',
  },
  {
    cc: 'ST',
    cd: 'STD',
  },
  {
    cc: 'SV',
    cd: 'USD',
  },
  {
    cc: 'SX',
    cd: 'ANG',
  },
  {
    cc: 'SY',
    cd: 'SYP',
  },
  {
    cc: 'SZ',
    cd: 'SZL',
  },
  {
    cc: 'TC',
    cd: 'USD',
  },
  {
    cc: 'TD',
    cd: 'XAF',
  },
  {
    cc: 'TF',
    cd: 'EUR',
  },
  {
    cc: 'TG',
    cd: 'XOF',
  },
  {
    cc: 'TH',
    cd: 'THB',
  },
  {
    cc: 'TJ',
    cd: 'TJS',
  },
  {
    cc: 'TK',
    cd: 'NZD',
  },
  {
    cc: 'TL',
    cd: 'USD',
  },
  {
    cc: 'TM',
    cd: 'TMT',
  },
  {
    cc: 'TN',
    cd: 'TND',
  },
  {
    cc: 'TO',
    cd: 'TOP',
  },
  {
    cc: 'TR',
    cd: 'TRY',
  },
  {
    cc: 'TT',
    cd: 'TTD',
  },
  {
    cc: 'TV',
    cd: 'AUD',
  },
  {
    cc: 'TW',
    cd: 'TWD',
  },
  {
    cc: 'TZ',
    cd: 'TZS',
  },
  {
    cc: 'UA',
    cd: 'UAH',
  },
  {
    cc: 'UG',
    cd: 'UGX',
  },
  {
    cc: 'UM',
    cd: 'USD',
  },
  {
    cc: 'US',
    cd: 'USD',
  },
  {
    cc: 'UY',
    cd: 'UYU',
  },
  {
    cc: 'UZ',
    cd: 'UZS',
  },
  {
    cc: 'VA',
    cd: 'EUR',
  },
  {
    cc: 'VC',
    cd: 'XCD',
  },
  {
    cc: 'VE',
    cd: 'VEF',
  },
  {
    cc: 'VG',
    cd: 'USD',
  },
  {
    cc: 'VI',
    cd: 'USD',
  },
  {
    cc: 'VN',
    cd: 'VND',
  },
  {
    cc: 'VU',
    cd: 'VUV',
  },
  {
    cc: 'WF',
    cd: 'XPF',
  },
  {
    cc: 'WS',
    cd: 'WST',
  },
  {
    cc: 'XK',
    cd: 'EUR',
  },
  {
    cc: 'YE',
    cd: 'YER',
  },
  {
    cc: 'YT',
    cd: 'EUR',
  },
  {
    cc: 'ZA',
    cd: 'ZAR',
  },
  {
    cc: 'ZM',
    cd: 'ZMW',
  },
  {
    cc: 'ZW',
    cd: 'ZWL',
  },
];
