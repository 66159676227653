import { Card, ListItem } from '@hexa-ui/components';
import styled, { css } from 'styled-components';

type Direction = 'row' | 'column';

export const FilterCardContainer = styled(Card)`
  position: absolute;
  z-index: 20;
`;

export const FilterContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
`;

export const Menu = styled.div`
  width: 250px;
  min-height: 250px;

  border-right: 1px solid rgba(0, 0, 0, 0.15);
`;

export const List = styled.ul`
  padding: 0;
  margin: 16px 0 0 0;

  list-style: none;
`;

export const Item = styled(ListItem)<{ $active: boolean }>`
  width: 100%;
  height: 56px;

  padding: 0;
  margin: 0;

  ${({ $active }) => {
    if ($active) {
      return css`
        background: #f6f2ff;
        font-weight: 600;
        font-size: 16px;
      `;
    }
  }}

  :nth-child(2) > svg {
    margin-right: 12px;
  }
`;

export const ItemContent = styled.div<{ $hascounter: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $hascounter }) => ($hascounter ? 'space-between' : 'flex-start')};
  flex-direction: row;

  width: 100%;
  height: 100%;

  padding: 0 0 0 16px;
`;

export const FilterOptionsContainer = styled.div<{ $direction: Direction }>`
  display: flex;
  ${({ $direction }) => {
    if ($direction === 'column') {
      return css`
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        row-gap: 12px;
      `;
    }

    return css`
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;

      column-gap: 12px;
    `;
  }};

  min-width: 460px;
  width: calc(100% - 250px);
  padding: 16px;
`;

export const FilterFooter = styled.div<{ $hasfiltered: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $hasfiltered }) => ($hasfiltered ? 'space-between' : 'flex-end')};
  flex-direction: row;

  padding: 16px;
`;

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  column-gap: 8px;
`;
