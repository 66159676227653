import { Card } from '@hexa-ui/components';
import styled from 'styled-components';

export const SummaryContainer = styled(Card)<{ $isloading: boolean }>`
  display: flex;
  align-items: ${({ $isloading }) => ($isloading ? 'center' : 'flex-start')};
  justify-content: ${({ $isloading }) => ($isloading ? 'center' : 'flex-start')};
  flex-direction: row;

  min-height: 86px;
  width: 100%;
  padding: 24px;
  column-gap: 24px;
`;

export const SummaryItem = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  height: 100% !important;
`;
