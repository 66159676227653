import { ServicesProps, ServicesResponse } from '../../interfaces/services';

const service = {
  getSource: async ({ api }: ServicesProps): Promise<ServicesResponse | null> => {
    try {
      const response = await api.get({
        path: '/v1/source',
      });

      if (!response?.data) return null;

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: error };
    }
  },
};

export const { getSource } = service;
