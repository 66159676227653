import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { actions as ActionsMapping } from '../../../../constants';
import { useGetTables } from '../../../../hooks/useGetTables';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { FileStatus, GenericResponse, Tables } from '../../../../interfaces';
import { getFiles } from '../../../../services';
import { Table } from '../../../atoms/Table/Table';
import { FileButtons } from '../../../molecules/FileButtons/FileButtons';
import SegmentService from '../../../../services/segment';

export const FilesTable: React.FC = () => {
  const { formatMessage } = useIntl();
  const { get } = useRequester();

  const navigate = useNavigate();

  const { columns } = useGetTables(Tables.FILES);
  const { configs, selected } = useGetUserPreferences();
  const { filesConfig } = configs;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [files, setFiles] = useState({ value: [], filtered: [] });
  const [search, setSearch] = useState<string>('');

  const onSearch = async (value: string) => {
    setSearch(value);

    setFiles((prev) => ({
      ...prev,
      filtered: prev.value.filter((item) =>
        item.fileName.toLowerCase().includes(value.toLowerCase())
      ),
    }));
  };

  const getType = useCallback(
    (status: FileStatus) => {
      return Object.entries(ActionsMapping[Tables.FILES]).reduce((acc, [key, value]) => {
        if (value.includes(status)) {
          return key;
        }

        return acc;
      }, '');
    },
    [ActionsMapping]
  );

  const onRow = (row: GenericResponse) => {
    if (!row?.status || getType(row.status) === 'download-button') return null;

    navigate(`/bees-credit-management/upload/progress/${row.fileId}/${row.fileName}`);
  };

  const getData = async () => {
    setIsLoading(true);
    const response = await getFiles({ api: { get } });

    if (!response?.success) {
      setFiles({ value: [], filtered: [] });
      setIsLoading(false);
      return;
    }

    const fileHistory = response.data?.map((item: GenericResponse) => ({
      status: item.status,
      fileId: item.id,
      fileName: item.reference_name,
      fileDescription: item.description,
      total: String(item.total_count),
      processed: String(item.processed_count),
      uploadedBy: item.uploaded_by,
      uploadedAt: { value: item.updated_at, type: 'long-time' },
      actions: {
        type: getType(item.status),
        fileId: item.id,
        fileName: item.reference_name,
        filesConfig,
      },
    }));

    setFiles({ value: fileHistory, filtered: fileHistory });
    setIsLoading(false);
  };

  useEffect(() => {
    getData();

    SegmentService.paymentsPageViewed(
      selected?.vendorId,
      'Credit Management File Access',
      'CREDIT_MANAGEMENT_FILE_PAGE'
    );
  }, []);

  return (
    <Table
      loading={isLoading}
      columns={columns}
      data={files.filtered}
      toolbarExtra={FileButtons}
      onRow={onRow}
      search={{
        has: true,
        width: 320,
        placeholder: formatMessage({ id: 'tables.files.search' }),
        value: search,
        onChange: onSearch,
      }}
      messages={{
        loading: formatMessage({ id: 'tables.loading' }),
        empty: { message: formatMessage({ id: 'tables.files.empty' }) },
      }}
    />
  );
};
