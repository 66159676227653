import styled from 'styled-components';

export const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  row-gap: 16px;
`;

export const ItemContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;

  width: 100%;
  column-gap: 16px;
`;

export const DetailsContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  width: 100%;
  column-gap: 8px;
`;

export const IconContainer = styled.div<{ $backgroundcolor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  width: 24px;
  border-radius: 50%;

  background-color: ${(props) => props.$backgroundcolor};

  svg {
    width: 24px;
  }
`;
