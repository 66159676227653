import { Locales } from '../../interfaces';

const ptBR: Locales = {
  countries: {
    AQ: 'Antártica',
    AR: 'Argentina',
    BB: 'Barbados',
    BE: 'Bélgica',
    BO: 'Bolívia',
    BR: 'Brasil',
    CA: 'Canadá',
    IC: 'Ilhas Canárias',
    CL: 'Chile',
    CO: 'Colômbia',
    CZ: 'República Tcheca',
    DO: 'República Dominicana',
    EC: 'Equador',
    SV: 'El Salvador',
    DE: 'Alemanha',
    HN: 'Honduras',
    IN: 'Índia',
    ID: 'Indonésia',
    MX: 'México',
    NL: 'Países Baixos',
    NG: 'Nigéria',
    PA: 'Panamá',
    PY: 'Paraguai',
    PE: 'Peru',
    PH: 'Filipinas',
    ZA: 'África do Sul',
    KR: 'Coreia do Sul',
    TZ: 'Tanzânia',
    TH: 'Tailândia',
  },
  select: {
    country: 'Selecione um país',
    vendor: 'Selecione um fornecedor',
  },
  routes: {
    credit: 'Crédito',
    upload: 'Carregar arquivo',
    progress: 'Progresso do arquivo',
    pocDetails: 'Detalhes do PDV',
    files: 'Arquivos PDVs',
    views: 'Visualização do cliente',
  },
  pages: {
    credit: {
      title: 'Gestão de crédito',
      summary: {
        total: 'Total de PDVs',
        pending: 'Aprovações pendentes',
        inprogress: 'Em andamento',
        active: 'Crédito ativo',
        denied: 'Crédito negado',
      },
    },
    files: {
      title: 'Arquivos PDVs',
    },
    upload: {
      title: 'Carregar arquivo',
      uploader: {
        title: 'Envio de arquivo',
        description: `
          As informações de POCs existente serão atualizadas de acordo com o arquivo carregado. Novas POCs adicionados ao arquivo não serão incluídos na lista de POCs.

          O upload pode levar algum tempo para ser processado. Assim que o upload for concluído, o usuário pode sair da página e retornar posteriormente clicando no arquivo na página Arquivos.
        `,
        fileUploader: {
          label: 'Arraste ou navegue pelo seu arquivo',
          description:
            'Arraste e solte o arquivo aqui para fazer o envio. O formato do arquivo deve ser XLSX.',
        },
        fileDescription: {
          title: 'Descrição do arquivo',
          subTitle: '(opcional)',
          description: 'Facilite a identificação desse arquivo apresentando alguns detalhes.',
        },
      },
      buttons: {
        back: 'Voltar',
        upload: 'Carregar arquivo',
        apply: 'Atualizar PDVs',
        goToCredit: 'Ir para crédito',
        goToFiles: 'Ir para arquivos',
        toasts: {
          analysis: '{ validated } PDVs foram atualizados com sucesso.',
          processing: 'Processando arquivo. { fileName }',
          success: 'Arquivo enviado com sucesso! { fileName }',
          warning: 'Arquivo enviado com erros. { fileName }',
          error: 'Erro de envio. { fileName }',
        },
      },
      progress: {
        title: 'Análise do arquivo carregado',
        processingTitle: 'Atualização das condições e status do crédito',
        meter: {
          starting: 'Iniciando',
          progress: '{ progressValue } de { progressMaxValue } PDVs processados.',
          description: 'O tempo de análise pode ser maior quando o arquivo contém muitos PDVs.',
          descriptionProgressing:
            'O tempo de processamento pode ser maior quando há muitos POCs a serem atualizados.',
          error: 'O arquivo não contém PDVs válidos. Revise o arquivo e carregue-o novamente.',
          slow: 'O carregamento está demorando mais do que o normal. Aguarde um momento.',
        },
        finished: {
          pocs: {
            success: 'PDVs prontos para serem adicionados',
            error: 'PDVs com erro',
            tooltip:
              'Só é possível aumentar o limite de crédito para 200% do valor sugerido, e o prazo máximo permitido é de 30 dias.',
          },
          alert:
            'Baixe a lista de erros e carregue um novo arquivo corrigido na etapa anterior ou prossiga apenas com os PDVs prontos para serem adicionados.',
          download: 'Baixar lista de erros',
        },
        error: {
          analysis: 'Não foi possível concluir a análise do arquivo. Carregue o arquivo novamente.',
          processing: 'Não foi possível concluir a atualização. Carregue o arquivo novamente.',
        },
      },
    },
    'poc-details': {
      recommendation: {
        title: 'Recomendação de linha de crédito',
        status: {
          pending: 'Pendente',
          denied: 'Negado',
          waitingActivation: 'Aguardando ativação',
          waitingOnboarding: 'Aguardando onboarding',
        },
        labels: {
          creditLimit: 'Limite de crédito',
          termFee: 'Prazo/taxa',
          recommendation: 'Recomendação',
          updatedOn: 'Atualizado em',
          approvedBy: 'Editado por',
          approvalMethod: 'Método de edição',
        },
        timeline: {
          title: 'Cronograma de recomendação',
        },
      },
      information: {
        title: 'Informações do PDV',
        labels: {
          pocName: '​Nome',
          pocDocument: 'ID',
          pocAddress: 'Endereço',
          pocEmail: 'E-mail',
          pocPhoneNumber: 'Telefone',
        },
      },
      conditions: {
        title: 'Condições atuais da linha de crédito',
        status: {
          active: 'Ativo',
          blocked: 'Bloqueado',
          overdue: 'Atrasado',
          due: 'Vencimento',
        },
        labels: {
          creditLimit: 'Limite de crédito',
          limitAvailable: 'Limite disponível',
          balance: 'Utilizado',
          overdue: 'Atrasado',
          termFee: 'Prazo/taxa',
          paymentMethod: 'Método de pagamento',
          activatedOn: 'Ativado em',
          approvedBy: 'Aprovado por',
          approvalMethod: 'Método de aprovação',
          fileName: 'Nome do arquivo',
          fileDescription: 'Descrição do arquivo',
        },
      },
      history: {
        credit: {
          title: 'Histórico de crédito',
          description: 'Todas as alterações de status feitas nas condições de crédito do PDV.',
          buttons: { trigger: 'Mostrar histórico de crédito' },
          information: {
            resume: '{ limit } - { term } - { fee }',
            by: 'Por { identifier }',
          },
          labels: {
            byAndHour: 'por { identifier } em { hour }',
            creditLimit: 'Limite de crédito:',
            termFee: 'Prazo/taxa:',
            method: {
              edit: 'Editar método:',
              approval: 'Método de aprovação:',
              denial: 'Método de negação:',
            },
          },
          status: {
            newCreditRecommendation: 'Nova recomendação de crédito',
            editedSuggestion: 'Sugestão de crédito editada',
            creditConditionsEdited: 'Condições atuais de crédito editada',
            approvedRecommendation: 'Recomendação de crédito aprovada',
            creditLineBlocked: 'Linha de crédito bloqueada por falta de pagamento',
            creditLineUnblocked: 'Linha de crédito desbloqueada',
            creditLineActivated: 'Linha de crédito ativada',
            recommendationDenied: 'Recomendação de crédito negada',
          },
        },
      },
    },
    views: {
      title: 'Visualização do cliente',
      description:
        'Crie visualizações personalizadas na tabela para atender aos seus requisitos de análise.',
      buttons: { back: 'Voltar', save: 'Salvar e aplicar' },
      toasts: {
        warning: 'Você pode adicionar no máximo 6 colunas a uma tabela.',
        success: 'A visualização { name } foi salva e aplicada à tabela.',
      },
      inputs: {
        name: {
          label: 'Exibir nome',
          placeholder: 'Insira um nome',
        },
        type: {
          label: 'Tipo de visualização',
          options: {
            RECOMMENDED: {
              label: 'Recomendações de crédito',
              tooltip: 'PDVs marcados como bons candidatos para crédito.',
            },
            NOT_RECOMMENDED: {
              label: 'PDVs sem recomendação de crédito',
              tooltip: 'PDVs não marcados como bons candidatos para crédito.',
            },
          },
        },
      },
      columns: {
        select: { description: 'Selecione até 6 colunas para exibir na tabela.' },
        order: {
          description: 'Classifique as colunas selecionadas com base na ordem de sua preferência.',
        },
      },
    },
  },
  formatting: {
    datetime: { 'long-time': `{ date } em { time }` },
    input: {
      text: {
        percentage: '{ value } %',
        days: '{value, plural, =1 {# dia} other {# dias}}',
      },
      suffix: {
        percentage: '%',
        days: '{value, plural, =1 {dia} other {dias}}',
      },
    },
    recommendation: {
      NEW_CREDIT_OFFER: 'Oferta de crédito',
      INCREASE: 'Aumento de crédito',
      DECREASE: 'Diminuição de crédito',
    },
    'approval-method': {
      FILE_BATCH_UPDATE: 'Por arquivo',
      SYSTEM: 'Por sistema',
    },
    'payment-method': {
      CASH: 'Dinheiro',
      CREDIT: 'Crédito',
      BANK_SLIP: 'Boleto à vista',
      BANK_SLIP_INSTALLMENT: 'Boleto parcelado',
    },
    'term-fee': '{term, plural, =1 {# dia} other {# dias}} / {fee}',
  },
  tables: {
    loading: 'Carregando itens...',
    empty: 'Ainda não há clientes nesta lista.',
    search: 'Pesquisar por nome ou ID do cliente',
    pagination: {
      quantity: 'de',
      itensPerPage: 'Itens por página: { numberPage }',
    },
    selectable: {
      selected: { singular: 'cliente selecionado', plural: 'clientes selecionados' },
      deselect: 'Deselecionar tudo',
    },
    views: {
      selector: { label: 'Visualizar: { name }' },
      card: {
        custom: { title: 'Visualizações do cliente' },
        alert:
          'Você atingiu o limite de 10 visualizações personalizadas. Exclua uma visualização para criar uma nova.',
        standard: {
          title: 'Visualizações padrões',
          DEFAULT: 'Padrão',
          RECOMMENDED: 'Recomendações de crédito',
          NOT_RECOMMENDED: 'PDVs sem recomendação de crédito',
        },
        buttons: { create: 'Criar nova visualização' },
        tooltips: { edit: 'Editar visualização', delete: 'Excluir visualização' },
        modal: {
          title: 'Excluir visualização personalizada?',
          description: 'Esta ação não pode ser desfeita.',
          buttons: { cancel: 'Não, voltar', delete: 'Sim, excluir' },
          toasts: { success: 'Sua visualização personalizada foi excluída.' },
        },
      },
    },
    filters: {
      empty: 'Nenhum resultado do filtro.',
      buttons: {
        filter: '{value, plural, =0 {Filtro} one {Filtro (1)} other {Filtros (#)}}',
        cancel: 'Cancelar',
        apply: 'Aplicar',
        clearAll: 'Limpar tudo',
        clearDate: 'Limpar data',
      },
      chips: {
        min: 'Mínimo { min }',
        max: 'Máximo { max }',
        startDate: 'Data de início { min }',
        endDate: 'Data de término { max }',
        to: '{ min } até { max }',
      },
      inputs: {
        updatedAt: {
          name: 'Última atualização',
          min: { label: 'Data de início', placeholder: 'Insira a data' },
          max: { label: 'Data de término', placeholder: 'Insira a data' },
        },
        creditLimit: {
          name: 'Valor do crédito',
          min: { label: 'Limite mínimo', placeholder: 'Insira o valor' },
          max: { label: 'Limite máximo', placeholder: 'Insira o valor' },
        },
        fee: {
          name: 'Taxa',
          min: { label: 'Taxa mínima', placeholder: '0' },
          max: { label: 'Taxa máxima', placeholder: '0' },
        },
        term: {
          name: 'Prazo',
          min: {
            label: 'Prazo mínimo',
            placeholder: '0',
            sufix: '{value, plural, =1 {dia} other {dias}}',
          },
          max: {
            label: 'Prazo máximo',
            placeholder: '0',
            sufix: '{value, plural, =1 {dia} other {dias}}',
          },
        },
        status: {
          name: 'Status',
          title: 'Status',
          labels: {
            processing: 'Processando',
            integration: 'Integração',
            activated: 'Ativo',
            denied: 'Negado',
          },
        },
        recommendation: {
          name: 'Recomendação',
          title: 'Recomendação',
          labels: {
            creditOffer: 'Oferta de crédito',
            creditIncrease: 'Aumento de crédito',
            creditDecrease: 'Diminuição de crédito',
          },
        },
      },
    },
    files: {
      tab: { title: 'Arquivos' },
      empty: 'Ainda não há arquivos nesta lista.',
      search: 'Pesquisar por nome do arquivo',
      status: {
        pending: 'Pendente',
        uploaded: 'Enviado',
        analyzing: 'Analisando',
        analyzed: 'Analisado',
        processing: 'Processando',
        success: 'Carregado',
        errors: 'Carregado com erros',
      },
      columns: {
        name: 'Nome do arquivo',
        totalPOCs: 'PDVs atualizados',
        description: 'Descrição do arquivo',
        uploadedBy: 'Atualizado por',
        uploadedAt: 'Atualizado em',
        status: 'Status',
        actions: 'Ação',
      },
    },
    pending: {
      tab: { title: 'Pendente' },
      columns: {
        pocName: 'PDV',
        accountId: 'Código da conta',
        creditLimit: 'Limite de crédito',
        term: 'Prazo',
        fee: 'Taxa',
        score: 'Pontuação',
        recommendation: 'Recomendação',
        updatedAt: 'Última atualização',
        actions: 'Ação',
        salesAgency: 'Agência de vendas',
        salesArea: 'Área de vendas',
        customerType: 'Tipo de cliente',
      },
    },
    inprogress: {
      tab: { title: 'Em andamento' },
      status: {
        processing: 'Processando',
        integration: 'Integração',
      },
      columns: {
        pocName: 'PDV',
        accountId: 'Código da conta',
        creditLimit: 'Limite de crédito',
        term: 'Prazo',
        fee: 'Taxa',
        updatedAt: 'Última atualização',
        updatedBy: 'Atualizado por',
        status: 'Status',
      },
    },
    completed: {
      tab: { title: 'Concluída' },
      status: {
        active: 'Ativo',
        denied: 'Negado',
      },
      columns: {
        pocName: 'PDV',
        accountId: 'Código da conta',
        creditLimit: 'Limite de crédito',
        term: 'Prazo',
        fee: 'Taxa',
        updatedAt: 'Última atualização',
        updatedBy: 'Atualizado por',
        status: 'Status de crédito',
      },
    },
  },
  files: {
    buttons: {
      upload: 'Carregar arquivo',
      download: 'Baixar arquivo',
    },
    modal: {
      title: 'Baixar arquivo',
      description:
        'Baixar arquivos para aprovar ou negar linhas de crédito do cliente. Os arquivos não podem conter:',
      itemsList: {
        first: 'Colunas adicionadas ou renomeadas',
        second: 'Clientes adicionados ou renomeados',
        third:
          'Qualquer informação que tenha sido adicionada ou modificada, exceto o status de aprovação',
      },
      fileDetails: {
        title: 'Detalhes do arquivo',
        description: 'Selecione as informações que deseja incluir no arquivo.',
        subTitle: 'Status do cliente:',
        subDescription: 'Selecione os status a serem incluídos no arquivo.',
        checkboxes: {
          all: 'Todos',
          pending: 'Aprovação pendente',
          approved: 'Crédito aprovado',
          denied: 'Crédito negado',
        },
      },
      buttons: {
        back: 'Voltar',
        confirm: 'Gerar e baixar arquivo',
      },
      toasts: {
        info: 'O arquivo está sendo gerado e pode levar alguns segundos. Ele será baixado automaticamente quando terminar.',
      },
    },
    errors: {
      412: {
        description:
          'Outro arquivo já está em processamento. Você precisa esperar até que ele esteja pronto antes de carregar um novo arquivo.',
      },
    },
  },
  editRecommendation: {
    title: 'Editar recomendação de crédito',
    description:
      'As recomendações se baseiam na análise de dados do PDV. Ao editar uma recomendação de crédito, você deve seguir algumas condições.',
    buttons: {
      cancel: 'Cancelar',
      apply: 'Aplicar alterações',
      tooltip: 'Editar status',
      edit: { recommendation: 'Editar recomendação', conditions: 'Editar condições' },
    },
    toasts: { success: 'As condições de crédito de { value } foram alteradas.' },
    inputs: {
      creditLimit: {
        label: 'Limite de crédito',
        hint: 'Sugerido: { value }.',
      },
      term: {
        label: 'Prazo de crédito',
        hint: 'Sugerido: {value, plural, =1 {# dia} other {# dias}}.',
      },
      fee: {
        label: 'Taxa de crédito',
      },
    },
  },
  approveRecommendation: {
    title: 'Aprovar recomendação de crédito?',
    description: {
      single: 'Selecione a opção para este PDV.',
      multiple: 'Selecione uma opção para todos os PDVs selecionados.',
    },
    counter: '{ counter } PDVs selecionados',
    info: {
      title: 'Solicitar integração PDV',
      description:
        'Número de telefone, código do PDV, comprovante de endereço e a aceitação dos termos e condições serão solicitados.',
    },
    radios: { request: 'Solicitar', doNotRequest: 'Não solicitar' },
    buttons: { back: 'Não, voltar', approve: 'Sim, aprovar' },
    warning: 'Se você não solicitar a integração, será responsável por esta decisão.',
  },
  offerCredit: {
    title: 'Oferta de crédito?',
    tooltip: 'Oferta de crédito.',
    warning:
      'Com base nas informações disponíveis, não há recomendação de crédito para este PDV. Quaisquer decisões de crédito tomadas serão tomadas a seu próprio critério.',
    info: {
      title: 'Solicitar integração PDV',
      description:
        'Número de telefone, código do PDV, comprovante de endereço e a aceitação dos termos e condições serão solicitados.',
    },
    radios: { request: 'Solicitar', doNotRequest: 'Não solicitar' },
    buttons: {
      cancel: 'Voltar',
      apply: 'Oferta de crédito',
    },
    toasts: { success: 'Crédito oferecido a { value }.' },
    inputs: {
      creditLimit: {
        label: 'Limite de crédito',
        placeholder: 'Insira um valor',
      },
      term: {
        label: 'Prazo de crédito',
        placeholder: 'dias',
      },
      fee: {
        label: 'Taxa de crédito',
      },
    },
  },
  loading: {
    default: 'Estamos te levando para tela',
    optimizely: 'Preparando sua experiência',
  },
  errors: {
    default: 'Algo deu errado.',
    generic: 'Algo deu errado. Tente recarregar a página.',
    403: {
      title: 'Hmm... Acesso negado!',
      description: 'Infelizmente, você não tem permissão para acessar esta página.',
      button: 'Voltar',
    },
    404: {
      title: 'Hmm… Parece que essa página sumiu!',
      description: 'Essa página não pôde ser encontrada ou não existe.',
      button: 'Voltar',
    },
    500: {
      title: 'Ooops! Parece que alguma coisa deu errada',
    },
    503: {
      title: 'Ooops! Parece que alguma coisa deu errada',
      description: 'Estamos com problemas para carregar esta página. Por favor, tente novamente.',
      info: '',
      button: 'Tente novamente',
    },
    custom: {
      source: {
        title: 'Ooops!',
        description: 'Algo deu errado. Tente recarregar a página.',
        button: 'Tente novamente',
      },
    },
  },
  source: {
    validations: {
      errors: {
        'error:term:zero': 'Deve ser no mínimo {value, plural, =1 {# dia} other {# dias}}.',
        'error:term:small': 'Deve ser no máximo {value, plural, =1 {# dia} other {# dias}}.',
        'error:term:fee:big': 'Deve ser no máximo { value }%.',
        'error:term:fee:negative': 'Deve ser maior ou igual a { value }%.',
        'error:limit:big': 'Máximo de { value }% do valor recomendado.',
        'error:limit:small': 'Deve ser maior ou igual a { value }.',
      },
    },
  },
  approval: {
    buttons: {
      APPROVED: {
        single: 'Aprovar',
        multiple: 'Aprovar selecionados',
        tooltip: 'Aprovar linha de crédito',
      },
      DENIED: {
        single: 'Negar',
        multiple: 'Negar selecionados',
        tooltip: 'Negar linha de crédito',
      },
    },
    toasts: {
      APPROVED: {
        single: 'A linha de crédito de { value } está aprovada!',
        multiple: 'A linha de crédito para { value } clientes está aprovada!',
      },
      DENIED: {
        single: 'A linha de crédito de { value } foi negada!',
        multiple: 'A linha de crédito para { value } clientes foi negada!',
      },
    },
    errors: {
      412: {
        description:
          'Uma atualização já está em andamento. Você precisa esperar até que ela seja concluída antes de fazer uma nova atualização.',
      },
    },
  },
  timeline: {
    approved: 'Aprovado',
    onboarding: 'Integração',
    activationProcess: 'Processo de ativação',
    creditLineActivated: 'Linha de crédito ativada',
  },
};

export default ptBR;
