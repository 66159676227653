/**
 * Generates a formatted download file name based on the given file name.
 * The generated file name includes the original file name and the current date and time.
 *
 * @param fileName - The original file name.
 * @returns The formatted download file name.
 */
export const downloadFileName = (fileName: string) => {
  return `${fileName}_${new Date().toISOString().replace(/[:T]/g, '-').replace(/\..+/, '')}`;
};
