import { Chip, Tooltip } from '@hexa-ui/components';
import { useEffect, useRef, useState } from 'react';
import { ChipTooltipProps } from './ChipTooltip.types';

export const ChipTooltip: React.FC<ChipTooltipProps> = ({ description, onDelete }) => {
  const chipRef = useRef<HTMLDivElement>(null);

  const [hasTooltip, setHasTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (chipRef.current) {
      setHasTooltip(chipRef.current.offsetWidth < chipRef.current.scrollWidth);
    }
  }, [description]);

  return (
    <Chip.Root>
      {hasTooltip ? (
        <Tooltip text={description} placement="top">
          <Chip.Label ref={chipRef} css={{ 'max-width': '300px' }}>
            {description}
          </Chip.Label>
        </Tooltip>
      ) : (
        <Chip.Label ref={chipRef} css={{ 'max-width': '300px' }}>
          {description}
        </Chip.Label>
      )}

      <Chip.DeleteIcon onDelete={onDelete} />
    </Chip.Root>
  );
};
